.customerServiceOffering
  .customerForm
    .signature
      +size(auto, auto)
      cursor: crosshair

      &:active
        +shadowCellElevated
        transform: scale(1.01)
        background-color: $colorBackgroundPrimary

    .service
      +size(100%, 60px)
      +interactionNone
      &:hover
        +shadowCell
      &:active
        +shadowCell
        background-color: $colorBackgroundPrimary
  
  .formHeading
    +contentEdgeInsets(12px, 12px, 12px, 12px)
    background-color: $colorBackgroundPrimary

