.emptyPlaceholder
  +size(100%, 100%)
  +display-flex
  +flex-direction(column)
  +align-items(center)
  +justify-content(center)
  min-height: 260px
  .placeholderTitle
    +fontTitle3Emphasized
    color: $colorTitle
  .placeholderBody
    +fontBody
    color: $colorSubtitle
