.button
  +display-flex
  +align-items(center)
  +cursorAction
  +noUserSelect
  +interactionTransition
  background-color: $colorActionTranslucent

  .title, .buttonTitle
    +cursorAction
    color: $colorTitle

  .buttonIcon
    +display-flex
    
  &:hover
    +interactionHoverScale

  &:active
    +interactionTapOpacity

  &.extraSmall
    +size(auto, 20px)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +cornerRadius(4px)
    .title, .buttonTitle
      +fontCaption2Emphasized

  &.small
    +size(auto, 28px)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +cornerRadius(6px)
    .title, .buttonTitle
      +fontFootnoteEmphasized

  &.medium
    +size(auto, 32px)
    +contentEdgeInsets(10px, 0, 10px, 0)
    +cornerRadius(5px)
    .title, .buttonTitle
      +fontFootnoteEmphasized

  &.clearFilterInActive
    +size(auto, 35px)
    +contentEdgeInsets(10px, 0, 10px, 0)
    +cornerRadius(5px)
    color: $colorSubtitle
    .title, .buttonTitle
      +fontFootnoteEmphasized

  &.clearFilterActive
    +size(auto, 35px)
    +contentEdgeInsets(10px, 0, 10px, 0)
    +cornerRadius(5px)
    color: $colorTitle
    .title, .buttonTitle
    +fontFootnoteEmphasized


  &.large
    +size(auto, 40px)
    +contentEdgeInsets(12px, 0, 12px, 0)
    +cornerRadius(6px)
    .title, .buttonTitle
      +size(100%, auto)
      +fontSubheadEmphasized
      text-align: center

  &.dismiss, &.plain
    background-color: $colorBackgroundPrimary
    +shadowSmallBordered
    .title, .buttonTitle
      color: $colorAction
    &:hover
      background-color: $colorBackgroundPrimaryHover

  &.tintBlue
    background-color: $colorTintTranslucent
    .title, .buttonTitle
      color: $colorTint

  &.tint
    background-color: $colorTintTranslucent
    .title, .buttonTitle
      color: $colorTitle

    &:hover
      background-color: $colorTintTranslucentHover

  &.reset:disabled
    color: var(--colorHeading)

  &.reset:not(:disabled)
    color: var(--colorTitle)
    +interactionHoverScale

  &.tintProminent, &.confirm
    background-color: $colorTint
    .title, .buttonTitle
      color: $colorTitleSecondary
    &:hover
      background-color: $colorTintHover

  &.tintPurple
    background-color: $colorTintPurpleTranslucent
    .title, .buttonTitle
      color: $colorTintPurple
    &:hover
      background-color: $colorTintPurpleTranslucentHover

  &.tintYellow
    background-color: $colorTintYellowTranslucent
    .title, .buttonTitle
      color: $colorTintYellow
    &:hover
      background-color: $colorTintYellowTranslucentHover

  &.confirm
    // +shadowSmall

  &.disabled
    +shadowNone
    +interactionNone
    +cursorDefault
    background-color: $colorBackgroundDisabled
    .title, .buttonTitle
      +cursorDefault
      color: $colorTitleDisabled

  &.action
    background-color: $colorActionTranslucent
    .title, .buttonTitle
      color: $colorAction
    &:hover
      background-color: $colorActionTranslucentHover

  &.actionProminent
    background-color: $colorAction
    .title, .buttonTitle
      color: $colorBackgroundPrimary

  &.destructive
    background-color: $colorDestructiveTranslucent
    .title, .buttonTitle
      color: $colorDestructive
    &:hover
      background-color: $colorDestructiveTranslucentHover

  &.destructiveProminent
    background-color: $colorDestructive
    .title, .buttonTitle
      color: $colorTitleSecondary
    &:hover
      background-color: $colorDestructiveHover

  &.onDark
    background-color: $colorTitleSecondaryTranslucent
    .title, .buttonTitle
      color: $colorTitleSecondary
    &:hover
      background-color: $colorTitleSecondaryTranslucentHover

  &.backButton
    +contentEdgeInsets(8px, 0, 8px, 0)
    .icon

    .backTitle
      +offset(6px, 0, 0, 0)
      +fontFootnoteEmphasized
      color: $colorTint


.sortButton
  +size(auto, 32px)
  +contentEdgeInsets(8px, 0, 12px, 0)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +cornerRadius(5px)
  +shadowSmallBordered
  +cursorAction
  +interactionTransition
  background-color: $colorBackgroundPrimary

  .icon
    +offset(0, 0, 6px, 0)
    path
      fill: $colorAction

  .sortButtonTitle
    +noUserSelect
    +fontFootnoteEmphasized
    color: $colorAction

  .sortButtonValue
    +offset(6px, 0, 0, 0)
    +noUserSelect
    +fontFootnote
    color: $colorAction

  &:hover
    background-color: $colorBackgroundPrimaryHover

  &:active
    transform: scale(0.99)
    opacity: 0.4

.filterButton
  +size(auto, 32px)
  +contentEdgeInsets(8px, 0, 12px, 0)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +cornerRadius(5px)
  +shadowSmallBordered
  +cursorAction
  +interactionTransition
  background-color: $colorBackgroundPrimary

  .icon
    +offset(0, 0, 6px, 0)
    path
      fill: $colorAction

  .filterButtonTitle
    +noUserSelect
    +fontFootnoteEmphasized
    color: $colorAction

  .filterButtonValue
    +offset(6px, 0, 0, 0)
    +display-flex
    +flex-direction(row)
    +noUserSelect

  .filterButtonValueClearAll
    +offset(6px, 0, 0, -3px)
    +display-flex
    +flex-direction(row)
    +noUserSelect

  .filterButtonValueCell
    +contentEdgeInsets(6px, 0, 6px, 0)
    +offset(0, 0, 4px, 0)
    +size(auto, 24px)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    +cornerRadius(4px)
    +interactionTransition
    background-color: $colorBackgroundPrimaryTranslucent

    &:last-of-type
      +offset(0, 0, 0, 0)

    .filterButtonValueCellTitle
      +interactionTransition
      +fontCaption1Emphasized
      color: $colorBackgroundPrimary

    .filterButtonValueCellClear
      +size(0px, 0px)
      opacity: 0
      path
        +interactionTransition
        fill: $colorBackgroundPrimary

    &:hover
      background-color: $colorBackgroundPrimary
      .filterButtonValueCellTitle
        color: $colorAction
      .filterButtonValueCellClear
        +size(auto, auto)
        +offset(5px, 0, 0, 0)
        opacity: 1
        path
          fill: $colorAction

  &:hover
    background-color: $colorBackgroundPrimaryHover

  &:active
    transform: scale(0.99)
    opacity: 0.4

  &.isFiltered
    +contentEdgeInsets(8px, 0, 4px, 0)
    background-color: $colorAction

    .icon
      path
        fill: $colorBackgroundPrimary

    .filterButtonTitle
      color: $colorBackgroundPrimary

    &:hover
      background-color: $colorActionHover

.paginationExpander
  +size(100%, 56px)
  +positionAbsoluteWithOffset(0, auto, 0, 16px)
  +contentEdgeInsets(24px, 0, 24px, 0)
  +display-flex
  +flex-direction(column)
  +align-items(center)
  +justify-content(flex-end)
  +cursorAction
  z-index: 1
  background-image: linear-gradient(180deg, $colorClear 0%, $colorBackgroundSecondary 64%, $colorBackgroundSecondary 100%)

  .expanderButton
    +size(auto, 20px)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    +offset(0, 0, 0, 8px)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +cornerRadius(4px)
    +noUserSelect
    +interactionTransition
    background-color: $colorActionTranslucent

    .buttonTitle
      +fontCaption2Emphasized
      color: $colorAction

    &:hover
      background-color: $colorActionTranslucentHover

    &:active
      opacity: 0.4

.addButton
  +size(auto, 32px)
  +offset(10px, 0, 0, 0)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +cornerRadius(5px)
  +clipToBounds
  +cursorAction
  +shadowSmall
  +interactionTransition
  background-color: $colorTint

  .buttonIcon
    +offset(10px, 0, 5px, 0)
    +iconNavBarAdd
  
  .buttonTitle
    +offset(0, 0, 12px, 0)
    +cursorAction
    +fontFootnoteEmphasized
    color: $colorTitleSecondary

  &:hover
    +interactionHoverScale

  &:active
    +interactionTapScale

.filtersSelectButton
  .filterButtonTitle
    display: flex
    align-items: center
