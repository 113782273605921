.conversationsView
  .chatsList
    +size(272px, 100vh)
    +display-flex
    +flex-shrink(0)
    +flex-direction(column)
    +shadowChatsList
    +hideScrollBars
    z-index: 2
    overflow-y: scroll
    background-color: $colorBackgroundSecondary

  .navBar
    +flex-shrink(0)
    z-index: 4
    background-color: $colorBackgroundSecondary

    .startConversation
      +justify-content(center)
      +offset(4px, 0, 0, 0)
      width: 32px
      .icon
        +iconNavBarAddTint

  .listActions
    +contentEdgeInsets(16px, 12px, 16px, 0)
    z-index: 10

    .separator
      +size(100%, 1px)
      +offset(0, 12px, 0, 0)
      background-color: $colorSeparator

    .responseGroupPicker
      .groupPickerValue
        +size(100%, 32px)
        +cornerRadius(0)
        +clipToBoundsFalse
        +contentEdgeInsets(0, 0, 0, 0)

        .groupPickerValue__control
          +cornerRadius(0)
          +size(100%, 32px)
          +contentEdgeInsets(10px, 0, 0, 0)
          +cursorAction
          min-height: auto
          background-color: $colorClear
          border: none

          &.groupPickerValue__control--is-focused
            +shadowNone

          .groupPickerValue__value-container
            +contentEdgeInsets(0, 0, 0, 0)
            +align-items(center)

            .groupPickerValue__placeholder
              +fontFootnote
              color: $colorTint
              text-align: right

            .groupPickerValue__single-value
              position: relative
              +offset(0, 0, 0, 0)
              +contentEdgeInsets(0, 0, 0, 0)
              +fontFootnote
              +transform(none)
              color: $colorTint
              text-align: right
              width: 100%

            .groupPickerValue__input
              +fontFootnote
              color: $colorTint
              text-align: right

            &.groupPickerValue__value-container--has-value
              .groupPickerValue__single-value
                +fontFootnote
                color: $colorTint
                text-align: right

          .groupPickerValue__indicators
            .groupPickerValue__indicator-separator // burn in hell you ugly garbage
              display: none
            .groupPickerValue__indicator
              &.groupPickerValue__dropdown-indicator
                display: none
                svg // burn in hell
                  display: none

        .groupPickerValue__menu
          +cornerRadius(8px)
          +shadowPopover
          +noUserSelect
          +cursorDefault
          background-color: $colorBackgroundPrimary

          .groupPickerValue__menu-list
            +contentEdgeInsets(4px, 4px, 4px, 4px)
            .groupPickerValue__option
              height: 32px
              +fontSubhead2Emphasized
              color: $colorTitle
              +contentEdgeInsets(8px, 10px, 8px, 10px)
              +cornerRadius(6px)
              +cursorAction
              +interactionTransition
              
              &.groupPickerValue__option--is-focused
                background-color: $colorTintTranslucent

              &.groupPickerValue__option--is-selected
                background-color: $colorAction
                color: $colorTitleSecondary

        &.groupPickerValue--is-disabled
          +cursorDefault
          +shadowNone
          +interactionNone
          opacity: 0.4

          .groupPickerValue__control
            background-color: $colorBackgroundPrimary

          &:active, &:hover
            +cursorDefault
            background-color: $colorBackgroundPrimary
            opacity: 0.4

  .chatsSection
    +size(100%, auto)
    +display-flex
    +flex-direction(column)
    +flex-shrink(0)
    +offset(0, 12px, 0, 0)
    position: relative

    .sectionHeading
      +size(100%, 32px)
      +contentEdgeInsets(8px, 0, 8px, 0)

      .headingContainer
        +size(100%, 100%)
        +display-flex
        +flex-direction(row)
        +align-items(center)
        +justify-content(space-between)
        +contentEdgeInsets(8px, 0, 8px, 0)
        +cursorAction
        +cornerRadius(6px)
        +interactionTransition
        background-color: $colorClear

        .title
          +size(100%, auto)
          +fontFootnoteEmphasized
          +noUserSelect
          color: $colorHeading

        .icon
          +iconTableViewCellAccessoryExpander
          +flex-shrink(0)
          +interactionTransition
          opacity: 0

      &:hover
        .headingContainer
          background-color: $colorActionTranslucentCell
          .icon
            opacity: 1



    .sectionSeparator
      +size(100%, 8px)
      +contentEdgeInsets(16px, 0, 16px, 0)
      display: none
      box-sizing: border-box
      .separator
        +size(100%, 100%)
        +shadowSeparator

    &.expanded
      .sectionSeparator
        display: initial
      .sectionHeading
        .icon
          +transform(rotate(90deg))

.conversationListAction
  +size(100%, 32px)
  +offset(0, 0, 0, 4px)
  +display-flex
  +flex-direction(row)
  +cornerRadius(4px)
  +align-items(center)
  +noUserSelect
  +cursorAction
  background-color: $colorActionTranslucent
  
  .conversationListActionIcon
    +offset(8px, 0, 0, 0)
    +flex-shrink(0)
    path
      fill: $colorAction

  .conversationListActionTitle
    +size(auto, auto)
    +offset(6px, 0, 0, 0)
    +fontFootnoteEmphasized
    color: $colorAction

  .conversationListActionDetail
    +size(100%, auto)
    +offset(6px, 0, 6px, 0)
    +fontFootnote
    color: $colorAction
    text-align: right

  .conversationListActionDisclosureIndicator
    +offset(0, 0, 8px, 0)
    +clipToBoundsFalse

  .conversationListActionSwitch
    +size(26px, 16px)
    +offset(6px, 0, 6px, 0)
    +flex-shrink(0)
    +cornerRadius(8px)
    position: relative
    background-color: $colorActionTranslucent

    .conversationListActionSwitchKnob
      +positionAbsoluteWithOffset(2px, 2px, auto, auto)
      +size(12px, 12px)
      +cornerRadius(50%)
      +shadowSmall
      +interactionTransition
      background-color: white

  &.statusWorking
    background-color: $colorSuccessTranslucent
    
    .conversationListActionIcon
      path
        fill: $colorSuccess
        // stroke: $colorSuccess

    .conversationListActionTitle, .conversationListActionDetail
      color: $colorSuccess

    .conversationListActionSwitch
      background-color: $colorSuccess

      .conversationListActionSwitchKnob
        +positionAbsoluteWithOffset(auto, 2px, 2px, auto)

  &.conversationWorkingButton
    .conversationListActionTitle
      +size(100%, auto)

  &.responseGroupPicker
    background-color: $colorTintTranslucent

    .conversationListActionTitle
      color: $colorTint

    .conversationListActionIcon
      +iconConversationsResponseGroupPicker
