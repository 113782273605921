.chatCell
  +size(100%, auto)
  +contentEdgeInsets(16px, 14px, 16px, 14px)
  +display-flex
  +flex-direction(row)
  +noUserSelect
  +cursorAction
  +interactionTransition
  +flex-shrink(0)
  position: relative
  z-index: 1
  box-sizing: border-box

  .profilePicture
    +size(36px, 36px)
    +flex-shrink(0)
    +clipToBounds
    +cornerRadius(50%)
    background-color: $colorBackgroundPlaceholder

  .chatDetails
    +size(100%, auto)
    +display-flex
    +flex-direction(column)
    +offset(10px, 0, 0, 0)

    .clientDetails
      +display-flex
      +flex-direction(row)
      +justify-content(space-between)

      .clientName
        +fontFootnoteEmphasized
        color: $colorTitle
        +display-flex
        +flex-direction(column)
        
        .name
          max-width: 120px
          overflow-x: hidden
          overflow-y: hidden
          max-height: 20px
      

      .timestamp
        +offset(0, 2px, 0, 0)
        +fontCaption1
        color: $colorSubtitle
        text-align: right

    .patientDetails
      +display-flex
      +flex-direction(row)

      .patientName
        +fontFootnoteEmphasized
        color: $colorSubtitle

      .patientBreed
        +offset(6px, 0, 0, 0)
        +fontFootnote
        color: $colorSubtitle

    .attributes
      +size(100%, 20px)
      +display-flex
      +justify-content(space-between)
      +flex-direction(row)
      +offset(0, 10px, 0, 0)

      .attributeItem
        +size(auto, 100%)
        +contentEdgeInsets(8px, 0, 8px, 0)
        +display-flex
        +align-items(center)
        +cornerRadius(4px)
        +fontCaption2Emphasized
        background-color: $colorActionTranslucent

        &.priority
          +fontCaption2Emphasized
          color: $colorBackgroundPrimary

          &.none
            background-color: $colorPriorityNone
          &.low
            background-color: $colorPriorityLow
          &.medium
            background-color: $colorPriorityMedium
          &.high
            background-color: $colorPriorityHigh

        &.overdueIndicator
          +contentEdgeInsets(5px, 0, 8px, 0)
          background-color: $colorDestructive
          .title
            +offset(4px, 0, 0, 0)
            color: $colorTitleSecondary  
          .icon
            +iconConversationChatCellOverdue

        &.unreadBadge
          +contentEdgeInsets(5px, 0, 8px, 0)
          background-color: $colorAction
          .icon
            +iconConversationChatCellUnread
          .count
            +offset(4px, 0, 0, 0)
            color: $colorTitleSecondary

        &.followUpBadge
          +contentEdgeInsets(6px, 0, 8px, 0)
          background-color: $colorAction
          .icon
            +iconConversationChatCellFollowUp
          .title
            +offset(4px, 0, 0, 0)
            color: $colorTitleSecondary

  .background
    +positionAbsoluteWithOffset(8px, 4px, 8px, 4px)
    +cornerRadius(10px)
    +interactionTransition
    z-index: -1

  &:hover
    .background
      background-color: $colorActionTranslucentCell

  &:active
    +interactionTapScale

  &.selected
    .chatDetails
      .clientDetails
        .clientName
          color: $colorTitleSecondary
        .timestamp
          color: $colorTitleSecondary
          opacity: 0.6
      
      .patientDetails
        .patientName
          color: $colorTitleSecondary
        .patientBreed
          color: $colorTitleSecondary
          opacity: 0.8
      
      .attributes
        .attributeItem
          &.priority
            opacity: 0.8

            &.none
              background-color: $colorPriorityNone
              color: $colorBackgroundPrimary
            &.low
              background-color: $colorPriorityLow
              color: $colorBackgroundPrimary
            &.medium
              background-color: $colorPriorityMedium
              color: $colorBackgroundPrimary
            &.high
              background-color: $colorPriorityHigh
              color: $colorBackgroundPrimary

          &.unreadBadge
            background-color: $colorBackgroundPrimary
            .icon
              +iconConversationChatCellUnreadSelected
            .count
              color: $colorAction
          
          &.followUpBadge
            background-color: $colorBackgroundPrimary
            .icon
              +iconConversationChatCellFollowUpSelected

    .background
      background-color: $colorTint
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16)
