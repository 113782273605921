.startConversationView
  .tableView
    .tableSection
      &.sectionPhone
        max-width: 320px
      &.sectionRecentEncounters
        .tableCell
          +size(100%, 56px)
          +contentEdgeInsets(8px, 0, 12px, 0)
          .profilePicture
            +size(40px, 40px)
            +cornerRadius(50%)
            +clipToBounds
            +flex-shrink(0)
          .cellBody
            +offset(8px, 0, 0, 0)
            .callerName
              +fontSubheadEmphasized
              color: $colorTitle
            .callerNumber
              +fontFootnote
              color: $colorTitle
          .cellDetail
            +size(100%, auto)
            .encounterName
              +fontFootnoteEmphasized
              color: $colorSubtitle
              text-align: right
          &.selected
            .cellBody
              .callerName
                color: $colorTitleSecondary
              .callerNumber
                color: $colorTitleSecondary
            .cellDetail
              .encounterName
                color: $colorTitleSecondary
                opacity: 0.80


