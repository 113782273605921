.searchInput input, .inputCell input
  border: none
  background-image: none
  background-color: transparent
  -webkit-box-shadow: none
  -moz-box-shadow: none
  box-shadow: none

.inputCell input:focus, .inputCell input:focus
  border-color: transparent
  outline: none
  width: 100%

input[type="date"]::-webkit-inner-spin-button
input[type="date"]::-webkit-calendar-picker-indicator
  display: none
  -webkit-appearance: none

.inputCell
  +queryPrint
    +cursorTextInput
    +shadowCell
    +interactionTransition

    &:hover, &:active
      transform: scale(1)

    .title
      +cursorTextInput

    .input
      +cursorTextInput
      color: $colorTitlePlaceholder

    &.isFocused
      +shadowInputFocused

    &.hasInput
      .input
        color: $colorTitle

  @media print

.react-tel-input

  .tel-input
    border: none
    padding-left: 30px

  .flag-dropdown
    border: none
    background-color: transparent

    .arrow
      display: none

.tableView
  .tableSection
    .cell
      &.selectCell
        +contentEdgeInsets(0, 0, 0, 0)
        +offset(0, 0, 0, 8px)
        +noUserSelect
        +cursorAction
        +clipToBoundsFalse
        background-color: $colorClear


.selectCell
  +cornerRadius(6px)
  +clipToBoundsFalse
  +contentEdgeInsets(0, 0, 0, 0)
  +interactionTableViewCellSelect
  +cursorAction
  +shadowCell

  .selectCell__control
    +cornerRadius(6px)
    +size(100%, 100%)
    +contentEdgeInsets(12px, 0, 12px, 0)
    +cursorAction
    border: none
    background-color: $colorBackgroundPrimary

    &.selectCell__control--is-focused
      +shadowInputFocused

    .selectCell__value-container
      +contentEdgeInsets(0, 0, 0, 0)

      .selectCell__placeholder
        +fontSubhead
        color: $colorTitlePlaceholder !important

      .selectCell__single-value
        +fontSubhead
        color: $colorTitlePlaceholder

      .selectCell__input
        +fontSubhead
        color: $colorTitle

      &.selectCell__value-container--has-value
        .selectCell__single-value
          +fontSubheadEmphasized
          color: $colorTitle        

    .selectCell__indicators
      .selectCell__indicator-separator // burn in hell you ugly garbage
        display: none
      .selectCell__indicator
        &.selectCell__dropdown-indicator
          +contentEdgeInsets(0, 0, 0, 0)
          +iconTableViewCellAccessoryPopUp
          svg // burn in hell
            display: none

  .selectCell__menu
    +cornerRadius(8px)
    +shadowPopover
    +noUserSelect
    +cursorDefault
    background-color: $colorBackgroundPrimary

    .selectCell__menu-list
      +contentEdgeInsets(4px, 4px, 4px, 4px)
      .selectCell__option
        +fontSubhead2Emphasized
        color: $colorTitle
        +contentEdgeInsets(8px, 10px, 8px, 10px)
        +cornerRadius(6px)
        +cursorAction
        +interactionTransition
        
        &.selectCell__option--is-focused
          background-color: $colorTintTranslucent

        &.selectCell__option--is-selected
          background-color: $colorTint
          color: $colorTitleSecondary

  &.selectCell--is-disabled
    +cursorDefault
    +shadowNone
    +interactionNone
    opacity: 0.4

    .selectCell__control
      background-color: $colorBackgroundPrimary

    &:active, &:hover
      +cursorDefault
      background-color: $colorBackgroundPrimary
      opacity: 0.4

.editorCell
  +size(100%, auto)
  +interactionTableViewCellEditor
  +cursorTextInput
  min-height: 40px

  .DraftEditor-root
    +contentEdgeInsets(0, 10px, 0, 10px)
    height: auto
    min-height: auto

    .public-DraftEditorPlaceholder-root
      +positionAbsoluteWithOffset(auto, 10px, auto, auto)
      +fontSubhead
      color: $colorTitlePlaceholder

    .DraftEditor-editorContainer
      +fontSubhead
      color: $colorTitle

  &:focus-within
    +shadowInputFocused

  &.multilineCell
    +flex-direction(column)
    +align-items(flex-start)
    .title
      +offset(0, 10px, 0, 0)
    .DraftEditor-root
      +contentEdgeInsets(0, 2px, 0, 10px)



.searchInput
  +size(184px, 32px)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +shadowNone
  +cursorTextInput
  +clipToBounds
  +cornerRadius(5px)
  +interactionTransition
  position: relative
  background-color: $colorActionTranslucent

  .icon
    +iconNavBarSearch
    +flex-shrink(0)
    +offset(8px, 0, 8px, 0)

  .input
    +size(100%, 100%)
    +fontFootnote
    color: $colorAction

  &:hover
    transform: scale(1)

  &:active
    opacity: 0.8

  &.isFocused
    +shadowInputFocused
    &.hasInput
      .input
        color: $colorTitle

  &.hasInput
    .clear
      +positionAbsoluteWithOffset(auto, auto, 7px, auto)
      +iconNavBarSearchClear
      +cursorAction
      &:active
        +interactionTapOpacity

  &.tableSearch
    +size(100%, 40px)
    .input
      +fontSubhead
      color: $colorAction
    &.isFocused
      &.hasInput
        .input
          color: $colorTitle


.tableCell
  +queryPrint
    &.titledSelectCell
      +interactionTableViewCellEditor
      +cursorDefault
      +justify-content(space-between)
      &:focus-within
        +shadowInputFocused
      .selectCell
        +offset(0, 0, 0, 0)
        +contentEdgeInsets(0, 0, 0, 0)
        +interactionTableViewCellNone
        +shadowNone
        .selectCell__control
          .selectCell__value-container
            &.selectCell__value-container--has-value
              .selectCell__single-value
                +fontSubhead
                color: $colorTitle
      .inputCell
        +offset(0, 0, 0, 0)
        +contentEdgeInsets(0, 0, 0, 0)
        +shadowNone
        background-color: $colorClear
        &:focus-within
          +shadowNone
        .input
          +fontSubheadEmphasized
          color: $colorTitle
          text-align: right
      .valueDescription
        +flex-shrink(0)
        +fontSubhead
        color: $colorSubtitle
      .selectCell
        +size(100%, 100%)
        +shadowNone
        min-width: 50%

        .selectCell__control
          +contentEdgeInsets(0, 0, 0, 0)
          background-color: $colorClear
          border: none
          .selectCell__value-container
            .selectCell__placeholder, .selectCell__single-value, .selectCell__input
              text-align: right
              width: 100%

            &.selectCell__value-container--has-value
              .selectCell__single-value
                text-align: right
                width: 100%

          &.selectCell__control--is-focused
            +shadowNone

.tableCell
  &.phoneInput
    &:focus-within
      +shadowInputFocused
    &:hover
      transform: scale(1)
    position: relative
    .tel-input
      +fontSubhead
      background-color: $colorClear
      color: $colorTitle
      text-align: right
      &.disabled
        color: $colorTitleDisabledSecondary
    .flag-dropdown
      .selected-flag
        &:hover
          background-color: $colorClear
    .react-tel-input
      +positionAbsoluteWithOffset(auto, auto, 12px, auto)
      width: 164px
      .tel-input
        width: 164px

.datePickerCell
  .DayPickerInput
    width: 100%
    input
      +fontSubhead
      width: 100%
      background-color: $colorClear
      border: $colorClear
      text-align: right
      &:focus
        border-color: transparent
        outline: none
    .DayPickerInput-OverlayWrapper
      .DayPickerInput-Overlay
        left: auto
        right: 0px
        background-color: $colorBackgroundPrimary
        +cornerRadius(12px)
        +shadowMedium
        +clipToBounds
        .DayPicker
          background-color: $colorBackgroundPrimary
          .DayPicker-wrapper
            .DayPicker-NavBar
            .DayPicker-Months
              .DayPicker-Month
                .DayPicker-Caption
                  div
                    +fontBodyEmphasized
                    color: $colorTitle
                .DayPicker-Weekdays
                  .DayPicker-WeekdaysRow
                    .DayPicker-Weekday
                      abbr
                        +fontFootnoteEmphasized
                        color: $colorSubtitle
                .DayPicker-Body
                  .DayPicker-Week
                    .DayPicker-Day
                      +cornerRadius(6px)
                      +fontSubhead
                      color: $colorTitle
                      &:hover
                        background-color: $colorActionTranslucent
                      &.DayPicker-Day--today
                        +fontSubheadEmphasized
                        color: $colorTint
                      &.DayPicker-Day--selected
                        +fontSubheadEmphasized
                        background-color: $colorTint
