.modalDialog
  .content
    .tableView
      .encounterTypeContainer
        +display-flex
        .remove
          +offset(0, 5px, 0, 0)
          +size(32px, 32px)
          +cornerRadius(6px)
          +display-flex
          +align-items(center)
          +justify-content(center)
          +flex-shrink(0)
          +interactionTransition
          +cursorAction
          background-color: $colorActionTranslucent
          &:active
            +interactionTapOpacity
          .icon
            +iconTableViewSectionRowAccessoryDelete
        .encounterType
          +cornerRadius(10px)
          width: 90%
          background-color: $colorBackgroundTertiary
          +offset(10px, 0px, 10px, 10px)
          +contentEdgeInsets(7px, 7px, 7px, 7px)
          .tableSection
            padding: 0px

      .tableSection
        .addEncounterType
          width: 150px
          justify-content: center