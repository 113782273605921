.popup-content
  border: none !important
  box-shadow: none !important
  background-color: $colorClear !important
  .popup-arrow
    display: none

.pullDownMenu
  +size(100%, 100%)
  +contentEdgeInsets(4px, 4px, 4px, 4px)
  +shadowPopover
  +cornerRadius(8px)
  +noUserSelect
  +cursorDefault
  box-sizing: border-box
  z-index: 100
  background-color: $colorBackgroundPrimary

  &.serviceOffering
    +size(320px, 100%)
    .icon
      .glyph
        +iconConversationAttachmentService

  &.referral
    +size(320px, 100%)
    .icon
      .glyph
        +iconConversationAttachmentReferral

  .cell, .menuCell
    +size(100%, 32px)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    +cornerRadius(6px)
    +cursorAction
    +interactionTransition
    background-color: $colorClear
    box-sizing: border-box

    .title, .menuItemTitle
      +offset(0, 0, 0, 0)
      +fontSubhead2Emphasized
      color: $colorTitle
      width: 102px

    .title, .satisfactionMenuItemTitle
      +offset(0, 0, 0, 0)
      +fontSubhead2Emphasized
      color: $colorTitle
      width: 130px

    &:hover
      background-color: $colorActionTranslucentCell

    &:active
      +interactionTapScale

    &.selected
      +justify-content(space-between)
      background-color: $colorAction
      .title, .menuItemTitle
        color: $colorBackgroundPrimary
      path
        fill: $colorBackgroundPrimary

    &.detailed
      +size(100%, 48px)

      .cellBody
        +size(calc(100% - 48px), auto)
      
      .detail, .subtitle
        +offset(0, 1px, 0, 0)
        +fontCaption1
        +clipToBounds
        white-space: nowrap
        text-overflow: ellipsis
        color: $colorSubtitle

      .attribute
        +size(100%, auto)
        +fontSubhead2Emphasized
        color: $colorSubtitle
        text-align: right


    &.new
      .title
        color: $colorAction

    &.hasIcon
      .cellBody
        +size(calc(100% - 80px), auto)
      .icon
        +size(28px, 28px)
        +offset(0, 0, 8px, 0)
        +flex-shrink(0)
        +display-flex
        +align-items(center)
        +justify-content(center)
        +cornerRadius(8px)
        background-color: $colorBackgroundPlaceholder

  &.dateRange
    .cell
      +offset(0, 0, 0, 4px)
      +shadowNone
      background-color: $colorActionTranslucentCell

      .input
        +size(80px, auto)
        +fontFootnote
        color: $colorTitle
        text-align: right
    
      &:focus-within
        +shadowInputFocused

    .button
      +display-flex
      +justify-content(center)
      &:hover
        +transform(scale(1))

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded
  +cornerRadius(8px)
  +contentEdgeInsets(4px, 4px, 4px, 4px)
  +shadowPopover
  background-color: $colorBackgroundPrimary

  .MuiList-root
    +contentEdgeInsets(0, 0, 0, 0)

  .MuiListItem-root
    +size(100%, 32px)
    +cornerRadius(6px)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +fontSubhead2Emphasized
    color: $colorTitle

    &.Mui-selected
      background-color: $colorTint
      color: $colorBackgroundPrimary
