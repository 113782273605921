.encounterDetailsView
  &.splitView
    .mainView
      width: 240px

    .detailView
      .encounterDetails
        +contentEdgeInsets(12px, 12px, 12px, 56px)
        .tableSection
          +contentEdgeInsets(0, 0, 0, 0)

.testRuleSelectCell
  width: 50%

.encounterVersionCell
  +size(100%, auto)
  +contentEdgeInsets(12px, 8px, 12px, 8px)
  +flex-direction(column)
  +align-items(flex-start)

  .cellTitle
    +fontSubhead2Emphasized
    color: $colorTitle

  .cellSubtitle
    +fontCaption1
    color: $colorSubtitle

.encounterAttributeCell
  +size(100%, auto)
  +contentEdgeInsets(8px, 6px, 8px, 6px)
  +offset(0, 0, 0, 8px)
  +cornerRadius(8px)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +shadowSmall
  background-color: $colorBackgroundPrimary

  .currentValue, .previousValue
    +size(100%, auto)

  .previousValue
    .attributeDetail
      color: $colorSubtitle

  .attributeTitle
    +fontCaption1Emphasized
    color: $colorSubtitle

  .attributeDetail
    +fontSubhead
    color: $colorTitle

  .changeIndicator
    +cornerRadius(6px)
    +contentEdgeInsets(6px, 4px, 6px, 4px)
    background-color: $colorAction

    .indicatorTitle
      +fontCaption1Emphasized
      color: $colorBackgroundPrimary

  &.added
    .changeIndicator
      background-color: $colorSuccess

  &.deleted
    .changeIndicator
      background-color: $colorDestructive

  &.changed
    .changeIndicator
      background-color: $colorTint

  &.nested
    +display-flex
    +flex-direction(column)
    +align-items(flex-start)

    .attributeCellHeading
      +fontCaption1Emphasized
      color: $colorSubtitle

    .attributeCellSectionTitle
      +fontSubheadEmphasized
      color: $colorTitle

    .attributeCellSection
      +size(100%, auto)
      +offset(0, 6px, 0, 0)
      +display-flex
      +flex-direction(column)

      &.added, &.deleted, &.changed
        +cornerRadius(8px)
        +contentEdgeInsets(8px, 8px, 8px, 8px)
        
        .attributeCellSectionContent
          +display-flex
          +flex-direction(column)
          +justify-content(space-between)

          .sectionContentCells
            +size(80%, auto)
            +display-flex
            +flex-direction(column)

        &.changed
          background-color: $colorTintTranslucent
          .changeIndicator
            background-color: $colorTint

        &.deleted
          background-color: $colorDestructiveTranslucent
          .changeIndicator
            background-color: $colorDestructive

        &.added
          background-color: $colorSuccessTranslucent
          .changeIndicator
            background-color: $colorSuccess
      
      .encounterNestedAttributeCell
        +display-flex
        +flex-direction(row)
        +align-items(center)
        +offset(0, 0, 0, 4px)
        +contentEdgeInsets(8px, 6px, 8px, 6px)
        +cornerRadius(4px)
        background-color: $colorActionTranslucent

        .attributeTitle
          +fontFootnoteEmphasized
          color: $colorSubtitle

        .attributeDetail
          +fontSubhead2
          color: $colorTitle

        .currentValue
          +display-flex
          +flex-direction(row)
          +justify-content(space-between)
          +align-items(center)

        &.changed, &.deleted, &.added
          .currentValue
            +flex-direction(column)
            +align-items(flex-start)

        &.changed
          background-color: $colorTintTranslucent
          .changeIndicator
            background-color: $colorTint

        &.deleted
          background-color: $colorDestructiveTranslucent
          .changeIndicator
            background-color: $colorDestructive

        &.added
          background-color: $colorSuccessTranslucent
          .changeIndicator
            background-color: $colorSuccess