.fileImport

    .gridCell
        width: calc(100%/5)
        color: $colorTitle

        .gridButton
            width: 150px
            justify-content: center

    .emptylist
        display: flex
        flex-direction: column
        width: 100%
        height: 100%
        
        align-items: center
        color: $colorTitle
        font-weight: bold

        .importButton
            margin-top: 10px

        .subtitle
            padding-top: 30px

    .inputfile 
        display: none

    .inputfilebutton
        padding: 20px 10px
        width: 90%
        align-self: center
        background-color: $colorTint
        color: $colorTitleSecondary
        text-transform: uppercase
        text-align: center
        display: block
        margin-top: 10px
        cursor: pointer
        border-radius: 10px
        font-weight: 600
        font-size: 13px
 
    .uploadArea
        margin: auto
        height: 120px
        width: 80%
        background-color: $colorBackgroundPrimary
        border: 1px dashed white
        color: white
        text-align: center
        padding: 10px
        border-radius: 10px

        &.ok
            border: 1px solid white
            background-color: $colorTintTranslucent

        &.error
            border: 1px dashed red
            background-color: $colorDestructiveTranslucent
  
    .errorMessage
        font-size: 14px
        font-weight: bold
        color: $colorDestructive

    .title
        color: $colorTitle
 
    .uploadText
        text-decoration: underline
        font-weight: bold
        cursor: pointer

    .logsTable
        display: flex
        align-items: center
        margin: 20px 20px 0 20px

        .logsInfo   
            +fontSubheadEmphasized
            color: $colorSubtitle
            font-size: 13px

            &.title
                color: $colorTitle

            &.space
                padding-left: 20px

    .logsRecords        
        margin-top: 20px
        color: $colorSubtitle
        font-size: 12px
        text-align: center

        .content
            display: inline-block
            text-align: left
            min-width: 300px

            .tag
                display: flex

        .main
            font-weight: bold

    .divider
        border-top: 1px solid $colorSubtitle
        margin-top: 10px
        margin-bottom: 10px

    .fileUploadIcon
        +iconFileUpload

    .fileIcon
        +iconFile
    
.prefersDarkTheme
  .fileImport
    .fileUploadIcon
        +iconFileUploadOnDark
    .fileIcon
        +iconFileOnDark