$mobile-max-width: 1025px
$mobile-bottom-chrome: 83px

.hidden
  display: none

.locationMap
  +size(100vw, 90vh)

.submitLocation
  +size(auto, 10vh)
  +contentEdgeInsets(4px, 0, 4px, 0)
  font-size: 32px
  font-weight: bold
  +cornerRadius(6px)
  +display-flex
  +align-items(center)
  +justify-content(center)
  box-sizing: border-box
  cursor: pointer
  min-width: 180px
  background-color: $colorTint
  .title
    color: $colorTitleSecondary
    background-color: $colorTint
