.addParticipant
  max-width: 360px

  .content
    +contentEdgeInsets(12px, 12px, 12px, 56px)

    .sectionProvider
      .tableView
        .tableSection
          +contentEdgeInsets(0, 0, 0, 0)
      .providersList
        .searchInput
          +size(100%, 40px)
          .input
            +fontSubhead
            color: $colorAction
          &.isFocused
            &.hasInput
              .input
                color: $colorTitle

    .sectionExternal
      .phoneInput
        +offset(0, 12px, 0, 0)
      .editorCell
        +offset(0, 8px, 0, 12px)
