// Layout
.testRuleCell
  +size(100%, auto)
  +offset(0, 0, 0, 12px)
  +display-flex
  +flex-direction(column)
  +clipToBounds
  
  .testDetails
    +contentEdgeInsets(8px, 8px, 8px, 8px)
    +display-flex
    +flex-direction(row)
    +justify-content(space-between)
    +align-items(center)

    .filterDetails
      +display-flex
      +flex-direction(row)

      .filterDetailItem
        +offset(0, 0, 4px, 0)
        +contentEdgeInsets(8px, 4px, 8px, 4px)

  .separator
    +size(100%, 1px)

  .testStatus
    +contentEdgeInsets(12px, 8px, 12px, 8px)


// Styles
.testRuleCell
  background-color: $colorBackgroundPrimary
  +shadowMedium
  +cornerRadius(6px)

  .separator
    background-color: $colorSeparator

  .testStatus
    background-color: $colorBackgroundSecondary

  .testStatusTitle
    +fontCaption1Emphasized
    color: $colorSubtitle

  .filterDetailItem
    +cornerRadius(4px)
    +fontFootnote
    background-color: $colorActionTranslucentCell
    color: $colorTitle

    &.filterSchema, &.filterValue
      +fontFootnoteEmphasized
      color: $colorTitle

  &.fail
    .testStatusTitle
      color: $colorTitle

    .filterDetailItem
      background-color: $colorDestructiveTranslucent
      color: $colorDestructive

  &.pending
    .filterDetailItem
      color: $colorTitleDisabled
