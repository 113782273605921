// Layout
.taskCell
  +contentEdgeInsets(12px, 0, 12px, 0)
  +offset(0, 0, 0, 8px)
  +size(100%, 56px)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +cornerRadius(8px)

  .taskCellIcon
    +size(24px, 24px)
    +cornerRadius(12px)
    +flex-shrink(0)
  
  .taskCellBody
    +offset(10px, 0, 0, 0)
    +size(100%, auto)
    +display-flex
    +flex-direction(column)

    .taskCellSubtitle
      +display-flex
      +flex-direction(row)

    .taskCellSubtitleSeparator
      +offset(4px, 0, 4px, 0)

  .taskCellDetail
    +display-flex
    +flex-direction(row)
    +flex-shrink(0)

    .taskCellDueDate
      +cornerRadius(4px)
      +contentEdgeInsets(6px, 3px, 6px, 3px)

// Styles
.taskCell
  +shadowCell
  background-color: $colorBackgroundPrimary

  .taskCellTitle
    +fontSubheadEmphasized
    color: $colorTitle

  .taskCellSubtitle
    +fontCaption1
    color: $colorSubtitle

  .taskCellDueDate
    +fontFootnoteEmphasized

  &.overdueTask
    .taskCellDueDate
      background-color: $colorDestructiveTranslucent
      color: $colorDestructive
  
  &.inProgressTask
    .taskCellDueDate
      background-color: $colorActionTranslucent
      color: $colorAction
  
  &.upcomingTask
    .taskCellIcon
      background-color: $colorActionTranslucentCell

    .taskCellDueDate
      background-color: $colorActionTranslucent
      color: $colorAction
  
  &.closedTask
    +shadowNone
    background-color: $colorActionTranslucentCell

    .taskCellDueDate
      background-color: $colorTintTranslucent
      color: $colorTint      

// Hack for Broken Modal Component
.taskCellContainer
  +size(100%, auto)
