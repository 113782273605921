// Layout
.providerRotationCell
  +size(100%, auto)
  +contentEdgeInsets(10px, 10px, 10px, 10px)
  
  .providerProfilePicutre
    +flex-shrink(0)
    +offset(0, 0, 10px, 0)

  .activityStatus
    +size(auto, 24px)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +flex-shrink(0)
    +display-flex
    +align-items(center)
    +flex-direction(row)

    .statusIcon
      +offset(0, 0, 4px, 0)

// Styles
.providerRotationCell
  .activityStatus
    +cornerRadius(6px)
    background-color: $colorActionTranslucent
    
    .statusIcon
      path
        fill: $colorAction

    .statusTitle
      +fontCaption1Emphasized
      color: $colorAction

  &.active
    .activityStatus
      background-color: $colorSuccess
    
    .statusIcon
      path
        fill: $colorBackgroundPrimary

    .statusTitle
      color: $colorBackgroundPrimary
