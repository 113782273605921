.chatInputBar
  +positionAbsoluteWithOffset(0, auto, 0, 0)
  +contentEdgeInsets(16px, 16px, 16px, 16px)
  box-sizing: border-box

  .chatInputCell
    +size(100%, auto)
    +contentEdgeInsets(12px, 9px, 12px, 9px)
    +cornerRadius(18px)
    +shadowCell
    +cursorText
    +interactionTransition
    min-height: 36px
    position: relative
    background-color: $colorBackgroundPrimary

    &:focus-within
      +shadowInputFocused

    .DraftEditor-root
      +size(100%, auto)
      min-height: 18px
      .public-DraftEditorPlaceholder-root
        +positionAbsoluteWithOffset(12px, auto, auto, auto)

    .send
      +size(24px, 24px)
      +positionAbsoluteWithOffset(auto, auto, 6px, 6px)
      +cursorAction
      +interactionTransition
      &:hover
        +interactionHoverScale
      &:active
        +interactionTapOpacity
        +interactionTapScale

      .icon
        +iconConversationChatInputActionSend

      &.disabled
        +cursorDefault
        +interactionNone
        .icon
          +iconConversationChatInputActionSendDisabled

    .attachment
      +size(auto, auto)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +cornerRadius(8px)
      position: relative

      .clear
        +positionAbsoluteWithOffset(auto, auto, 10px, auto)
        +iconConversationChatInputActionAttachmentClear
        +cursorAction
        &:active
          +interactionTapOpacity

      &.compact
        +size(244px, 48px)
        +contentEdgeInsets(12px, 0, 10px, 0)
        +offset(0, 0, 0, 12px)
        background-color: $colorActionTranslucentCell

        &:last-child
          +offset(0, 0, 0, 0)

        .icon
          +offset(0, 0, 12px, 0)
        .attachmentDetails
          +size(calc(100% - 64px), auto)
          +offset(0, 0, 4px, 0)
          +display-flex
          +flex-direction(column)
          .attachmentTitle
            +fontFootnoteEmphasized
            color: $colorTitle
          .attachmentSubtitle
            +clipToBounds
            +fontCaption1
            white-space: nowrap
            text-overflow: ellipsis
            color: $colorSubtitle

      &.videoCall
        .icon
          +iconConversationAttachmentVideoCall

      &.file
        .icon
          +iconConversationAttachmentFile

      &.serviceOffering
        .icon
          +iconConversationAttachmentService

      &.referral
        .icon
          +iconConversationAttachmentReferral

      &.location
        .icon
          +iconConversationAttachmentLocation

      &.library
        .icon
          +iconConversationAttachmentArticle

    .input, .DraftEditor-root
      +fontFootnote
      color: $colorTitlePlaceholder
    &.hasInput
      .input, .DraftEditor-root
        color: $colorTitle

  .chatInputActions
    +size(100%, auto)
    +offset(0, 12px, 0, 0)
    +display-flex
    +flex-direction(row)
    position: relative

    .actionsOverlay
      +positionAbsoluteWithOffset(auto, 0, 0, 0)
      +size(48px, auto)
      background-image: linear-gradient(90deg, $colorClear 0%, $colorBackgroundTertiary 64%, $colorBackgroundTertiary 100%)

    .actionsList
      +size(100%, auto)
      +display-flex
      +flex-direction(row)
      +hideScrollBars
      overflow-x: scroll

    .inputAction
      +size(auto, 28px)
      +contentEdgeInsets(12px, 0, 12px, 0)
      +offset(0, 0, 12px, 0)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +flex-shrink(0)
      +cornerRadius(6px)
      +cursorAction
      +interactionTransition
      background-color: $colorActionTranslucent

      .title
        +offset(8px, 0, 0, 0)
        +fontFootnoteEmphasized
        color: $colorAction

      &.spacer
        width: 36px
        background-color: $colorClear

      &.videoCall
        .icon
          +iconConversationChatInputActionVideo

      &.addAttachment
        .icon
          +iconConversationChatInputActionAttachment

      &.serviceOffering
        padding-left: 7px
        .icon
          +iconConversationChatInputActionService

      &.refer
        padding-left: 7px
        .icon
          +iconConversationChatInputActionRefer

      &.requestLocation
        padding-left: 8px
        .icon
          +iconConversationChatInputActionLocation

      &.library
        padding-left: 8px
        .icon
          +iconConversationChatInputActionArticle

      &:hover
        background-color: $colorActionTranslucentHover

      &:active
        +interactionTapScale
        +interactionTapOpacity

.DraftEditor-root
  +fontFootnote
  color: $colorTitlePlaceholder
.hasInput
  .DraftEditor-root
    color: $colorTitle
