.customerView
  +size(100%, auto)
  +minSize(100%, 100vh)
  +display-flex
  +flex-direction(column)
  +align-items(center)
  background-color: $colorTintSecondary

  .customerContentView
    +size(100%, auto)
    +maxSize(420px, auto)
    +offset(0, 24px, 0, 88px)
    +contentEdgeInsets(16px, 0, 16px, 0)
    +display-flex
    +flex-direction(column)
    +align-items(center)
    @media only screen and (max-width: 414px)
      +contentEdgeInsets(0, 0, 0, 0)
      .customerForm
        +cornerRadius(0px)

  .logo
    +offset(0, 0, 0, 32px)
    +iconGatekeeperLogo
    +interactionLogo

  .customerForm
    +size(100%, auto)
    +shadowMedium
    +cornerRadius(8px)
    +clipToBounds
    +noUserSelect
    +cursorDefault
    background-color: $colorBackgroundPrimary

    .formHeader
      +size(100%, auto)
      +contentEdgeInsets(32px, 14px, 32px, 14px)
      +noUserSelect
      +cursorDefault
      box-sizing: border-box
      position: relative

      .title
        +fontSubheadEmphasized
        color: $colorTitle
        text-align: center

      .separator
        +size(100%, 1px)
        +positionAbsoluteWithOffset(0, auto, 0, 0)
        +shadowSeparator

    .formContent
      +contentEdgeInsets(12px, 16px, 12px, 16px)
      background-color: $colorBackgroundSecondary

      .tableSection
        +offset(0, 0, 0, 24px)

        .header
          +offset(0, 0, 0, 6px)


    .confirmation
      +size(100%, auto)
      +display-flex
      +flex-direction(column)
      +align-items(center)

      .placeholder
        +contentEdgeInsets(0px, 24px, 0px, 24px)
        +size(100%, auto)
        +maxSize(256px, auto)

        .title
          +size(100%, auto)
          +fontTitle1Emphasized
          color: $colorTitle
          text-align: center

        .body
          +offset(0, 4px, 0, 0)
          +size(100%, auto)
          +fontSubhead
          color: $colorSubtitle
          text-align: center
