.rulesView
    .versionEdit
    .target
      z-index: 0
      position: relative

      .targetListItem
        +size(100%, auto)
        +display-flex
        +flex-direction(row)
        +offset(0, 0, 0, 12px)

        .targetCell
          +size(100%, auto)
          +display-flex
          +flex-direction(row)
          +cornerRadius(10px)
          +contentEdgeInsets(8px, 8px, 8px, 8px)
          +offset(0, 0, 8px, 0)
          +shadowSmallInset
          background-color: $colorBackgroundTertiary

          .targetBody
            +size(80%, auto)
            +offset(0, 0, 8px, 0)
            +display-flex
            +flex-direction(column)

            .targetTitle
              .DraftEditor-root
                .public-DraftEditorPlaceholder-root
                  +fontSubheadEmphasized
                  color: $colorTitlePlaceholder

                .DraftEditor-editorContainer
                  +fontSubheadEmphasized
                  color: $colorTitle

            .targetTextMessage
              min-height: 80px
              .inputAction
                +positionAbsoluteWithOffset(12px, auto, auto, 12px)
              .DraftEditor-root
                min-height: 80px
                margin-bottom: 38px
                .inputVariableTag
                  +contentEdgeInsets(6px, 3px, 6px, 3px)
                  +cornerRadius(4px)
                  +fontFootnoteEmphasized
                  color: $colorTitleSecondary
                  background-color: $colorTint

            .targetResponseGroup
              +offset(0, 0, 0, 8px)
              +interactionTableViewCellEditor
              .inputCell
                box-shadow: none
                color: black
              .selectCell
                +size(100%, 100%)
                +contentEdgeInsets(0, 0, 0, 0)
                +interactionTableViewCellNone
                min-width: 120px

                .selectCell__menu
                  // max-width: 160px

                .selectCell__control
                  +contentEdgeInsets(0, 0, 0, 0)
                  border: none

                  .selectCell__value-container
                    .selectCell__placeholder, .selectCell__single-value, .selectCell__input 
                      text-align: right
                      width: 100%

                    &.selectCell__value-container--has-value
                      .selectCell__single-value
                        text-align: right
                        width: 100%

                  &.selectCell__control--is-focused
                    +shadowNone

            .delayFromTrigger
              +interactionTableViewCellEditor
              +cursorDefault
              +justify-content(space-between)
              .selectCell, .inputCell
                +offset(0, 0, 0, 0)
                +contentEdgeInsets(0, 0, 0, 0)
                +interactionTableViewCellNone
              .inputCell
                +shadowNone
                .input
                  +fontSubheadEmphasized
                  color: $colorTitle
                  text-align: right
              .selectCell
                +size(auto, 100%)
                +shadowNone
                min-width: 96px

                .selectCell__control
                  +contentEdgeInsets(0, 0, 0, 0)
                  border: none
                  .selectCell__value-container
                    .selectCell__placeholder, .selectCell__single-value, .selectCell__input 
                      text-align: right
                      width: 100%

                    &.selectCell__value-container--has-value
                      .selectCell__single-value
                        text-align: right
                        width: 100%

                  &.selectCell__control--is-focused
                    +shadowNone

          .targetType
            +size(20%, 40px)

        .removeTarget
            +size(32px, 32px)
            +offset(0, 12px, 0, 0)
            +cornerRadius(6px)
            +display-flex
            +align-items(center)
            +justify-content(center)
            +flex-shrink(0)
            +cursorAction
            +interactionTransition
            background-color: $colorActionTranslucent

            &:hover
              background-color: $colorActionTranslucentHover
            &:active
              +interactionTapOpacity

            .icon
              +iconTableViewSectionRowAccessoryDelete
