// Layout
.satisfactionPersonnelCell
  position: relative

  .personnelDetails
    +display-flex
    +flex-direction(row)

  .personnelRole
    +display-flex
    +flex-direction(row)
    +align-items(center)

    svg
      +offset(0, 0, 4px, 0)

  .personnelID
    +offset(8px, 0, 0, 0)

  .personnelEmail
    +offset(8px, 0, 0, 0)
  
  .cellActions
    +positionAbsoluteWithOffset(auto, 0 , 0, 0)
    +size(auto, 100%)
    +align-items(center)
    opacity: 0
    max-width: 160px
    z-index: 2

  .cellDetails
    +display-flex
    +flex-direction(row)

    .detailCell
      +offset(16px, 0, 0, 0)
      &:last-child
        min-width: 44px

      .detailValue
        +offset(0, 0, 0, 4px)

// Styles
.satisfactionPersonnelCell
  .personnelID
    +fontFootnoteEmphasized
    color: $colorSubtitle

  .roleName
    +fontFootnoteEmphasized
    color: $colorSubtitle

  .detailValue
    +fontSubheadEmphasized
    color: $colorTitle

  .detailTitle
    +fontCaption2
    color: $colorSubtitle

  .cellActions
    background-color: $colorBackgroundPrimary

// Interaction
.satisfactionPersonnelCell
  .cellActions
    +interactionTransition

  &:hover
    .cellActions
      opacity: 1
