.teamMembersList

.teamMembersListPager
  .MuiPaginationItem-page.MuiPaginationItem-root
    color: var(--colorSubtitle)

.teamMemberCell
  +size(100%, auto)
  +offset(0, 8px, 0, 0)
  +contentEdgeInsets(8px, 8px, 8px, 8px)
  +cornerRadius(6px)
  +shadowCell
  +interactionTransition
  +cursorAction
  +noUserSelect
  background-color: $colorBackgroundPrimary

  .profilePicture
    .icon
  .memberDetails
    .memberName
      +fontSubheadEmphasized
      color: $colorTitle

  &:hover
    background-color: $colorBackgroundPrimaryHover
    +shadowCellElevated

  &:active
    +interactionTapScale
    +interactionTapOpacity

  &.selected
    background-color: $colorTint
    .memberDetails
      .memberName
        color: $colorTitleSecondary