// Layout
.modalDialog.taskView
  +size(auto, auto)
  +display-flex
  +flex-direction(row)

  .mainView
    +size(368px, auto)

    .titleBar
      +contentEdgeInsets(12px, 0, 12px, 0)

  .detailView
    +size(368px, auto)

    .tableView
      overflow: scroll
      overflow-x: hidden
      height: calc(100% - 56px)

  .tableSection
    +contentEdgeInsets(12px, 0, 12px, 16px)

    .sectionTitle
      +contentEdgeInsets(0, 0, 0, 4px)

  .taskAssigneeCell
    +size(100%, 56px)

    .taskAssigneePicture
      +size(36px, 36px)
      +offset(0, 0, 10px, 0)
      +cornerRadius(50%)
      +flex-shrink(0)

    .cellSubtitle
      +flex-direction(row)
      +display-flex
      +justify-content(space-between)

  .taskConversationCell
    +size(100%, 56px)

    .taskConversationPicture
      +size(36px, 36px)
      +offset(0, 0, 10px, 0)
      +cornerRadius(50%)
      +flex-shrink(0)

    .taskConversationView
      +justify-content(center)
      min-width: 64px

  .addTaskActionButton
    width: 100%

  .taskActionCell
    +size(100%, auto)
    +offset(0, 0, 0, 8px)
    +contentEdgeInsets(12px, 10px, 12px, 10px)
    +display-flex
    +flex-direction(column)
    +align-items(flex-start)

    .actionDetails
      display: inline-block

      .actionCopy
        +offset(4px, 0, 4px, 0)
        display: inline-block

        &.copyEmphasized
          +offset(0, 0, 0, 0)

    .actionNotes
      +offset(0, 8px, 0, 0)

// Styles
.modalDialog.taskView
  .mainView
    background-color: $colorBackgroundSecondary

    .titleBar
      background-color: $colorClear
      .titleBarTitle
        +fontBodyEmphasized
        color: $colorTitle

    .taskNotes
      +fontSubhead
      color: $colorTitle
      word-break: break-word
      height: 100px
      overflow-y: scroll

  .detailView
    background-color: $colorBackgroundPrimary
    box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.06)

  .taskAssigneeCell
    .taskAssigneePicture
      background-color: $colorBackgroundPlaceholder

    .taskAssigneeDeadline
      +fontFootnoteEmphasized
      color: $colorSubtitle

  .taskConversationCell
    background-color: $colorTint

    .taskConversationPicture
      background-color: $colorBackgroundPlaceholder

    .cellTitle
      color: $colorTitleSecondary

    .cellSubtitle
      color: $colorTitleSecondary
      opacity: 0.8

  .taskActionCell
    +cornerRadius(6px)
    +shadowNone
    background-color: $colorBackgroundSecondary
    
    .actionType
      +fontSubheadEmphasized
      color: $colorTitle

    .actionCopy
      +fontFootnote
      color: $colorTitle

      &.copyEmphasized
        +fontFootnoteEmphasized
        color: $colorTitle

    .actionNotes
      +fontFootnote
      color: $colorSubtitle
