.rootView
  +size(100%, 100%)
  +display-flex
  +flex-direction(column)
  +align-items(center)
  +flex-grow(0)
  position: relative
  background-color: $colorBackgroundSecondary

  &.publicFeedbackView
    +size(100%, 100vh)

  &.publicFocusedFeedbackView
    +size(100%, 100vh)
    @media screen and (max-width: 650px)
      +size(96%, 100vh)

  .navBar
    .separator
      +positionAbsoluteWithOffset(24px, 0, 24px, 0)

  .scrollView
    +contentEdgeInsets(0, 0, 0, 24px)
    +size(100%, 100%)
    overflow-y: scroll

  .tableView
    +size(100%, auto)
    overflow-y: auto
    .tableSection
      +contentEdgeInsets(18px, 0, 18px, 18px)
      +flex-direction(row)
      +flex-wrap(wrap)
      +offset(0, 18px, 0, 0)
      .cell, .tableCell
        // +contentEdgeInsets(0, 0, 0, 0)
        +clipToBounds
        +cursorDefault
        +shadowCell
        // +justify-content(space-between)
        &:active
          transform: scale(1)
          opacity: 1
        &:hover
          transform: scale(1)
          opacity: 1
        +size(100%, 60px)
        +offset(6px, 6px, 6px, 6px)
        @media only screen and (min-width: 1200px)
          +size(calc((100%/2) - 12px), 60px)
        @media only screen and (min-width: 1600px)
          +size(calc((100%/3) - 12px), 60px)
        @media only screen and (min-width: 1800px)
          +size(calc((100%/4) - 12px), 60px)
        @media only screen and (min-width: 2000px)
          +size(calc((100%/5) - 12px), 60px)

        .cellActions
          +offset(0, 0, 12px, 0)
          +size(100%, auto)
          +display-flex
          +flex-direction(row)
          +justify-content(flex-end)

          .cellAction
            +contentEdgeInsets(12px, 0, 12px, 0)
            +offset(8px, 0, 0, 0)
            &.tint
              +offset(16px, 0, 0, 0)
