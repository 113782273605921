.messageCell
  +size(100%, auto)
  +display-flex
  +flex-direction(row)
  +offset(0, 0, 0, 16px)
  +flex-shrink(0)
  
  .profilePicture
    +size(36px, 36px)
    +cornerRadius(50%)
    +offset(0, 2px, 10px, 0)
    +flex-shrink(0)
    background-color: $colorBackgroundPlaceholder

  .deliveryStatus
    +size(100%, auto)
    +offset(0, 4px, 0, 0)
    +fontCaption2Emphasized
    color: $colorSubtitle
    text-align: right
    text-transform: capitalize

  .messageBubble
    +size(100%, auto)
    +cornerRadius(12px)
    +contentEdgeInsets(12px, 10px, 12px, 12px)
    +clipToBounds
    +shadowSmall
    box-sizing: border-box
    max-width: 306px
    background-color: $colorBackgroundPrimary
    
    .attributes
      +display-flex
      +flex-direction(row)
      +justify-content(space-between)
      +offset(0, 0, 0, 2px)
      .timestamp
        +fontCaption1
        color: $colorSubtitle
      .source
        +offset(12px, 0, 0, 0)
        +fontCaption1Emphasized
        color: $colorSubtitle

    .text
      +fontFootnote
      +userSelect
      color: $colorTitle
      white-space: pre-line
  .generic-attachment
    +size(100%, 50px)
    +cornerRadius(8px)
    background-color: $colorBackgroundPrimary


  &.business
    +justify-content(flex-end)

    .profilePicture
      display: none

    .messageBubble
      +shadowSmallSubtle
      background-color: $colorTintSecondary
      .text
        color: $colorTitle
        white-space: pre-line
      .attributes
        .timestamp
          color: $colorTitle
          opacity: 0.40
        .source
          color: $colorTitle

  &.bot, &.messageBot
    +flex-direction(column)
    +align-items(center)

    .profilePicture
      +offset(0, 0, 0, -16px)
      background-color: $colorTint
      z-index: 2
      .icon
        +iconEntityChatbot

    .messageBubble
      +size(100%, auto)
      +shadowNone
      max-width: 400px
      background-color: $colorActionTranslucent

  &.hasAttachment
    .messageBubble
      background-color: $colorActionTranslucentCell
