.candidatesHeader
    display: flex
    justify-content: space-between

.candidatesTitle
    color: var(--colorTitle)
    font-weight: bold

.candidatesSearchInput
    border: none
    color: white

.candidatesFilter
    display: flex
    gap: 0.5rem

.candidateItem
    width: 165px

.candidatesScrollView
    overflow-x: scroll

.candidatesList
    height: auto
    width: fit-content

.candidatesStats
    margin: 0 0 0 12px
    height: auto
    display: flex
    flex-direction: row
    -ms-flex-shrink: 0
    flex-shrink: 0
