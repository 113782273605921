.activitySection
  &.sectionAllEncoutners
    +size(100%, auto)
    // +clipToBounds

.allEncountersList
  +size(100%, auto)
  +display-flex
  +flex-direction(column)
  overflow-x: hidden

.detailedEncountersTable
  +size(100% !important, auto)
  +display-flex
  +flex-direction(column)
  +cornerRadius(8px)
  +clipToBounds
  +shadowMedium
  background-color: $colorBackgroundPrimary
  overflow-x: auto
  width: fit-content
  .encountersTableCells
    +size(100%, auto)
    +display-flex
    +flex-direction(column)

.encountersTableHeader, .encountersTableCell
  +size(100%, 40px)
  +contentEdgeInsets(12px, 0, 12px, 0)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  position: relative
  
  .separator
    +positionAbsoluteWithOffset(0, 0, 0, 0)
    +shadowSeparator
    z-index: -1

  .encountersCell
    +size(160px, 100%)
    +contentEdgeInsets(0, 0, 20px, 0)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    +clipToBounds
    .title
      +size(100%, auto)
      +fontFootnote
      color: $colorTitle
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .ruleItem
      +size(auto, 20px)
      +contentEdgeInsets(8px, 0, 8px, 0)
      +cornerRadius(5px)
      +display-flex
      +align-items(center)
      background-color: $colorTint
      .title
        +size(auto, auto)
        +fontCaption1Emphasized
        color: $colorTitleSecondary
      &.empty, &.none
        background-color: $colorActionTranslucent
        .title
          +fontCaption1Emphasized
          color: $colorAction
    &.pending
      .title
        color: $colorSubtitle
    &.columnIncidentType
      .title
        +fontFootnoteEmphasized
        color: $colorTitle

    .testIndicator
      +size(auto, 8px)
      +contentEdgeInsets(4px, 3px, 4px, 3px)
      +offset(0, 0, 8px, 0)
      +cornerRadius(4px)
      display: inline-block
      background-color: $colorTest

      .indicatorTitle
        +fontCaption2Emphasized
        color: $colorTest
        display: none

    &:hover
      .testIndicator
        +size(auto, 18px)
        .indicatorTitle
          color: $colorBackgroundPrimary
          display: block

  .encountersColumn
    &.columnIncidentType
      +minSize(148px, auto)
    &.columnCallerName
      +minSize(140px, auto)
    &.columnCallerNumber
      +minSize(140px, auto)
    &.columnSource
      +minSize(80px, auto)
    &.columnLocation
      +minSize(240px, auto)
    &.columnReportedDate
      +minSize(182px, auto)
    &.columnStatus
      +minSize(108px, auto)
    &.columnPriority
      +minSize(240px, auto)
    &.columnIncidentNumber
      +minSize(100px, auto)
    &.columnResponseTime
      +minSize(100px, auto)
    &.columnRuleMatches
      +minSize(140px, auto)
    &.columnRuleTarget
      +minSize(182px, auto)
    &.columnActions
      +minSize(16px, auto)

.encountersTableHeader
  +contentEdgeInsets(0, 0, 0, 0)
  +noUserSelect
  background-color: $colorBackgroundSecondary
  .resizer
    background-color: $colorBackgroundPrimary
    &:active
      background-color: $colorTint
  .headerCell
    +size(auto, 100%)
    +contentEdgeInsets(12px, 9px, 12px, 0)
    background-color: $colorBackgroundSecondary
    .title
      +fontFootnoteEmphasized
      color: $colorTitle

.encountersTableCell
  +contentEdgeInsets(0, 0, 0, 0)
  .encountersCell
    +contentEdgeInsets(12px, 10px, 12px, 0)
    position: relative
  &:hover
    .encountersCell
      background-color: $colorBackgroundSecondary
  &.selected
    +cornerRadius(6px)
    background-color: $colorTint
    .encountersCell
      background-color: $colorTint
      .title
        color: $colorTitleSecondary

.encounter-enter
  opacity: 0
.encounter-enter-active
  opacity: 1
  transition: opacity 1000ms ease-in
.encounter-exit
  opacity: 1
.encounter-exit-active
  opacity: 0
  transition: opacity 1000ms ease-in

.resizer
  display: inline-block
  background: blue
  width: 10px
  height: 100%
  position: absolute
  right: 0
  top: 0
  transform: translateX(50%)
  z-index: 1
  touch-action: none
  &.isResizing
    background: red




