.gatekeeperView
  +size(100vw, 100vh)
  +display-flex
  +flex-direction(column)
  +justify-content(center)
  +align-items(center)
  background-color: $colorBackgroundTertiary

  .gatekeeperContentView
    +size(auto, auto)
    +offset(0, 0, 0, 88px)
    +display-flex
    +flex-direction(column)
    +align-items(center)

    .gatekeeperForm
      +size(420px, auto)
      +shadowMedium
      +cornerRadius(8px)
      +clipToBounds
      background-color: $colorBackgroundPrimary

      .formHeader
        +size(100%, auto)
        +contentEdgeInsets(32px, 14px, 32px, 14px)
        +noUserSelect
        +cursorDefault
        box-sizing: border-box
        position: relative

        .title
          +fontSubheadEmphasized
          color: $colorTitle
          text-align: center

        .separator
          +size(100%, 1px)
          +positionAbsoluteWithOffset(0, auto, 0, 0)
          +shadowSeparator

      .formContent
        +contentEdgeInsets(12px, 16px, 12px, 16px)
        background-color: $colorBackgroundSecondary

        .tableSection
          +offset(0, 0, 0, 24px)

          .forgotPassword
            +offset(0, 8px, 0, 0)
            +fontCaption1Emphasized
            +interactionLink
            color: $colorAction

        .footnote
          +offset(0, 0, 0, 16px)
          +contentEdgeInsets(12px, 0, 12px, 0)
          +fontFootnote
          +noUserSelect
          +cursorDefault
          color: $colorSubtitle

        .button
          &:hover
            +transform(scale(1))

        .legalFootnote
          +offset(0, 16px, 0, 0)
          +noUserSelect
          +cursorDefault
          +fontCaption1
          color: $colorSubtitle
          .action
            +interactionLink
            +fontCaption1Emphasized
            color: $colorAction

    .additionalActions
      +size(100%, auto)
      +offset(0, 24px, 0, 0)
      +contentEdgeInsets(12px, 0, 12px, 0)
      +display-flex
      +justify-content(center)
      box-sizing: border-box
      .button
        max-width: 280px
        text-decoration: none
        .title
          color: $colorAction
        &:hover
          background-color: $colorActionTranslucentHover
          +transform(scale(1))

  &.logIn
    .gatekeeperForm
      .formContent
        .sectionCredentials
          +offset(0, 0, 0, 16px)
        .cell
          &.phone
            position: relative
            .tel-input
              +fontSubhead
              background-color: $colorClear
              color: $colorTitle
              text-align: right
            .flag-dropdown
              .selected-flag
                &:hover
                  background-color: $colorClear
            .react-tel-input
              +positionAbsoluteWithOffset(auto, auto, 12px, auto)
              width: 156px
              .tel-input
                width: 156px

  &.resetPassword
    .gatekeeperForm
      .formContent
        .sectionCredentials
          +offset(0, 0, 0, 16px)
          .footer
            +offset(0, 10px, 0, 0)

  .verifyContact
    .gatekeeperTitle
      +fontTitle1Emphasized
      color: $colorTitle
    .MuiInputBase-root
      +cornerRadius(12px)
      background-color: $colorBackgroundSecondary
    .submitButtonWrapper
      .MuiButtonBase-root
        +size(100%, 40px)
        +cornerRadius(6px)
        background-color: $colorTint
        .title
          +fontSubheadEmphasized
          text-transform: none
    label
      color: $colorSubtitle
    input
      color: $colorSubtitle

.copyright
  +positionAbsoluteWithOffset(50%, auto, auto, 16px)
  +transform(translate(-50%, 0))
  +noUserSelect
  +cursorDefault
  +fontFootnote
  color: $colorSubtitle
  text-align: center
  .copyrightLink
    +cursorAction
    +fontFootnoteEmphasized
    color: $colorSubtitle
    text-decoration: none
