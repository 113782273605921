.tasksView
  .navBar
    .filterButton
      +offset(0, 0, 8px, 0)
  .navActions
    gap: 0.5rem
  .tasksList
    .tableSection
      .taskCell
        +cursorAction

        .taskCellContent
          +size(100%, 100%)
          +display-flex
          +flex-direction(row)
          +align-items(center)

  .taskCustomFilterSelect
    color: #b4b4c0
    background-color: transparent
    border: none
    width: 95%
    &:focus
      border: none
      outline: none

  .addTaskButton
    +positionFixedWithOffset(auto, auto, 24px, 24px)
    +shadowLarge
    
    .buttonIcon
      +offset(0, 0, 4px, 0)
      color: $colorTitleSecondary

.taskDetail
  .tableView
    .taskAssignee
      +interactionNone
      +size(100%, 56px)
      .icon
        +size(36px, 36px)
        +cornerRadius(50%)
        background-color: $colorActionTranslucent
      .dueDetails
        +display-flex
        +justify-content(space-between)
        +flex-direction(row)
        .dueCountdown
          +fontFootnoteEmphasized
          color: $colorSubtitle

    .sectionNotes
      .inputCell
        input
          text-align: left

.taskListPager
  ul
    justify-content: center
    padding: 16px
    background-color: white