.amplify-button[data-variation='primary']
  background: #FF9900
  max-width: 153px
  text-transform: uppercase
  align-self: flex-end
  font-weight: 100

[data-amplify-authenticator] [data-amplify-container]
    width: var(--amplify-components-authenticator-container-width-max)
    padding-top: 40px
    padding-bottom: 40px

[data-amplify-authenticator] [data-amplify-router]
  background-color: var(--amplify-components-authenticator-router-background-color)
  box-shadow: var(--amplify-components-authenticator-router-box-shadow)
  border-color: var(--amplify-components-authenticator-router-border-color)
  border-width: var(--amplify-components-authenticator-router-border-width)
  border-style: var(--amplify-components-authenticator-router-border-style)
  border-radius: 5px

