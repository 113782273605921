.encounterCard
  .MuiCardContent-root
    height: 122px
    display: flex
    justify-content: center
    align-items: center
    border: 1.5px solid #667C89
    border-bottom: none
    border-radius: 4px 4px 0px 0px
    .MuiTypography-root
      font-weight: 900
      color: #667C89
  .MuiCardActions-root
    padding: 0px
    .MuiButton-root
      width: 100%
      justify-content: flex-start
      padding: 8px 8px 8px 12px
      border: 1.5px solid #667C89
      border-radius: 0px 0px 4px 4px
      text-transform: none
  &.configured
    .MuiCardContent-root
      background: #667C89
      .MuiTypography-root
        color: #FFFFFF

.uploaderContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 220px
  flex-direction: column
  align-items: center
  gap: 5px
  background: #E6E6E6
  border: 1px dashed #676767
  box-sizing: border-box
  border-radius: 8px
  &.selectedFile
    background: rgba(82, 121, 235, 0.2)
    border: 1px solid #676767
  &.error
    background: rgba(254, 106, 106, 0.2)
    border: 1px dashed #D41B1B
  .browseButton
    cursor: pointer
    font-weight: 900
  .errorMessage
    font-weight: bold
    color: #D51B1B

.wizardButtons
  display: flex
  justify-content: flex-end
  gap: 10px

.unmappedTargetFieldsTitle
  font-weight: 500
  font-size: 16px
  margin-bottom: 10px

.unmappedTargetFieldsContainer
  display: flex
  flex-wrap: wrap
  gap: 10px
  margin-bottom: 20px

  .unmappedTargetField
    background: var(--colorTint)
    color: white
    padding: 5px 10px
    border-radius: 25px
    cursor: pointer
    transition: background-color 0.5s ease
    &:hover
      background: var(--colorTintHover)

.selectionTable
  .removeButton
    +offset(8px, 0, 0, 0)
    +size(32px, 32px)
    +cornerRadius(6px)
    +display-flex
    +align-items(center)
    +justify-content(center)
    +flex-shrink(0)
    +interactionTransition
    +cursorAction
    background-color: $colorActionTranslucent
    &:active
      +interactionTapOpacity
    .icon
      +iconTableViewSectionRowAccessoryDelete

  .sourceDropdown__value-container
    .sourceDropdown__multi-value
      &:not(:nth-last-child(-n+2))
        margin-right: 15px
        position: relative
      &:not(:nth-last-child(-n+2)):after
        content: '+'
        position: absolute
        left: calc(100% + 5px)