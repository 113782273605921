.tableView
  .tableSection
    +display-flex
    +flex-direction(column)

    .header
      +size(100%, auto)
      +offset(0, 16px, 0, 6px)
      +fontCaption1Emphasized
      +noUserSelect
      +cursorDefault
      color: $colorSubtitle

    .sectionTitle
      +size(100%, auto)
      +contentEdgeInsets(2px, 0, 2px, 6px)
      +fontFootnoteEmphasized
      +noUserSelect
      +cursorDefault
      color: $colorSubtitle

      .subtitle
        font-size: 11px

    .sectionRow
      +size(100%, auto)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +offset(0, 0, 0, 12px)
      
      .cell, .tableCell
        +offset(0, 0, 8px, 0)

    .remove
      +offset(8px, 0, 0, 0)
      +size(32px, 32px)
      +cornerRadius(6px)
      +display-flex
      +align-items(center)
      +justify-content(center)
      +flex-shrink(0)
      +interactionTransition
      +cursorAction
      background-color: $colorActionTranslucent
      &:active
        +interactionTapOpacity
      .icon
        +iconTableViewSectionRowAccessoryDelete

    .cell
      +size(100%, 40px)
      +contentEdgeInsets(12px, 0, 12px, 0)
      +offset(0, 0, 0, 8px)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +cornerRadius(6px)
      +clipToBounds
      +noUserSelect
      +cursorAction
      position: relative
      box-sizing: border-box
      background-color: $colorBackgroundPrimary

      &:last-of-type
        +offset(0, 0, 0, 0)

      .icon
        +size(40px, 40px)
        +cornerRadius(6px)
        +flex-shrink(0)
        +offset(0, 0, 8px, 0)
        background-color: $colorBackgroundPlaceholder

      .cellBody
        +size(100%, auto)

      .title, .cellTitle
        +flex-shrink(0)
        +fontSubheadEmphasized
        color: $colorTitle

        a
          cursor: pointer
          color: $colorTitle

      .subtitle, .cellSubtitle
        +fontFootnote
        color: $colorSubtitle

      .detail
        +size(100%, auto)
        +fontSubhead
        color: $colorTitlePlaceholder
        text-align: right

      .input
        +size(100%, auto)
        +fontSubhead
        color: $colorTitlePlaceholder
        text-align: right

      &.plain
        .input
          text-align: left

      .accessory
        +flex-shrink(0)
        +offset(8px, 0, 0, 0)
        &.popUpIndicator
          +iconTableViewCellAccessoryPopUp
        &.disclosureIndicator
          +iconTableViewCellAccessoryDisclosureIndicator

      &.isFocused
        +shadowInputFocused

      &.hasInput
        .input
          color: $colorTitle

      &.disabled
        +cursorDefault
        +shadowNone
        +interactionNone
        opacity: 0.4
        &:active, &:hover
          +cursorDefault
          background-color: $colorBackgroundPrimary
          opacity: 0.4
        .input
          +cursorDefault

    &.groupedSection
      +offset(0, 16px, 0, 0)

      .cell
        +interactionTableViewCellGrouped
        +offset(0, 0, 0, 2px)
        +cornerRadius(2px)
        +shadowNone
        background-color: $colorActionTranslucentCell
        
        &:last-of-type
          +cornerRadius(2px 2px 6px 6px)
        
        &:nth-of-type(2)
          +cornerRadius(6px 6px 2px 2px)

      .sectionTitle
        +size(100%, auto)
        +contentEdgeInsets(2px, 0, 2px, 6px)
        +fontFootnoteEmphasized
        +noUserSelect
        +cursorDefault
        color: $colorSubtitle

.tableCell
  +size(100%, 40px)
  +contentEdgeInsets(12px, 0, 12px, 0)
  +offset(0, 0, 0, 8px)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +cornerRadius(6px)
  +clipToBoundsFalse
  +shadowCell
  +noUserSelect
  +interactionTableViewCell
  +cursorAction
  position: relative
  box-sizing: border-box
  background-color: $colorBackgroundPrimary

  &:last-of-type
    +offset(0, 0, 0, 0)

  .icon
    +size(40px, 40px)
    +cornerRadius(6px)
    +flex-shrink(0)
    +offset(0, 0, 8px, 0)
    background-color: $colorBackgroundPlaceholder

  .cellBody
    +size(100%, auto)

  .title, .cellTitle
    +flex-shrink(0)
    +fontSubheadEmphasized
    color: $colorTitle

  .subtitle, .cellSubtitle
    +fontFootnote
    color: $colorSubtitle

  .detail
    +size(100%, auto)
    +fontSubhead
    color: $colorTitlePlaceholder
    text-align: right

  .input
    +size(100%, auto)
    +fontSubhead
    color: $colorTitlePlaceholder
    text-align: right

  .accessory
    +flex-shrink(0)
    +offset(8px, 0, 0, 0)
    &.popUpIndicator
      +iconTableViewCellAccessoryPopUp
    &.disclosureIndicator
      +iconTableViewCellAccessoryDisclosureIndicator

  &.isFocused
    +shadowInputFocused

  &.hasInput
    .input
      color: $colorTitle

  &.selected
    background-color: $colorTint
    .title, .cellTitle
      color: $colorTitleSecondary

    .subtitle, .cellSubtitle
      color: $colorTitleSecondary
      opacity: 0.8

    &:hover
      background-color: $colorTint

    &:active
      background-color: $colorTint
      transform: scale(1)
      opacity: 0.8

  &.disabled
    +cursorDefault
    +shadowNone
    +interactionNone
    opacity: 0.4
    &:active, &:hover
      +cursorDefault
      background-color: $colorBackgroundPrimary
      opacity: 0.4
    .input
      +cursorDefault

.switchCell
  +interactionTableViewCellSwitch
  +justify-content(space-between)

.accessorySwitch
  +size(30px, 18px)
  +cornerRadius(9px)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +contentEdgeInsets(2px, 2px, 2px, 2px)
  +interactionTransition
  background-color: #D0D0D7

  .switchThumb
    +size(14px, 14px)
    +cornerRadius(7px)
    +interactionTransition
    background-color: $colorBackgroundPrimary

  &.on
    background-color: $colorTint
    .switchThumb
      +offset(12px, 0, 0, 0)

.cellFootnote
  +offset(0, 4px, 0, 0)
  +contentEdgeInsets(4px, 0, 4px, 0)
  +noUserSelect
  +cursorDefault
  +fontFootnote
  color: $colorSubtitle

.tableView
  .tableSection
    .tableRow
      +offset(0, 0, 0, 8px)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      .inputCell
        +offset(0, 0, 0, 0)
