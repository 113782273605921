.bannerView
  +size(100%, auto)
  +contentEdgeInsets(12px, 0, 12px, 0)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +justify-content(space-between)
  +shadowLarge
  +cornerRadius(6px)
  +cursorAction
  min-height: 42px
  background-color: $colorTint
  .bannerTitle
    +fontFootnoteEmphasized
    color: $colorTitleSecondary
  .button
    +offset(0, 0, -4px, 0)
