.ehrModal
  width: 100%
  +display-flex
  +flex-direction(column)

  .loadingRecords
    width: 100%
    height: 100%
    position: absolute
    background-color:rgba(0, 0, 0, 0.5)
    z-index: 9999
    +display-flex
    +align-items(center)
    +justify-content(center)

    .message
      color: white
      font-size: 14px

  .content
    flex-grow: 1
    +display-flex
    +flex-direction(row)
    height: 100%

    .secondaryNavBar
      width: 30%
      min-width: 30%
      +align-items(flex-start)
      padding-left: 0px
      padding-right: 0px
      margin-left: 15px
      margin-right: 15px
      height: 65vh
      overflow-y: auto

      .segmentedVerticalBar
        +size(100%, auto)
        +display-flex
        +flex-direction(column)
        +align-items(center)
        +cornerRadius(8px)
        position: relative
        background-color: $colorActionTranslucent
        margin-bottom: 70px

        .separator
          +size(2px, 16px)
          +shadowNone
          position: static
          background-color: $colorActionTranslucent

        .segment
          +size(100%, 100%)
          padding-top: 20px
          padding-bottom: 20px
          +cornerRadius(8px)
          +display-flex
          +align-items(center)
          +justify-content(center)
          +cursorAction
          +interactionTransition
          z-index: 4

          .title
            +cursorAction
            +fontFootnoteEmphasized
            color: $colorAction

          &:hover
            background-color: $colorActionTranslucent
          &:active
            +interactionTapScale

          &.selected
            &:hover
              +interactionNone
            &:active
              +interactionTapScale
            .title
              color: $colorBackgroundPrimary

        .selection
          +size(100%, 50%)
          +cornerRadius(8px)
          +cursorAction
          +interactionTransition
          +positionAbsoluteWithOffset(0, 0, auto, auto)
          +shadowSmall
          z-index: 3
          background-color: $colorAction

          &:active
            +interactionTapScale

        &.index0
          .selection
            left: 0
          .separator1
            opacity: 0
        &.index1
          .selection
            left: 80px
          .separator1, .separator2
            opacity: 0
        &.index2
          .selection
            left: 162px
          .separator2
            opacity: 0
        &.index3
          .selection
            left: 244px
          .separator2
            opacity: 0

    .tableView
      flex-grow: 1
      padding-right: 15px
      +display-flex
      +flex-direction(column)
      gap: 10px
      overflow-y: auto
      height: 65vh
      padding-bottom: 70px

      .tableSection
        .tableCell
          height: 100%
          min-height: 40px
          .inputCell
            height: fit-content
          
          &.textarea
            +display-flex
            align-items: start
            flex-direction: column
            padding-top: 10px
            padding-bottom: 10px
            textarea
              background-color: transparent
              border: 0
              color: white
              width: 100%
              height: 120px
              &:focus
                outline: none


      .label
        color: white

      .sectionMatches
        .backButton
          +offset(0, 0, 0, 12px)
          +justify-content(center)
          max-width: 164px
          padding-top: 10px
          padding-bottom: 10px
          overflow-y: scroll
  
  .actionBar
    bottom: 0px
    padding: 12px 0
    position: fixed
    z-index: 10
  
  .headerInfo
    padding: 30px
    color: white
    color: white
    +display-flex
    flex-direction: column
    gap: 20px

    .row
      &.no-justify
        justify-content: flex-end
        gap: 20px

      +display-flex
      justify-content: space-between
      flex-wrap: wrap
      width: 100%

      .field
        +display-flex
        gap: 10px
        align-items: center

        .value
          font-weight: bold
          +display-flex
          align-items: center

        .menuButton
          background-color: $colorActionTranslucent
          box-shadow: var(--shadowSmallBordered)
          +size(100%, auto)
          +contentEdgeInsets(15px, 0, 15px, 0)
          +display-flex
          +flex-direction(row)
          +align-items(center)
          +cornerRadius(8px)
          +cursorAction
          +interactionTransition
          position: relative
          box-sizing: border-box
          min-height: 35px
          z-index: 99

          .cellBody
            +size(100%, auto)
            +offset(0px, 2px, 8px, 0)

          .accessory
            +flex-shrink(0)
            &.popUpIndicator
              +iconTableViewCellAccessoryPopUp

.incidentNumber
  .inputCell
    .input
      text-align: left !important
      width: 120px

.intake
  width: 140px
  .selectCell
    .selectCell__control
      .selectCell__value-container
        .selectCell__single-value
          text-align: left !important
        .selectCell__placeholder
          text-align: left !important

.newAddendumButton
  width: 140px
  justify-content: center

.addendumTextInputContainer
  height: 100px
  text-align: center
  .addendumTextInput
    height: 100%
    width: 80%

.createAddendumButtonsContainer
  display: flex
  justify-content: center
  gap: 5em
  align-items: center
  margin-top: 10px

.newAddendumButtonSection, .newAddendumButtonContainer
  margin-bottom: 10px 

.addendumAccordion
  flex-direction: column

.addendumAccordionSummary
  justify-content: space-between
  width: 100%

.addendumsDisplay
  overflow-y: scroll

  .MuiCollapse-root.MuiCollapse-entered
    width: 100%

.adddendumDetailText
  padding-bottom: 1.5em