.onboardingView
  +size(100%, auto)

  .navBar
    +contentEdgeInsets(24px, 0, 24px, 0)
    .separator
      +positionAbsoluteWithOffset(24px, 0, 24px, 0)

  .contentOptions
    padding-left: 24px
    display: flex
    flex-direction: row
    justify-content: space-between
    height: calc(100% - 65px)
    overflow: auto
    .tableView
      height: calc(100% - 11px)
      overflow-y: auto
      &::-webkit-scrollbar
        display: none


  .contactDetails
    +size(100%, auto)
    +contentEdgeInsets(16px, 14px, 16px, 16px)
    +cornerRadius(10px)
    +shadowLarge
    +flex-direction(row)
    +noUserSelect
    +cursorDefault
    flex: 1
    background-color: $colorBackgroundPrimary

    .header
      +offset(0, 0, 0, 16px)
      .title
        +fontTitle2Emphasized
        color: $colorTitle
      .subtitle
        +offset(0, 4px, 0, 0)
        +fontFootnote
        color: $colorSubtitle

    .tableView
      height: calc(100% - 148px)
      overflow-y: auto
      .tableSection
        .cell
          flex-shrink: 0
          +shadowNone
          +interactionNone
          +justify-content(space-between)
          background-color: $colorActionTranslucentCell
          &:hover, &:active
            +shadowNone
          .detail
            +userSelect
            +cursorTextInput
            color: $colorTitle
          .button
            .title
              color: $colorTint

  .wizardPanels
    flex: 1
    padding-left: 20px
    +offset(0, 0, 20px, 0)
    .cell
      +size(100%, auto)
      +contentEdgeInsets(0, 0, 0, 0)
      +offset(0, 0, 0, 8px)
      +cornerRadius(10px)
      +clipToBoundsFalse
      // +shadowCell
      +userSelect
      // +interactionTableViewCell
      +cursorAction
      display: block
      border: none
      &:before
        display: none
      &.MuiPaper-root
        .MuiButtonBase-root
          .MuiAccordionSummary-content
            .MuiTypography-root
              +offset(0, 0, 0, 0)
              &.MuiTypography-body1
                +fontSubhead
                color: $colorSubtitle
              &.makeStyles-panelHeading-11
                +fontBodyEmphasized
                color: $colorTitle
      .MuiLinearProgress-bar
        &.MuiLinearProgress-barColorSecondary
          &.MuiLinearProgress-bar1Determinate
            display: none
      .MuiLinearProgress-root
        &.MuiLinearProgress-colorSecondary
          &.MuiLinearProgress-determinate
            display: none
