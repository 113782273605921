.navBar
  +size(100%, 56px)
  +positionStickyWithOffset(0, 0, auto, auto)
  +contentEdgeInsets(16px, 0, 16px, 0)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +flex-shrink(0)
  box-sizing: border-box
  background-color: $colorBackgroundSecondary
  z-index: 1

  .separator
    +positionAbsoluteWithOffset(16px, 0, 16px, 0)
    +shadowSeparator
    z-index: -1

  .navTitle
    +size(auto, auto)
    +display-flex
    +flex-direction(row)
    +align-items(center)

    .viewName
      +offset(0, 0, 12px, 0)
      +cursorDefault
      +noUserSelect
      +fontTitle3Emphasized
      +flex-shrink(0)
      color: $colorTitle

    .counterBadge
      +size(auto, 20px)
      +contentEdgeInsets(4px, 0, 4px, 0)
      +display-flex
      +align-items(center)
      +cornerRadius(5px)
      +noUserSelect
      +cursorDefault
      background-color: $colorAction

      .counterValue
        +fontCaption1Emphasized
        color: $colorBackgroundSecondary

  .title
    +cursorDefault
    +noUserSelect
    +fontTitle3Emphasized
    +flex-shrink(0)
    color: $colorTitle

  .actions, .navActions
    +size(100%, 100%)
    +display-flex
    +flex-direction(row)
    +justify-content(flex-end)
    +align-items(center)
    +noUserSelect

    .navAction
      +offset(10px, 0, 0, 0)

    .add
      +size(auto, 32px)
      +offset(10px, 0, 0, 0)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +cornerRadius(5px)
      +clipToBounds
      +cursorAction
      +shadowSmall
      +interactionTransition
      background-color: $colorTint

      .icon
        +offset(10px, 0, 5px, 0)
        +iconNavBarAdd
      .title
        +offset(0, 0, 12px, 0)
        +cursorAction
        +fontFootnoteEmphasized
        color: $colorTitleSecondary

        &.clear
          +offset(12px, 0, 12px, 0)

      &:hover
        +interactionHoverScale

      &:active
        +interactionTapScale

    // .action
    //   +size(auto, 32px)
    //   +offset(10px, 0, 0, 0)
    //   +display-flex
    //   +flex-direction(row)
    //   +align-items(center)
    //   +cornerRadius(5px)
    //   +clipToBounds
    //   +cursorAction
    //   +shadowSmall
    //   +interactionTransition
    //   background-color: $colorBackgroundPrimary

    //   .title
    //     +cursorAction
    //     +fontFootnoteEmphasized
    //     color: $colorTitle

    //   &:hover
    //     +interactionHoverScale

    //   &:active
    //     +interactionTapScale

    //   &.add
    //     background-color: $colorTint
    //     .icon
    //       +offset(10px, 0, 5px, 0)
    //       +iconNavBarAdd
    //     .title
    //       +offset(0, 0, 12px, 0)
    //       color: $colorTitleSecondary

.secondaryNavBar
  +size(100%, 56px)
  +contentEdgeInsets(24px, 0, 24px, 0)
  +display-flex
  +flex-direction(row)
  +justify-content(space-between)
  +align-items(center)
  +noUserSelect
  
  .navTitle
    +size(auto, auto)
    +display-flex
    +flex-direction(row)
    +align-items(center)

    .viewName
      +offset(0, 0, 12px, 0)
      +noUserSelect
      +cursorDefault
      +fontBodyEmphasized
      color: $colorTitle

    .counterBadge
      +size(auto, 20px)
      +contentEdgeInsets(4px, 0, 4px, 0)
      +display-flex
      +align-items(center)
      +cornerRadius(5px)
      +noUserSelect
      +cursorDefault
      background-color: $colorAction

      .counterValue
        +fontCaption1Emphasized
        color: $colorBackgroundSecondary

  .navActions
    +size(auto, auto)
    +display-flex
    +flex-direction(row)
    +align-items(center)

    .sortButton
      +offset(0, 0, 8px, 0)
