body.fullScreen
  .tabBar
    display: none

.tabBar
  +size(200px, 100vh)
  +positionStickyWithOffset(0, 0, auto, auto)
  +shadowTabBar
  +display-flex
  +flex-direction(column)
  +flex-shrink(0)
  +cornerRadius(10px)
  +clipToBounds
  z-index: 10
  background-color: $colorBackgroundPrimary
  
  .header
    +size(100%, 62px)
    +display-flex
    +align-items(center)
    +justify-content(center)
    // +cornerRadius(10px)
    // +clipToBounds
    // +shadowSeparator
    // +shadowSmall
    // background-color: #13243D
    position: relative
    .logo
      +iconTabBarLogo
    .separator
      +positionAbsoluteWithOffset(0, 0, 0, 0)
      +shadowSeparator
      opacity: 0.72
      z-index: 1

  .navigationItems
    +size(100%, 100%)
    +contentEdgeInsets(0, 0, 0, 64px)
    +hideScrollBars
    overflow-y: scroll

  .navigationSection
    +size(100%, auto)
    +contentEdgeInsets(12px, 12px, 12px, 8px)
    +noUserSelect
    +cursorDefault
    position: relative
    box-sizing: border-box

    .sectionTitle
      +size(100%, auto)
      +contentEdgeInsets(6px, 0, 6px, 0)
      // +offset(0, 4px, 0, 6px)
      +offset(0, 0, 0, 6px)
      +noUserSelect
      +fontCaption2Emphasized
      color: $colorHeading
      opacity: 0.72
      text-transform: uppercase

    .separator
      +positionAbsoluteWithOffset(16px, 0, 16px, 0)
      +shadowSeparator
      opacity: 0.72
      z-index: -1
    
    .navigationItem
      +size(100%, 40px)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +contentEdgeInsets(8px, 8px, 8px, 8px)
      +offset(0, 0, 0, 4px)
      +clipToBounds
      +cornerRadius(7px)
      +cursorAction
      +interactionTransition
      box-sizing: border-box
      background-color: $colorClear
      
      .icon
        +size(24px, 24px)

      .title
        +offset(6px, 0, 0, 0)
        +fontFootnoteEmphasized
        color: $colorAction
        opacity: 0.8

      &:hover
        background-color: $colorActionTranslucent

      &:active
        +interactionTapScale

      &.selected
        background-color: $colorTint
        .title
          color: $colorTitleSecondary
          opacity: 1

      &.disabled
        +interactionNone
        opacity: 0.4
        &:hover
          opacity: 0.4
          background-color: $colorClear

      &.overview
        .icon
          +iconTabBarOverview
        &.selected
          .icon
            +iconTabBarOverviewSelected

      &.conversations
        .icon
          +iconTabBarConversations
        &.selected
          .icon
            +iconTabBarConversationsSelected

      &.workflow
        .icon
          +iconTabBarWorkflow
        &.selected
          .icon
            +iconTabBarWorkflowSelected

      &.clients
        .icon
          +iconTabBarClients
        &.selected
          .icon
            +iconTabBarClientsSelected

      &.services
        .icon
          +iconTabBarServices
        &.selected
          .icon
            +iconTabBarServicesSelected

      &.settings
        .icon
          +iconTabBarSettings
        &.selected
          .icon
            +iconTabBarSettingsSelected

      &.file-import
        .icon
          +iconTabBarFileImport
        &.selected
          .icon
            +iconTabBarFileImportSelected
      
      &.onboarding
        background-color: $colorActionTranslucentCell
        .icon
          +iconTabBarOnboarding
        &.selected
          background-color: $colorTint
          .icon
            +iconTabBarOnboardingSelected

      &.rules
        .icon
          +iconTabBarRules
        &.selected
          .icon
            +iconTabBarRulesSelected

      &.survey, &.Candidates
        .icon
          +iconTabBarSurveys
        &.selected
          .icon
            +iconTabBarSurveysSelected

      &.activity
        .icon
          +iconTabBarOverview
        &.selected
          .icon
            +iconTabBarOverviewSelected

      &.measure
        .icon
          +iconTabBarMeasure
        &.selected
          .icon
            +iconTabBarMeasureSelected

      &.map
        .icon
          +iconTabBarMap
        &.selected
          .icon
            +iconTabBarMapSelected

      &.groups
        .icon
          +iconTabBarGroups
        &.selected
          .icon
            +iconTabBarGroupsSelected

      &.articles
        .icon
          +iconTabBarArticles
        &.selected
          .icon
            +iconTabBarArticlesSelected

      &.providers
        .icon
          +iconTabBarProviders
        &.selected
          .icon
            +iconTabBarProvidersSelected

      &.sources
        .icon
          +iconTabBarSources
        &.selected
          .icon
            +iconTabBarSourcesSelected

      &.feedback
        .icon
          +iconTabBarFeedback
        &.selected
          .icon
            +iconTabBarFeedbackSelected

      &.tasks
        .icon
          +iconTabBarTasks
        &.selected
          .icon
            +iconTabBarTasksSelected

      &.satisfaction
        .icon
          +iconTabBarGroups
        &.selected
          .icon
            +iconTabBarGroupsSelected

  .bottomActions
    +size(100%, auto)
    +display-flex
    +flex-direction(column)
    +contentEdgeInsets(12px, 12px, 12px, 12px)
    +positionAbsoluteWithOffset(auto, auto, 0, 0)
    +noUserSelect
    +cursorDefault
    background-image: linear-gradient(180deg, $colorClear 0%, $colorBackgroundPrimary 20%, $colorBackgroundPrimary 100%)
    box-sizing: border-box

    .customerDetails
      +size(100%, 56px)
      +contentEdgeInsets(10px, 10px, 10px, 10px)
      +display-flex
      +flex-flow(row)
      +align-items(center)
      +flex-shrink(1)
      +clipToBounds
      +cornerRadius(7px)
      +cursorAction
      +noUserSelect
      +interactionTransition
      box-sizing: border-box
      background-color: $colorActionTranslucentCell

      .profilePicture
        +size(36px, 36px)
        +cornerRadius(50%)
        +clipToBounds
        background-color: $colorBackgroundPlaceholder

      .details
        +size(100%, auto)
        +offset(10px, 0, 0, 0)
        +contentEdgeInsets(0, 0, 8px, 0)
        .fullName
          +size(109px, auto)
          +fontFootnoteEmphasized
          color: $colorTitle
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        .clinicName
          +fontCaption1
          color: $colorSubtitle

      &:hover
        background-color: $colorActionTranslucent
      
      &:active
        +interactionTapScale

  &.compact
    +size(64px, 100vh)
    .header
      +size(100%, 62px)
      +shadowNone
      .logo
        +iconTabBarLogoCompact

    .navigationItem
      +justify-content(center)
      .title
        display: none
        +offset(0, 0, 0, 0)

    .bottomActions
      +size(100%, auto)
      +contentEdgeInsets(0, 0, 0, 0)

      .customerDetails
        +justify-content(center)
        background-color: $colorClear
        .details
          display: none

.prefersDarkTheme
  .tabBar
    .header
      .logo
        +iconTabBarLogoOnDark