.detailedEncounterCell
  +size(100%, auto)
  +contentEdgeInsets(12px, 9px, 12px, 9px)
  // +size(100%, 56px)
  +display-flex
  +flex-direction(row)

  .encounterCellBody
    +flex-shrink(0)
    +display-flex
    +align-items(center)
    +flex-direction(row)
    .bodyColumn
      +offset(0, 0, 20px, 0)
      +display-flex
      +flex-direction(column)
      +align-items(flex-start)
      .bodyTitle
        +fontCaption1Emphasized
        color: $colorTitle
      .bodySubtitle
        +offset(0, 2px, 0, 0)
        +fontCaption1
        color: $colorSubtitle
      &.columnTestStatus
        +offset(0, 0, 0, 0)
      &.columnEncounterType
        .bodyTitle
          +fontSubhead2Emphasized
          color: $colorTitle
        .bodySubtitle
          +offset(0, 2px, 0, 0)
          +fontCaption1
          color: $colorSubtitle
  .encounterCellDetail
    +size(100%, 100%)
    +align-items(center)
    +justify-content(flex-end)
    +display-flex
    +flex-direction(row)
    .encounterActions
      +display-flex
      +flex-direction(row)
      .actionIgnore
        +offset(0, 0, 8px, 0)
    .encounterTrigger, .encounterTargetStatus
      +offset(0, 0, 16px, 0)
      +display-flex
      +align-items(center)
      +flex-direction(row)
      .triggerItem, .targetStatus
        +size(auto, auto)
        +contentEdgeInsets(8px, 3px, 8px, 3px)
        +offset(0, 0, 2px, 0)
        +display-flex
        +align-items(center)
        +cornerRadius(4px)
        background-color: $colorActionTranslucent
        .title
          +fontCaption1Emphasized
          color: $colorAction
    
    .encounterTargetStatus
      +offset(-12px, 0, 0, 0)
      .targetStatus
        +offset(12px, 0, 0, 0)
        &.statusCompletion
          background-color: $colorTintTranslucent
          .title
            color: $colorTint
          &.completionAnswered
            background-color: $colorTint
            .title
              color: $colorTitleSecondary

    .encounterTrigger
      .triggerItem
        &.triggerTarget
          background-color: $colorAction
          .title
            color: $colorBackgroundPrimary
        &.arrow
          +contentEdgeInsets(4px, 3px, 4px, 3px)
          background-color: $colorClear
          
          .title
            color: $colorTitle

    .encounterTargetStatus
      +display-flex
      +flex-direction(row)

  @media only screen and (max-width: 1460px)
    +flex-direction(column)
    +align-items(flex-start)
    +justify-content(flex-start)
    +contentEdgeInsets(0, 0, 0, 0)
    +clipToBounds

    .encounterCellBody
      +contentEdgeInsets(12px, 9px, 12px, 9px)

    .encounterCellDetail
      +contentEdgeInsets(8px, 8px, 8px, 8px)
      +justify-content(flex-start)
      background-color: $colorBackgroundSecondary
