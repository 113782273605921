.conversationEncounterAattributeCell
  +contentEdgeInsets(8px, 0, 8px, 0)
  +offset(0, 0, 0, 4px)
  +display-flex
  +flex-direction(row)
  +justify-content(space-between)

  .attributeTitle
    +userSelect
    +cursorText
    +fontCaption1Emphasized
    color: $colorSubtitle

  .attributeDetail
    +userSelect
    +cursorText
    +fontCaption1
    color: $colorTitle
