.groupsView
  background-color: initial

  .tableCell, .inputCell
    background-color: white
    color: black
    .title
      color: black

  .navBar
    background-color: initial
    .title
      color: black

  .editGroupsView
    overflow-y: scroll
    .cell
      background-color: white
      box-shadow: none
      height: 40px
      .title, .input
        color: black !important
    .actionBar
      margin-top: 40px
      background-color: white
    .tableView 
      .sectionBot
        flex-direction: column 
      
      .tableSectionGroups 
        padding: 0 18px 18px
        box-sizing: border-box
        +display-flex
        flex-wrap: wrap
        margin: 18px 0 0
        .sectionTitle
          font-family: Inter,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif
          font-weight: 600
          color: var(--colorSubtitle)
          width: 100%
          height: auto
          padding: 0 2px 6px
          box-sizing: border-box
          font-size: 13px
          letter-spacing: -.08px
          line-height: 18px
        .tableCell
          justify-content: space-around
          height: 60px
          width: calc(50% - 12px)
          margin: 6px
          .cell
            align-items: center
            display: flex
            flex-direction: row
          .selectCell
            .selectCell__single-value
              color: black
            .selectCell__menu
              background-color: white
              position: absolute
              z-index: 99999
              .selectCell__menu-list
                .selectCell__option
                  color: black
                  &.selectCell__option--is-selected
                    color: white
          &.switch
            justify-content: space-between
        .defaultGreeting
          border-radius: 6px
          margin-left: 6px
          +display-flex
          align-items: start
          flex-direction: column
          padding: 10px
          box-shadow: var(--shadowCell)
          width: 510px
          textarea
            resize: none
            background-color: transparent
            border: 0
            color: black
            width: 100%
            height: 120px
            &:focus
              outline: none
        .cell.hasInput .input
          color: black

        .teamMembersList
          padding: 10px
        .autoStartVideochatSwitch
          margin: 16px

  .shortCodesSection
    margin-top: 10px
    display: flex
    justify-content: space-around
    flex-direction: column
    flex: 1
    
    .shortCodeInputs
      +size(100%, auto)
      +offset(0, 0, 0, 8px)
      +contentEdgeInsets(8px, 8px, 8px, 8px)
      +display-flex
      +flex-direction(row)
      +cornerRadius(12px)
      background-color: $colorBackgroundDisabled


    .addShortCodeButton
      +justify-content(center)
      max-width: 180px
    
    .inputCell
      flex: 1
      background-color: white
      color: black
      height: 40px
      box-shadow: none

    .shortCodeInput
      width: 45%
      .title, .input
        color: black
      box-shadow: none
      height: 40px
      .inputCell.cell
        box-shadow: none
        height: 40px

    .shortCodeDownload
      display: flex
      justify-content: center
      align-items: center
      width: 8%
      .downloadButton
        cursor: pointer
      .disabled
        opacity: 0.3
    .copyQRCodeURLContainer
      display: flex
      justify-content: center
      align-items: center
      width: 8%
      .copyQRCodeURLButton
        cursor: pointer
      .disabled
        opacity: 0.3
      
  .groupCell
    background-color: initial
    .cellBody
      .groupName
        color: initial
      .detail
        color: initial
    .cellAction
      background-color: var(--colorAction)
      .title
        color: white
  +contentEdgeInsets(0, 0, 0, 0)
  +clipToBounds
  +cursorDefault
  &:active
    transform: scale(1)
    opacity: 1
  &:hover
    transform: scale(1)
    opacity: 1

  .cellBody
    +offset(12px, 0, 0, 0)
    +size(auto, auto)
    +flex-shrink(0)
    .detail
      +fontFootnote
      color: $colorSubtitle
      
  .searchInput
    &.tableSearch
      .input
        color: $colorTitlePlaceholder
      &.isFocused
        &.hasInput
          .input
            color: #000000

.workflowStepContainer
  margin: 0 !important
  +display-flex
  +flex-direction(column)
  .workflowStep
    +size(100%, auto)
    +offset(0, 0, 0, 8px)
    +contentEdgeInsets(8px, 8px, 8px, 8px)
    +display-flex
    +flex-direction(row)
    +cornerRadius(12px)
    background-color: $colorBackgroundDisabled

    .stepContainer
      +size(100%, auto)
      .tableRow
        .switchCell
          cursor: pointer
      .cell
        input
          color: black !important

  .addState
    +justify-content(center)
    max-width: 160px

.groupsList
  overflow-y: scroll