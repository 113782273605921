
.emailDigestSubscriptionsView
    display: flex
    flex-direction: column
    gap: 1rem

.emailDigestSubscriptionsHeader
    font-weight: bold
    
.emailDigestSubscriptionsDayOfWeek
    margin-top: 10px    
    
.emailDigestSubscriptionsDayOfWeekButtons
    display: flex
    gap: 0

    button
        +digestSettingBox
        cursor: pointer

    .selected
        border: 1px solid black

.emailDigestSubscriptionsTimeInput
    +digestSettingBox(100px, 50px)

.emailDigestSubscriptionsTimeInputsContainer
    display: flex
    gap: 0.5rem

.emailDigestSubscriptionsTimeInput
    text-align: center

.emailDigestSubscriptionsSendsHeaders
    display: flex
    justify-content: left
    align-items: center
    gap: 2rem
    .emailDigestSubscriptionsSendsHeader
        width: 20%

.emailDigestLog
    display: flex
    justify-content: left
    align-items: center
    border-top: 1px solid black
    padding: 1rem 0 
    gap: 2rem
    .emailDigestLogItem
        width: 20%

.emailDigestSubscriptionFeedbackOptions
    display: flex
    gap: 8px
    flex-direction: row
    .emailDigestSubscriptionFeedbackOption
        width: fit-content
