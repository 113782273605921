.chatMessages
  +size(100%, 100%)
  +display-flex
  +flex-direction(column)
  position: relative
  
  .messagesList
    +contentEdgeInsets(16px, 16px, 16px, 0)
    +size(100%, 444px)
    +display-flex
    +flex-direction(column)
    +hideScrollBars
    box-sizing: border-box
    position: relative
    overflow-y: scroll