.chatSideBar
  +size(256px, 555px)
  +display-flex
  +flex-direction(column)
  +flex-shrink(0)
  +cornerRadius(12px)
  +shadowSmall
  position: relative
  box-sizing: border-box
  background-color: $colorBackgroundPrimary
  &:after
    +size(100%, 44px)
    content: ''

  .segmentedBar
    +positionAbsoluteWithOffset(8px, auto, 8px, 8px)

  .sideBarContent
    +size(100%, 100%)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +hideScrollBars
    box-sizing: border-box
    overflow-y: scroll

  .sideBarSection
    +size(100%, auto)

    .sectionTitle
      +offset(0, 12px, 0, 8px)
      +contentEdgeInsets(8px, 0, 8px, 0)
      +fontFootnoteEmphasized
      box-sizing: border-box
      color: $colorHeading

    .attributeCell
      +size(100%, auto)
      +contentEdgeInsets(8px, 0, 8px, 0)
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +cornerRadius(8px)
      +cursorAction
      +interactionTransition
      position: relative
      box-sizing: border-box
      min-height: 48px
      background-color: $colorClear

      &.expandableCell
        +flex-direction(column)
        +contentEdgeInsets(0, 0, 0, 0)
        .baseCell
          +size(100%, auto)
          +contentEdgeInsets(8px, 0, 8px, 0)
          +display-flex
          +flex-direction(row)
          +align-items(center)
          +cornerRadius(8px)
          +cursorAction
          +interactionTransition
          position: relative
          box-sizing: border-box
          min-height: 48px
          background-color: $colorClear
        .detailsList
          +size(100%, auto)
          +display-flex
          +flex-direction(column)
          display: none
          .detailCell
            +display-flex
            +flex-direction(column)
            +contentEdgeInsets(8px, 8px, 8px, 8px)
            position: relative
            .title
              +fontFootnoteEmphasized
              color: $colorTitle
            .detail
              +fontCaption1
              color: $colorSubtitle
            .separator
              +positionAbsoluteWithOffset(0, 0, 0, 0)
              +shadowSeparator
            &:last-of-type
              .separator
                display: none
            &.participants
              .detail
                .participant-connected
                  +fontCaption1Emphasized
                  color: $colorSubtitle
                  &:before
                    +size(8px, 8px)
                    +offset(0, 0, 2px, 0)
                    +cornerRadius(50%)
                    content: ""
                    display: inline-block
                    background-color: $colorTint

            &.actions
              .button
                width: 100%
                +display-flex
                +justify-content(center)

        &.expanded
          +offset(0, 8px, 0, 8px)
          +interactionNone
          background-color: $colorBackgroundSecondary
          +shadowSmall
          .baseCell
            background-color: $colorBackgroundPrimary
            +shadowSmall
            .disclosureIndicator
              +transform(rotate(90deg))
          .detailsList
            +display-flex

      .icon
        +size(28px, 28px)
        +flex-shrink(0)

      .cellBody
        +size(100%, auto)
        +offset(12px, 2px, 8px, 0)
        .title
          +fontCaption1
          color: $colorSubtitle
        .detail
          +fontFootnoteEmphasized
          color: $colorTitle
        .answer
          +fontCaption1
          color: $colorTitle
          font-weight: bold

      &.empty
        .cellBody
          .detail
            color: $colorSubtitle

      .price
        +fontFootnoteEmphasized
        color: $colorSubtitle

      .accessory
        +flex-shrink(0)
        &.popUpIndicator
          +iconTableViewCellAccessoryPopUp
        &.disclosureIndicator
          +iconTableViewCellAccessoryDisclosureIndicator
        &.insuranceIndicator
          &.verified
            +iconTableViewCellAccessoryCheckmarkPositive
          &.invalid
            +iconTableViewCellAccessoryFlagDestructive

      &.pullDownButton
        &.selected
          background-color: $colorActionTranslucent

      &:hover
        background-color: $colorActionTranslucentCell

      &:active
        +interactionTapScale

    .sectionSeparator
      +size(100%, 8px)
      +contentEdgeInsets(8px, 0, 8px, 0)
      box-sizing: border-box
      .separator
        +size(100%, 100%)
        +shadowSeparator

    .recentLocation
      +size(auto, 300px)

.chatSideBar
  .sideBarSection
    &.sectionTasks

      .conversationTasksList
        +offset(0, 0, 0, 8px)

      .conversationTaskCell
        +size(100%, 44px)
        +contentEdgeInsets(8px, 0, 8px, 0)
        +cornerRadius(10px)
        +interactionTransition
        +cursorAction
        
        &:first-of-type
          +offset(0, -8px, 0, 0)
        &:last-of-type
          +offset(0, 0, 0, 0)

        &:hover
          background-color: $colorActionTranslucentCell
        &:active
          +interactionTapScale

        .taskCellContent
          +size(100%, 100%)
          +display-flex
          +flex-direction(row)
          +justify-content(space-between)
          +align-items(center)

        .cellBody
          +display-flex
          +flex-direction(column)

        .cellDetail
          +display-flex
          +flex-direction(row)

        .taskType
          +fontFootnoteEmphasized
          color: $colorTitle

        .taskDueDate
          +offset(0, 1px, 0, 0)
          +fontCaption2
          color: $colorSubtitle

        .taskStatus
          +size(auto, 24px)
          +contentEdgeInsets(6px, 0, 6px, 0)
          +display-flex
          +flex-direction(row)
          +align-items(center)
          +cornerRadius(6px)
          background-color: $colorActionTranslucent

          .statusTitle
            +fontCaption2Emphasized
            color: $colorAction

      .sectionActions
        +size(100%, auto)
        +contentEdgeInsets(8px, 0, 8px, 0)
        +display-flex
        .button
          +offset(0, 0, 0, 8px)
          +flex-shrink(0)
          +justify-content(center)
          width: 100%

    &.sectionExport
      .exportActions
        +size(100%, auto)
        +contentEdgeInsets(8px, 0, 8px, 0)
        +display-flex
        +flex-direction(row)
        +justify-content(space-between)
        .button
          +flex-shrink(0)
          +justify-content(center)
          width: 100%
    &.sectionClient
      +contentEdgeInsets(8px, 0, 8px, 0)
      box-sizing: border-box
      .participantsList
        +offset(0, 16px, 0, 0)
      .addParticipant
        +justify-content(center)
        width: 100%


      .clientDetails
        +contentEdgeInsets(0, 16px, 0, 16px)
        +display-flex
        +flex-direction(row)
        +align-items(center)
        // +justify-content(space-between)
        box-sizing: border-box
        position: relative

        .clientActions
          +size(auto, 100%)
          +positionAbsoluteWithOffset(0, auto, auto, auto)
          +display-flex
          +align-items(center)
          +interactionTransition
          background-color: $colorBackgroundPrimary
          opacity: 0

        &:hover
          .clientActions
            opacity: 1

        .details
          +offset(8px, 0, 0, 0)
          +size(100%, auto)
          .fullName
            +fontSubheadEmphasized
            color: $colorTitle
            +display-flex
            +flex-direction(column)
            
            .name
              max-width: 120px
              overflow-x: hidden
              overflow-y: hidden
              max-height: 20px
          .role
            +fontCaption1
            color: $colorSubtitle
          .location
            +fontFootnote
            color: $colorSubtitle

        .profilePicture
          +size(40px, 40px)
          +cornerRadius(50%)
          background-color: $colorBackgroundPlaceholder

        .participantProfilePicture
          +size(36px, 36px)

        .separator
          +positionAbsoluteWithOffset(0, auto, 0, 0)
          +size(auto, 1px)
          background-color: $colorSeparator

        .muteParticipantSwitch
          +size(32px, 32px)
          +display-flex
          +align-items(center)
          +justify-content(center)
          +flex-shrink(0)
          +cornerRadius(50%)
          +cursorAction
          +interactionTransition
          background-color: $colorActionTranslucent
          &:hover
            background-color: $colorActionTranslucentHover
          &:active
            opacity: 0.4

          &.switchOff
            background-color: $colorAction
            path
              fill: $colorBackgroundPrimary
          &.switchOn
            path
              fill: $colorAction

        &.isInactive
          opacity: 0.4

      .clientLocation
        +size(100%, 126px)
        +offset(0, 16px, 0, 0)
        +cornerRadius(8px)
        +clipToBounds
        +shadowSmall
        position: relative
        z-index: 1
        .mapboxgl-ctrl-bottom-left
          display: none
        .nav
          +cornerRadius(6px)
          +clipToBounds
          +positionAbsoluteWithOffset(auto !important, 8px !important, 8px !important, auto !important)
          padding: 0 !important
          // I'm sorry for using important but it's necessary to make sure it doesn't look like crap
          .mapboxgl-ctrl-group
            +shadowSmall
            .mapboxgl-ctrl-compass
              display: none
            .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out
              +size(24px, 24px)
              +display-flex
              +align-items(center)
              +justify-content(center)
              .mapboxgl-ctrl-icon
                +size(16px, 16px)
                opacity: 0.4

      .servicePlan
        +size(100%, 56px)
        +contentEdgeInsets(10px, 0, 10px, 0)
        +display-flex
        +flex-direction(row)
        +align-items(center)
        +cornerRadius(8px)
        box-sizing: border-box
        background-color: $colorActionTranslucentCell

        .icon
          +iconServiceWellnessPlan

        .serviceDetails
          +offset(10px, 0, 0, 0)

          .title
            +fontFootnoteEmphasized
            color: $colorAction
          .detail
            +fontFootnote
            color: $colorSubtitle



      .sectionSeparator
        +contentEdgeInsets(0, 0, 0, 0)
        +size(100%, 16px)

  .sideBarContent
    .sideBarSection
      .attributeCell
        &.service
          .icon
            +iconConversationContextDetailsService
        &.animal
          .icon
            +iconConversationContextDetailsPatientTypeDog
          &.empty
            .icon
              +iconConversationContextDetailsPatientEmpty
        &.symptoms
          .icon
            +iconConversationContextDetailsPatientSymptoms
        &.status
          .icon
            +iconConversationContextDetailsConsultationStatus
        &.suspectedDiagnosis
          .icon
            +iconConversationContextDetailsConsultationSuspectedDiagnosis
        &.priority
          &.none
            .icon
              +iconConversationContextDetailsConsultationPriorityNone
          &.low
            .icon
              +iconConversationContextDetailsConsultationPriorityLow
          &.medium
            .icon
              +iconConversationContextDetailsConsultationPriorityMedium
          &.high
            .icon
              +iconConversationContextDetailsConsultationPriorityHigh
        &.opened
          .icon
            +iconConversationContextDetailsConsultationOpened
        &.qrcode
          .icon
            +iconConversationContextDetailsConsultationQrCode
        &.followUpReminder
          .icon
            +iconConversationContextDetailsConsultationReminderEmpty
        &.videoCall
          .icon
            +iconConversationContextDetailsVideoCall
        &.disposition
          .icon
            +iconConversationContextDetailsDisposition
        &.demographics
          .icon
            +iconConversationContextDetailsPatientDemographics
        &.interaction
          .icon
            +iconConversationContextDetailsPatientInteraction
        &.insurance
          .icon
            +iconConversationContextDetailsPatientInsurance

.conversationNotes
  .DraftEditor-root
    max-height: 100%
