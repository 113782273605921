.modalDialog
  +size(320px, auto)
  +display-flex
  +cornerRadius(8px)
  +shadowLarge
  +flex-direction(column)
  +clipToBounds
  position: relative
  background-color: $colorBackgroundSecondary
  z-index: 100
  outline: none
  @media only screen and (max-width: 640px)
    width: 100% !important
    height: 100% !important
    overflow: auto

  .actionBar
    position: absolute
    bottom: 0px

  .titleBar
    +size(100%, 48px)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    background-color: $colorBackgroundPrimary
    position: relative

    .title
      +contentEdgeInsets(12px, 0, 12px, 0)
      +fontSubheadEmphasized
      +noUserSelect
      +cursorDefault
      color: $colorTitle

    .separator
      +size(100%, 1px)
      +positionAbsoluteWithOffset(0, auto, auto, 0)
      background-color: $colorSeparator

  .content
    +size(100%, 100%)
    +maxSize(auto, 76vh)
    +contentEdgeInsets(0, 0, 0, 56px)
    overflow-y: auto

    .tableView
      +contentEdgeInsets(0, 16px, 0, 16px)
      +size(100%, auto)

      .tableSection
        +contentEdgeInsets(12px, 0, 12px, 24px)
        &:last-of-type
          padding-bottom: 0

        .header
          +offset(0, 0, 0, 6px)
