.actionBar
  +size(100%, 56px)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  background-color: $colorBackgroundPrimary
  position: relative

  .actions
    +size(100%, 100%)
    +contentEdgeInsets(12px, 0, 12px, 0)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    +justify-content(flex-end)

    .button
      &:nth-child(n)
        margin-left: 10px

    &.left
      +justify-content(flex-start)

      .button
        &:first-child
          margin-right: 10px
        &:last-child
          margin-left: 0

  .separator
    +size(100%, 1px)
    +positionAbsoluteWithOffset(0, 0, auto, auto)
    background-color: $colorSeparator
