.activitySection
  &.sectionInsights
    +size(100%, auto)
    +clipToBounds
    +contentEdgeInsets(0, 0, 0, 0)

    .insightsCarousel
      +size(100%, auto)
      // +clipToBounds
      +offset(0, 16px, 0, 0)
      +contentEdgeInsets(24px, 0, 24px, 0)
      +display-flex
      +flex-direction(row)
      +hideScrollBars
      overflow-x: scroll
      .insightsSection
        +offset(0, 0, 20px, 0)
        +display-flex
        +flex-direction(column)
        .insightsSectionTitle
          +offset(0, 0, 0, 8px)
          +fontSubheadEmphasized
          color: $colorSubtitle

      .insightsSectionCells
        +size(100%, auto)
        +display-flex
        +flex-direction(row)
      
      .insightsCell
        +size(200px, 90px)
        +contentEdgeInsets(12px, 0, 12px, 0)
        +offset(0, 0, 12px, 0)
        +display-flex
        +flex-direction(column)
        +align-items(flex-start)
        +justify-content(center)
        +cornerRadius(8px)
        background-color: $colorActionTranslucentCell

        .insightTitle
          +fontSubheadEmphasized
          color: $colorSubtitle

        .insightValue
          +offset(0, 2px, 0, 0)
          +fontTitle0Emphasized
          color: $colorTitle

