.surveysView
  .surveysList
    .tableSection
      .surveyCell
        +size(100%, auto)
        +flex-direction(column)
        // +flex-grow(1)
        // +flex-shrink(0)


.surveyCell
  +contentEdgeInsets(0, 0, 0, 0)
  +cursorDefault
  background-color: $colorBackgroundSecondary

  &:active
    transform: scale(1)
    opacity: 1
  &:hover
    transform: scale(1)
    opacity: 1

  .surveyBaseCell, .surveySourceCell
    +size(100%, 60px)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    position: relative

  .surveySourcesList
    +size(100%, auto)
    +display-flex
    +flex-direction(column)

  .surveyBaseCell
    +cornerRadius(6px)
    +clipToBounds
    .title
      max-width: 361px
      text-overflow: ellipsis
      overflow: hidden
    background-color: $colorBackgroundPrimary

  &.expanded
    .surveyBaseCell
      +shadowCell
    .actionExpand
      background-color: $colorAction
      .title
        color: $colorBackgroundPrimary

  .surveySourcesList
    background-color: $colorBackgroundSecondary

  .cellBody
    +offset(12px, 0, 0, 0)
    +size(auto, auto)
    +flex-shrink(0)
    +flex-direction(column)
    min-width: 240px

  .surveyDetails
    +size(100%, 100%)
    +offset(12px, 0, 0, 0)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    +justify-content(space-between)

    .surveyStats
      +offset(12px, 0, 0, 0)
      +size(auto, auto)
      +display-flex
      +flex-direction(row)
      +flex-shrink(0)
      .surveyStatItem
        +offset(0, 0, 24px, 0)
        +flex-direction(column)

    .surveyActions
      +offset(0, 0, 12px, 0)
      +size(auto, auto)
      +display-flex
      +flex-direction(row)

      .surveyAction
        +contentEdgeInsets(12px, 0, 12px, 0)
        +offset(8px, 0, 0, 0)
        .icon
          +size(auto, auto)
          background-color: $colorClear
        &.tint
          +offset(16px, 0, 0, 0)

  .separator
    +size(100%, 1px)
    +positionAbsoluteWithOffset(0, auto, 0, 0)
    +shadowSeparator
    z-index: 2