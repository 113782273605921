.sourcesView
  .sourcesList
    +size(100%, auto)
    .tableSection
      +contentEdgeInsets(24px, 0, 24px, 24px)
      +flex-direction(column)
      +offset(0, 18px, 0, 0)
      .sectionTitle
        display: none
      .sourceCell
        +size(100%, auto)
        // +contentEdgeInsets(12px, 10px, 12px, 10px)
        +offset(0, 8px, 0, 0)

  .sourceName
    +display-flex
    align-self: start

  .attributeItem
    +size(auto, 100%)
    +contentEdgeInsets(8px, 0, 8px, 0)
    +display-flex
    align-self: end
    +cornerRadius(4px)
    +fontCaption2Emphasized
    background-color: $colorPriorityNone
    margin-left: 5px
