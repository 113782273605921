// Layout
.satisfactionPersonnelView
  .navBar
    +size(100%, 64px)

    .navTitle
      +size(auto, auto)
      +positionAbsoluteWithOffset(50%, 50%, auto, auto)
      +flex-direction(column)
      +align-items(center)
      +transform(translate(-50%, -50%))

      .viewName
        +offset(0, 0, 0, 0)

      .satisfactionPeriod
        +display-flex
        +flex-direction(row)
        +align-items(center)

        svg
          +offset(4px, 0, 0, 0)

  .tableView
    .tableSection
      +flex-direction(column)

      &.expanded
        .sectionHeader
          .icon
            +transform(rotate(90deg))

  .sectionHeader
    +display-flex
    +flex-direction(row)

    .icon
      +offset(0, 0, 6px, 0)

  .feedbackOverview
    +offset(-6px, 0, -6px, 0)
    +display-flex
    +flex-direction(row)
  
  .cpssGraph
    +size(100%, 450px)
    +contentEdgeInsets(20px, 20px, 20px, 0)
    +cornerRadius(10px)

// Styles
.satisfactionPersonnelView
  .navBar
    .viewName
      +fontBodyEmphasized
      color: $colorTitle

    .dateValue
      +fontCaption1
      color: $colorSubtitle

  .tableSection
    .sectionHeader
      .sectionTitle
        +fontTitle3Emphasized
        color: $colorHeading

  .cpssGraph
    +shadowLarge
    background-color: $colorBackgroundPrimary
    
    .grapScore
      +fontTitle1Emphasized
      color: $colorTitle

    .graphPeriod
      +fontFootnote
      color: $colorSubtitle

// Interactions
.satisfactionPersonnelView

