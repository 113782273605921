.led-box
  height: 30px
  width: 25%
  margin: 10px 0
  margin-left: 10px
  float: left

.led-green
  margin: 0 auto
  width: 24px
  height: 24px
  background-color: #ABFF00
  border-radius: 50%
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px

.led-red
  margin: 0 auto
  width: 24px
  height: 24px
  background-color: #F00
  border-radius: 50%
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px

.led-yellow
  margin: 0 auto
  width: 24px
  height: 24px
  background-color: #FF0
  border-radius: 50%
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 12px

.lexicalEditorContent
  color: $colorTitle
  outline: none

.lexicalEditorPlaceholder
  color: $colorTitlePlaceholder

textarea:focus
  outline-color: $colorTintHover
  outline: 2px solid $colorTintHover
  box-shadow: 0 0 2px 2px $colorTintHover

