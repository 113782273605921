// Font Imports
@font-face
    font-family: 'Inter'
    font-style:  normal
    font-weight: 400
    font-display: swap
    src: url("https://rsms.me/inter/font-files/Inter-Regular.woff2?v=3.11") format("woff2"), url("https://rsms.me/inter/font-files/Inter-Regular.woff?v=3.11") format("woff")

@font-face
    font-family: 'Inter'
    font-style:  normal
    font-weight: 600
    font-display: swap
    src: url("https://rsms.me/inter/font-files/Inter-SemiBold.woff2?v=3.11") format("woff2"), url("https://rsms.me/inter/font-files/Inter-SemiBold.woff?v=3.11") format("woff")

@font-face
    font-family: 'Inter'
    font-style:  normal
    font-weight: 700
    font-display: swap
    src: url("https://rsms.me/inter/font-files/Inter-Bold.woff2?v=3.11") format("woff2"), url("https://rsms.me/inter/font-files/Inter-Bold.woff?v=3.11") format("woff")

// Font Families
$fontFamilyInter: 'Inter', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif

// Font Weights
$fontWeightRegular: 400
$fontWeightSemibold2: 500
$fontWeightSemibold: 600
$fontWeightBold: 700

// Font Rendering
html
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

// Main Font Text Styles
=fontTitle0
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 34px
    letter-spacing: -1.6px
    text-align: left
    line-height: 41px

=fontTitle0Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightBold
    color: $colorTitle
    font-size: 34px
    letter-spacing: -1px
    text-align: left
    line-height: 41px


=fontTitle1
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 28px
    letter-spacing: -1.4px
    text-align: left
    line-height: 34px

=fontTitle1Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightBold
    color: $colorTitle
    font-size: 28px
    letter-spacing: -0.7px
    text-align: left
    line-height: 34px


=fontTitle2
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 22px
    letter-spacing: -1px
    text-align: left
    line-height: 28px

=fontTitle2Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightBold
    color: $colorTitle
    font-size: 22px
    letter-spacing: -0.5px
    text-align: left
    line-height: 28px


=fontTitle3
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 20px
    letter-spacing: -0.9px
    text-align: left
    line-height: 25px

=fontTitle3Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightBold
    color: $colorTitle
    font-size: 20px
    letter-spacing: -0.4px
    text-align: left
    line-height: 25px


=fontBody
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 17px
    letter-spacing: -0.41px
    text-align: left
    line-height: 22px

=fontBodyEmphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold
    color: $colorTitle
    font-size: 17px
    letter-spacing: -0.41px
    text-align: left
    line-height: 22px


=fontCallout
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 16px
    letter-spacing: -0.32px
    text-align: left
    line-height: 21px

=fontCalloutEmphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold
    color: $colorTitle
    font-size: 16px
    letter-spacing: -0.32px
    text-align: left
    line-height: 21px


=fontSubhead
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 15px
    letter-spacing: -0.24px
    text-align: left
    line-height: 20px

=fontSubheadEmphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold
    color: $colorTitle
    font-size: 15px
    letter-spacing: -0.24px
    text-align: left
    line-height: 20px


=fontSubhead2
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 14px
    letter-spacing: -0.16px
    text-align: left
    line-height: 19px

=fontSubhead2Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold
    color: $colorTitle
    font-size: 14px
    letter-spacing: -0.16px
    text-align: left
    line-height: 19px

=fontSubhead3Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold2
    color: $colorTitle
    font-size: 15px
    letter-spacing: -0.24px
    text-align: left
    line-height: 20px

=fontFootnote
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 13px
    letter-spacing: -0.08px
    text-align: left
    line-height: 18px

=fontFootnoteEmphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold
    color: $colorTitle
    font-size: 13px
    letter-spacing: -0.08px
    text-align: left
    line-height: 18px


=fontCaption1
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 12px
    letter-spacing: 0
    text-align: left
    line-height: 16px

=fontCaption1Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold
    color: $colorTitle
    font-size: 12px
    letter-spacing: 0
    text-align: left
    line-height: 16px


=fontCaption2
    font-family: $fontFamilyInter
    font-weight: $fontWeightRegular
    color: $colorTitle
    font-size: 11px
    letter-spacing: 0.07px
    text-align: left
    line-height: 13px

=fontCaption2Emphasized
    font-family: $fontFamilyInter
    font-weight: $fontWeightSemibold
    color: $colorTitle
    font-size: 11px
    letter-spacing: 0.07px
    text-align: left
    line-height: 13px
