body
  --shadowCell: 0 2px 5px 0 rgba(0, 0, 0, .06)
  --shadowCellElevated: 0 4px 10px 0 rgba(0, 0, 0, .1)
  --shadowSmall: 0 2px 5px 0 rgba(0, 0, 0, .12)
  --shadowSmallBordered: 0 2px 5px 0 rgba(0, 0, 0, .12), 0 0 0 1px rgba(0, 0, 0, 0.01)
  --shadowMedium: 0 4px 10px 0 rgba(0, 0, 0, .12)

.prefersDarkTheme
  --shadowCell: 0 0 0 1px rgba(255, 255, 255, .14), 0 2px 5px 0 rgba(0, 0, 0, .24)
  --shadowCellElevated: 0 0 0 1px rgba(255, 255, 255, .16), 0 4px 10px 0 rgba(0, 0, 0, .4)
  --shadowSmall: 0 0 0 1px rgba(255, 255, 255, 0.12), 0 2px 5px 0 rgba(0, 0, 0, .32)
  --shadowSmallBordered: 0 0 0 1px rgba(255, 255, 255, 0.1), 0 2px 5px 0 rgba(0, 0, 0, .32)
  --shadowMedium: 0 4px 10px 0 rgba(0, 0, 0, .4), 0 0 0 1px rgba(255, 255, 255, 0.1)

=shadowNone
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)

=shadowSeparator
  box-shadow: 0 1px 0 0 $colorSeparator

=shadowSeparatorOnDark
  box-shadow: 0 1px 0 0 $colorSeparatorOnDark

=shadowSmall
  box-shadow: var(--shadowSmall)

=shadowSmallInset
  box-shadow: inset 0 2px 8px 0 rgba(0,0,0,0.08)

=shadowSmallSubtle
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .08)

=shadowSmallBordered
  box-shadow: var(--shadowSmallBordered)

=shadowMedium
  box-shadow: var(--shadowMedium)

=shadowMediumProminent
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .24)

=shadowLarge
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .12)

=shadowCell
  box-shadow: var(--shadowCell)

=shadowCellElevated
  box-shadow: var(--shadowCellElevated)

=shadowTabBar
  box-shadow: 2px 0 5px 0 rgba(0,0,0,0.12)

=shadowChatsList
  box-shadow: 2px 0 5px 0 rgba(0,0,0,0.05)

=shadowInputFocused
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1), 0 0 0 1px $colorTint, inset 0 0 0 1px $colorTint

=shadowPopover
  box-shadow: 0 12px 30px 0 rgba(0,0,0,0.24), 0 0 0 1px rgba(0,0,0,0.02)
