.addTask
  max-width: 360px


.addTaskAction
  +contentEdgeInsets(8px, 8px, 8px, 8px)
  +cornerRadius(6px)
  background-color: $colorBackgroundSecondary

  .taskActionTitleBar
    +contentEdgeInsets(0, 4px, 0, 14px)

  .titleBarTitle
    +fontSubheadEmphasized
    color: $colorTitle

  .taskActionActionsBar
    +display-flex
    +flex-direction(row)
    +justify-content(space-between)

  .titledSelectCell
    z-index: 100

.sectionTextArea
  width: 100%
  background-color: $colorBackgroundPrimary
  border: none
  color: $colorTitle
  border-radius: 6px
  font-family: "Inter", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif
  font-weight: 400
  font-size: 15px
  letter-spacing: -0.24px
  text-align: left
  line-height: 20px

.sectionTextArea::placeholder
  color: var(--colorPlaceholder)
  opacity: 1 /* Ensure full opacity for placeholder text */


