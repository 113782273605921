.clientList
  .clientListContents
    +contentEdgeInsets(24px, 16px, 24px, 0)

    .clientCell
      +size(100%, 60px)
      +contentEdgeInsets(10px, 0, 10px, 0)
      +interactionTableViewCell

      &:hover
        .clientActions
          opacity: 1

      .separator
        +positionAbsoluteWithOffset(auto, auto, 0, auto)
        +size(1px, 24px)
        background-color: $colorSeparator

      .clientInfo
        +size(25%, 100%)
        +display-flex
        +flex-direction(row)
        +align-items(center)
        position: relative

        .profilePicture
          +size(40px, 40px)
          
        .clientDetails
          +offset(8px, 0, 0, 0)
          .clientName
            +fontSubheadEmphasized
            color: $colorTitle
            max-width: 240px
            overflow-x: hidden
          .clientAnimalInfo
            +offset(0, -2px, 0, 0)
            &.multiple
              +fontFootnoteEmphasized
              color: $colorSubtitle
            .clientAnimalInfoName
              +fontFootnoteEmphasized
              color: $colorSubtitle
            .clientAnimalInfoBreedSpecials
              +offset(6px, 0, 0, 0)
              +fontFootnote
              color: $colorSubtitle

      .clientContact
        +size(25%, 100%)
        +display-flex
        +align-items(center)
        +justify-content(center)
        position: relative

        .title
          +fontSubhead2
          color: $colorSubtitle

      .clientActions
        +size(auto, 100%)
        +positionAbsoluteWithOffset(auto, auto, 16px, auto)
        +flex-direction(row)
        +align-items(center)
        +display-flex
        +interactionTransition
        background-color: $colorBackgroundPrimary
        opacity: 0

        .action
          +size(auto, 28px)
          +contentEdgeInsets(10px, 0, 10px, 0)
          +offset(8px, 0, 0, 0)
          +display-flex
          +align-items(center)
          +cornerRadius(6px)
          +cursorAction
          +interactionTransition
          background-color: $colorActionTranslucent

          &:hover
            +interactionHoverScale
            +shadowSmall
            background-color: $colorAction
            .actionTitle
              color: $colorBackgroundPrimary

          &:active
            +interactionTapScale
            +interactionTapOpacity

          &.destructive
            background-color: $colorDestructiveTranslucent
            .actionTitle
              color: $colorDestructive
            &:hover
              background-color: $colorDestructive
              .actionTitle
                color: $colorTitleSecondary

          .actionTitle
            +fontFootnoteEmphasized
            color: $colorAction


