html, body, #root
  height: auto
  @include global-scrollbar


.appWindow
  +queryPrint
    +size(100%, 100%)
    +display-flex
    +flex-direction(row)
    background-color: $colorBackgroundSecondary

.appLogout
  padding-top: 50px
  width: 50%
  margin: 0 auto

  .title
    text-align: center
    padding-bottom: 20px
    font-weight: bold

  .button
    +size(auto, 32px)
    +offset(0, 0, 12px, 0)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    +cornerRadius(5px)
    +clipToBounds
    +cursorAction
    +shadowSmall
    +interactionTransition
    +fontFootnoteEmphasized
    +justify-content(center)
    background-color: $colorTint
    color: $colorTitleSecondary
    width: 100%
    align-text: center
    