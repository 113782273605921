.addDashboardDisplayName
  width: 100% !important

.supportToolsView
  .addButton
    +positionAbsoluteWithOffset(auto, auto, 24px, 24px)
    z-index: 2

.logEventLevelInterval
  margin-top: 16px
  .logEventLevelIntervalInput
    display: flex
    .inputCell
      width: 100%
      margin-left: 0 !important
      height: 40px !important
    .selectCell
      width: 100% 
      margin: 6px 0px 6px 6px
