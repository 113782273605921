.articlesView
  .articlesGroups
    .navBar
      .add
        +size(32px, 28px)
        +justify-content(center)
        +shadowNone
        background-color: $colorTintTranslucent
        &:hover
          background-color: $colorTintTranslucentHover
        .icon
          +offset(0, 0, 0, 0)
          +iconNavBarAddTint
  .articlesGroupsList
    +contentEdgeInsets(10px, 12px, 10px, 0)
    .articlesGroupCell
      +size(100%, 56px)
      +display-flex
      +flex-shrink(0)
      +flex-direction(column)
      +offset(0, 0, 0, 8px)
      +contentEdgeInsets(12px, 0, 12px, 0)
      +cornerRadius(6px)
      +justify-content(center)
      +noUserSelect
      +cursorAction
      +shadowCell
      position: relative
      background-color: $colorBackgroundPrimary
      .groupTitle
        +fontSubheadEmphasized
        color: $colorTitle
      .articlesCount
        +fontCaption1
        color: $colorSubtitle
      .groupActions
        +positionAbsoluteWithOffset(auto, 12px, 12px, auto)
        +interactionTransition
        opacity: 0
        background-color: $colorBackgroundPrimary
        .actionEdit
          min-width: 48px
          +justify-content(center)
      &.selected
        background-color: $colorTint
        .groupTitle
          color: $colorTitleSecondary
        .articlesCount
          color: $colorTitleSecondary
          opacity: 0.8
        .groupActions
          background-color: $colorTint
          .actionEdit
            background-color: rgba(255, 255, 255, 0.12)
            .title
              color: $colorTitleSecondary
            &:hover
              background-color: rgba(255, 255, 255, 0.32)
      &:hover
        .groupActions
          opacity: 1

  .articlesList
    .articleCell
      +size(100%, auto)
      +display-flex
      +flex-shrink(0)
      +flex-direction(column)
      +offset(0, 0, 0, 8px)
      +contentEdgeInsets(12px, 10px, 12px, 10px)
      +cornerRadius(6px)
      +justify-content(center)
      +noUserSelect
      +cursorDefault
      +shadowCell
      position: relative
      min-height: 56px
      background-color: $colorBackgroundPrimary
      .articleTitle
        +fontSubheadEmphasized
        color: $colorTitle
      .articleBody
        +fontCaption1
        color: $colorSubtitle
      .articleDetails
        +display-flex
        +flex-direction(row)
        +justify-content(space-between)
        +fontCaption1
        color: $colorSubtitle
      &.selected
        background-color: $colorTint
        .groupTitle
          color: $colorTitleSecondary
        .articlesCount
          color: $colorTitleSecondary
          opacity: 0.8
      .articleActions
        +positionAbsoluteWithOffset(auto, 12px, 12px, auto)
        +interactionTransition
        opacity: 0
        background-color: $colorBackgroundPrimary
        .actionEdit
          min-width: 48px
          +justify-content(center)
      &:hover
        .articleActions
          opacity: 1
