.publicSurveyForm
  +contentEdgeInsets(16px, 40px, 16px, 32px)
  +size(100%, auto)
  +display-flex
  +flex-direction(column)
  +align-items(center)
  min-height: 100vh
  background-color: $colorBackgroundSecondary

  .surveyDetails
    +size(100%, auto)
    +offset(0, 0, 0, 40px)
    +display-flex
    +flex-direction(column)
    +align-items(center)
    max-width: 560px

    .surveyAgencyName
      +fontTitle0Emphasized
      color: $colorTitle
    .surveyTitle
      +fontBodyEmphasized
      color: $colorTitle

  .surveyHeader, .surveyFooter
    +size(100%, auto)
    +display-flex
    +flex-direction(column)
    +align-items(center)
    max-width: 560px
    .headerText, .footerText
      +fontSubhead
      color: $colorTitle
      opacity: 0.8

  .surveyHeader
    +offset(0, 0, 0, 24px)

  .surveyFooter
    +offset(0, 16px, 0, 0)
    
    .submitSurvey
      +offset(0, 24px, 0, 0)
      min-width: 220px

  .fontStyleAnswer
    +fontSubhead3Emphasized
    color: $colorTitle

  .questionCell
    +size(100%, auto)
    +contentEdgeInsets(10px, 10px, 10px, 10px)
    +offset(0, 0, 0, 12px)
    +cornerRadius(6px)
    +shadowCell
    max-width: 560px
    background-color: $colorBackgroundPrimary

    .surveyQuestion
      +fontSubheadEmphasized
      color: $colorTitle

    .surveyAnswers
      margin-left: auto

    .surveyAnswer
      +contentEdgeInsets(12px, 11px, 12px, 11px)
      +offset(0, 8px, 0, 0)
      +cornerRadius(6px)
      +interactionTransition
      +cursorAction
      +noUserSelect
      min-height: 40px
      background-color: $colorActionTranslucentCellButton

      
      .answerTitle
        +fontFootnoteEmphasized
        //color: $colorTitle
        color: #f6f6f6
      
      &:first-of-type
        +offset(0, 12px, 0, 0)

      &:active
        opacity: 0.4

      &:hover
        background-color: $colorActionTranslucentCellButtonHover

      &.selected
        background-color: $colorTint
        &:hover
          background-color: $colorTint

        .answerTitle
          color: $colorTitleSecondary

    &.answerChoice
    
    &.answerScale
      .surveyAnswers
        +offset(0, 12px, 0, 0)
        +display-flex
        +flex-direction(row)
        .surveyAnswer
          +flex-grow(1)
          +display-flex
          +justify-content(center)
          +offset(0, 0, 8px, 0)
          &:last-of-type
            +offset(0, 0, 0, 0)
      .answersLabels
        +offset(0, 8px, 0, 0)
        +display-flex
        +justify-content(space-between)
        .label
          +noUserSelect
          +cursorDefault
          +fontFootnoteEmphasized
          color: $colorTitle

    &.answerComment
      .surveyAnswer
        &.inputCell
          +contentEdgeInsets(12px, 11px, 12px, 11px)
          +offset(0, 8px, 0, 0)
          +cornerRadius(6px)
          +cursorText
          +userSelect
          +shadowNone
          min-height: 40px
          background-color: $colorActionTranslucentCell

          &:hover
            background-color: $colorActionTranslucentCellHover
          .input
            +fontFootnote
            text-align: left
      .button
        +offset(0, 12px, 0, 0)
        +justify-content(center)
        max-width: 180px

  .statusMessage
    // +size(100%, auto)
    +contentEdgeInsets(32px, 24px, 32px, 24px)
    +offset(0, 0, 0, 0)
    +display-flex
    +flex-direction(column)
    +align-items(center)
    +cornerRadius(12px)
    +shadowSmall
    background-color: $colorBackgroundPrimary
    // max-width: 560px

    .messageTitle
      +fontTitle1Emphasized
      color: $colorTitle
      text-align: center
    .messageBody
      +offset(0, 4px, 0, 0)
      +fontBody
      color: $colorSubtitle
      text-align: center
