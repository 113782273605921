// Layout
.satisfactionPersonnelFeedbackCell
  +size(100%, auto)
  +offset(0, 8px, 0, 0)
  +contentEdgeInsets(12px, 0, 12px, 0)
  +display-flex
  +align-items(center)
  +cornerRadius(8px)

  .feedbackText
    +contentEdgeInsets(0, 12px, 0, 12px)
    +size(100%, auto)


// Styles
.satisfactionPersonnelFeedbackCell
  +shadowMedium
  background-color: $colorTint

  .feedbackText
    +fontTitle3Emphasized
    color: $colorTitleSecondary
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16)


// Interactions
.satisfactionPersonnelFeedbackCell
  +cursorAction
  +interactionTransition

  &:active
    opacity: 0.8


// Specifics
.satisfactionPersonnelFeedbackCell
  &.positive
    background: linear-gradient(180deg, #5F7FFA 0%, #424AE8 100%)
    .icon
      +offset(0, 0, -1px, -1px)
  
  &.neutral
    background: linear-gradient(180deg, #BCBDC9 0%, #747583 100%)
    .icon
      +offset(0, 0, -2px, -2px)

  &.negative
    background: linear-gradient(180deg, #FC7661 0%, #EB4F4F 100%)
    .icon
      +offset(0, 0, -2px, -2px)
