.addPublicFeedbackButton
    display: flex
    align-items: center
    border-radius: 5px
    color: #fff
    background-color: $colorTint
    padding: 5px
    width: fit-content
    cursor: pointer
    margin: 1em 0


.editMarginAdjustment
    margin: 1em 0.5em 1em 0
    white-space: nowrap

.publicFeedbackView
    display: flex
    flex-direction: column
    gap: 0
    padding: 0
    position: relative /* For absolute positioning of the action bar */
    min-height: 100vh /* Ensure it covers the full viewport height */


.publicFeedbackOption
    display: block
    margin-bottom: 8px


.publicFeedbackContainer
    gap: 0.5rem
    padding: 1rem /* Add padding for better visual separation */
    padding-bottom: 0.5rem
    padding-top: 0.5rem

.publicFeedbackNameContainer
    gap: 0.5rem
    padding: 1rem
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    padding-left: 1.5rem
    width: 100%


.publicFeedbackHeader
    font-weight: bold
    font-family: inherit
    font-size: medium
.publicFeedbackNameHeader
    font-weight: bold
    font-family: inherit
    font-size: medium
    padding-top: 1rem


.publicFeedbackPersonnelType
    display: flex
    flex-direction: column

.publicFeedbackPersonnelTypeOption
    width: fit-content
    font-family: inherit
    margin-top: -0.5rem

.navBarFeedback
    padding-left: 0
    padding-right: 0
    padding-bottom: 0
    background-color: white
    border-bottom: 1px solid #e0e0e0
    .navBarFeedbackTitle
        color: black
        font-size: 1.5rem
        font-family: "Roboto", "Helvetica", "Arial", sans-serif
        font-weight: 500
        line-height: 1.6
        letter-spacing: 0.0075em

.publicFeedbackNameInput
    width: 25%
    .MuiInputBase-root
        .MuiInputBase-input
            height: 0.1876em
            font-family: inherit