=interactionTransition
  transition: all .28s cubic-bezier(0.42, 0.800, 0.58, 1.2), background-color .32s ease-in-out, color .32s ease-in-out, opacity .32s ease-in-out

=interactionNone
  +cursorDefault
  transform: scale(1)
  opacity: 1
  &:hover, &:active
    transform: scale(1)
    opacity: 1

=interactionTapScale
  transform: scale(0.98)

=interactionTapOpacity
  opacity: 0.4

=interactionHoverScale
  transform: scale(1.02)

=interactionTableViewCell
  +interactionTransition
  &:hover
    +shadowCellElevated
    background-color: $colorBackgroundPrimaryHover
  &:active
    +shadowCell
    background-color: $colorBackgroundSecondary

=interactionTableViewCellInput
  +interactionTransition
  &:hover
    +shadowCellElevated
    transform: scale(1.01)
  &:active
    +shadowCell
    background-color: $colorBackgroundSecondary
    transform: scale(0.99)

=interactionTableViewCellSelect
  +interactionTransition
  &:hover
    +shadowCellElevated
    transform: none
  &:active
    +shadowCell
    background-color: $colorBackgroundSecondary
    transform: none

=interactionTableViewCellEditor
  +interactionTransition
  &:hover
    +shadowCellElevated
    transform: none
  &:active
    +shadowCell
    background-color: $colorBackgroundSecondary
    transform: none

=interactionTableViewCellNone
  +interactionTransition
  &:hover
    +shadowNone
    transform: none
  &:active
    +shadowNone
    background-color: $colorBackgroundPrimary
    transform: none

=interactionTableViewCellSwitch
  +interactionTransition
  &:hover
    +shadowCellElevated
    transform: none
  &:active
    +shadowCell
    background-color: $colorBackgroundSecondary
    transform: none


=interactionTableViewCellGrouped
  +interactionTransition
  &:hover
    +shadowNone
    background-color: $colorActionTranslucent
    transform: scale(1)
  &:active
    +shadowNone
    background-color: $colorActionTranslucent
    transform: scale(0.99)
    opacity: 0.4

=interactionTableViewCellSubtle
  +interactionTransition
  &:hover
    transform: scale(1.01)
  &:active
    transform: scale(0.99)

=interactionLogo
  +interactionTransition
  +noUserSelect
  +cursorAction
  &:hover
    transform: scale(1.01)
  &:active
    opacity: 0.4
    transform: scale(0.99)

=interactionLink
  +interactionTransition
  +noUserSelect
  +cursorAction
  &:active
    opacity: 0.4
    transform: scale(0.98)