// Layout
.satisfactionPersonnelSurveyCell
  +offset(0, 0, 0, 8px)
  .surveyResults
    +size(auto, 100%)
    +display-flex
    +flex-direction(row)
    +align-items(center)

    .detailCell
      +offset(0, 0, 32px, 0)

      &.additionalBottomPadding
          +offset(0, 0, 42px, 0)

      .detailValue
        +offset(0, 0, 0, 4px)

    .viewSurvey
      .buttonTitle
        +offset(0, 0, 5px, 0)


// Styles
.satisfactionPersonnelSurveyCell
  .detailCell
    .detailValue
      +fontSubheadEmphasized
      color: $colorTitle

    .detailTitle
      +fontCaption2
      color: $colorSubtitle
      white-space: nowrap


// Interactions
.satisfactionPersonnelSurveyCell
  +cursorAction

