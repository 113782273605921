.AppIntegrationContainer
  .navigation
    +display-flex
    gap: 5px
  .selectedAppIntegration
    .formGroup
      +display-flex
      +flex-direction(column)
      gap: 10px
      .formField
        +display-flex
        align-items: center
        gap: 20px
        .inputText
          width: 380px
      .enabledField
        margin-bottom: 10px

  .appIntegrationList
    .enabled
      background-color: rgba(0, 0, 0, 0.04)
    .configureButton
      float: right