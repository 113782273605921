.addDigestButton
    display: flex
    align-items: center
    border-radius: 5px
    color: #fff
    background-color: $colorTint
    padding: 5px
    width: fit-content
    cursor: pointer
    margin: 1em 0 

.addDigestFrequencySelectContainer
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 0.5rem

.addDigestFrequencySelect
    font-weight: 500
    background-color: #ccc
    border-radius: 5px
    padding: 6px
    cursor: pointer

    &.active
        color: white
        background-color: $colorTint