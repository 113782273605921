.versionsView
  +size(100%, 100%)
  +display-flex
  +flex-direction(row)
  position: relative

  .actionBar
    +positionAbsoluteWithOffset(0, auto, 0, 0)
    +size(100%, 56px)
    +display-flex
    +flex-direction(row)
    +align-items(center)
    background-color: $colorBackgroundPrimary

  .navBar
    +size(100%, 48px)
    +contentEdgeInsets(12px, 0, 12px, 0)
    background-color: $colorBackgroundPrimary

    .title
      +fontSubheadEmphasized
      color: $colorTitle
      
    .separator
      +positionAbsoluteWithOffset(0, 0, 0, 0)

  .versionList
    +minSize(232px, 100%)
    +flex-shrink(0)
    +shadowChatsList
    z-index: 2
    overflow-y: scroll
    background-color: $colorBackgroundPrimary

    .versionSection
      +contentEdgeInsets(12px, 0, 12px, 0)

    .versionCell
      &.selected
        background-color: $colorTint
        .title
          color: $colorTitleSecondary

        &:hover
          background-color: $colorTint

        &:active
          background-color: $colorTint
          transform: scale(1)
          opacity: 0.8

        .active
          .icon
            +iconTableViewAccessoryCheckmarkSelected

      .active
        +display-flex
        +align-items(center)
        +justify-content(flex-end)
        +size(100%, 100%)
        background-color: $colorClear
        .icon
          +offset(0, 0, 0, 0)
          background-color: $colorClear
          +iconTableViewAccessoryCheckmark

  .versionEdit
    +size(100%, 100%)
    background-color: $colorBackgroundSecondary
    position: relative

    .scrollView
      +size(100%, 100%)
      +contentEdgeInsets(0, 0, 0, 80px)
      position: relative
      z-index: 2

      .actionBar
        z-index: 4
    
    .editContent
      +size(100%, 100%)
      overflow-y: scroll
      +contentEdgeInsets(12px, 16px, 12px, 16px)
      z-index: 1
      .tableSection
        +offset(0, 0, 0, 24px)
        +align-items(flex-start)
        &.filters
          .sectionRow
            .schemaSelect
              width: 40%
            .comparisonSelect
              width: 20%
            .inputCell
              width: 40%
            .fieldValueSelect
              width: 40%

        .button
          +flex-grow(0)
          +flex-shrink(1)

    .navBar
      background-color: $colorBackgroundSecondary

    .actionBar
      background-color: $colorBackgroundSecondary


//   +display-flex
//   +flex-direction(column)
//   +noUserSelect
//   +cursorDefault

// .versionCell
//   +size(100%, auto)
//   +contentEdgeInsets(16px, 14px, 16px, 14px)
//   +display-flex
//   +flex-direction(row)
//   +noUserSelect
//   +cursorAction
//   +interactionTransition
//   +flex-shrink(0)
//   position: relative
//   z-index: 1
//   box-sizing: border-box

//   .versionCellDetails
//     +size(100%, auto)
//     +display-flex
//     +flex-direction(column)
//     +offset(10px, 0, 0, 0)

//     .versionName
//       +display-flex
//       +flex-direction(row)
//       +justify-content(space-between)
//       +fontFootnoteEmphasized
//       color: $colorTitle

// .versionsView
//   +display-flex
//   +flex-direction(row)
//   width: 100%

// .versionsView
//   .versionList
//     +size(225px, 100vh)
//     +display-flex
//     +flex-shrink(0)
//     +flex-direction(column)
//     +shadowChatsList
//     z-index: 2
//     overflow-y: scroll
//     background-color: $colorBackgroundSecondary

//   .navBar
//     +flex-shrink(0)
//     z-index: 4
//     background-color: $colorBackgroundSecondary

//   .versionSection
//     +size(100%, auto)
//     +display-flex
//     +flex-direction(column)
//     +flex-shrink(0)
//     position: relative

//     .sectionTitle
//       +size(100%, auto)
//       +contentEdgeInsets(16px, 12px, 16px, 2px)
//       box-sizing: border-box
//       .title
//         +size(100%, auto)
//         +fontFootnoteEmphasized
//         color: $colorHeading

//     .sectionSeparator
//       +size(100%, 8px)
//       +contentEdgeInsets(16px, 0, 16px, 0)
//       box-sizing: border-box
//       .separator
//         +size(100%, 100%)
//         +shadowSeparator

// .versionEdit
//   +size(100%, 100%)
//   +display-flex
//   +flex-direction(column)
//   +noUserSelect
//   +cursorDefault
//   background-color: $colorBackgroundTertiary
