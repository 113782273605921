// CSS Variables

// Default Theme
body
  // General
  --colorClear: rgba(255, 255, 255, 0)
  // Title
  --colorTitle: rgba(0, 0, 0, 1)
  --colorTitleSelected: rgba(255, 255, 255, 1)
  --colorTitlePlaceholder: rgba(80, 80, 81, 1)
  --colorTitleDisabled: rgba(143, 143, 143, 1)
  --colorTitleDisabledSecondary: rgba(143, 143, 143, 0.4)
  --colorTitleSecondary: rgba(255, 255, 255, 1)
  --colorTitleSecondaryTranslucent: rgba(255, 255, 255, 0.16)
  --colorTitleSecondaryTranslucentHover: rgba(255, 255, 255, 0.24)
  // Subtitle
  --colorSubtitle: rgba(143, 143, 148, 1)
  --colorSubtitlePlaceholer: rgba(199, 199, 205, 1)
  --colorSubtitleDisabled: rgba(128, 128, 128, 1)
  --colorSubtitleTranslucent: rgba(0, 0, 0, 0.32)
  // Heading
  --colorHeading: rgba(109, 109, 114, 1)
  // Action
  --colorAction: rgba(133, 133, 148, 1)
  --colorActionUnSelected: rgba(34, 34, 47, 1)
  --colorActionHover: rgba(123, 123, 138, 1)
  --colorActionTranslucent: rgba(133, 133, 148, 0.14)
  --colorActionTranslucentHover: rgba(133, 133, 148, 0.24)
  --colorActionTranslucentCell: rgba(133, 133, 148, 0.06)
  --colorActionTranslucentCellHover: rgba(133, 133, 148, 0.1)
  --colorActionTranslucentCellButton: rgba(133, 141, 147, 1)
  --colorActionTranslucentCellButtonHover: rgba(153, 161, 167, 1)
  --colorActionNavigation: rgba(55, 55, 69, 0.3)

  // Tint
  --colorTint: rgba(0, 80, 184, 1)
  --colorTintHover: rgba(62, 101, 206, 1)
  --colorTintTranslucent: rgba(74, 119, 243, 0.14)
  --colorTintTranslucentHover: rgba(74, 119, 243, 0.24)
  --colorTintSecondary: rgba(74, 119, 243, 1)
  // Tint Purple
  --colorTintPurple: rgba(171, 149, 237, 1)
  --colorTintPurpleTranslucent: rgba(131, 116, 177, 0.14)
  --colorTintPurpleTranslucentHover: rgba(131, 116, 177, 0.24)
  // Tint Yellow
  --colorTintYellow: rgba(213, 190, 15, 1)
  --colorTintYellowTranslucent: rgba(147, 133, 26, 0.14)
  --colorTintYellowTranslucentHover: rgba(147, 133, 26, 0.24)
  // Destructive
  --colorDestructive: rgba(255, 59, 48, 1)
  --colorDestructiveHover: rgba(235, 39, 28, 1)
  --colorDestructiveTranslucent: rgba(255, 59, 48, 0.14)
  --colorDestructiveTranslucentHover: rgba(255, 59, 48, 0.24)
  // Success
  --colorSuccess: rgba(25, 175, 0, 1)
  --colorSuccessHover: rgba(25, 175, 0, 1)
  --colorSuccessTranslucent: rgba(25, 175, 0, 0.14)
  --colorSuccessTranslucentHover: rgba(25, 175, 0, 0.24)
  // Separator
  --colorSeparator: rgba(0, 0, 0, 0.06)
  --colorSeparatorOnDark: rgba(255, 255, 255, 0.06)
  // Background
  --colorBackgroundPrimary: rgba(255, 255, 255, 1)
  --colorBackgroundPrimaryTranslucent: rgba(255, 255, 255, 0.24)
  --colorBackgroundPrimaryHover: rgba(240, 240, 240, 1)
  --colorBackgroundSecondary: rgba(250, 250, 252, 1)
  --colorBackgroundTertiary: rgba(235, 235, 240, 1)
  --colorBackgroundDisabled: rgba(143, 143, 143, 0.08)
  --colorBackgroundDark: rgba(48, 52, 62, 1)
  --colorBackgroundPlaceholder: rgba(235, 235, 240, 0.4)
  --colorBackgroundSelected: rgba(58, 54, 54,1)
  // Video Call
  --colorBackgroundVideoCall: rgba(205,205,205,1)
  --colorCallEnd: rgba(215, 97, 85, 1)
  // Overlay
  --colorOverlay: rgba(0, 0, 0, 0.4)
  //Placeholder
  --colorPlaceholder: rgba(60, 60, 60, 1)
  // Priorities
  --colorPriorityNone: rgba(188, 188, 196, 1)
  --colorPriorityNoneTranslucent: rgba(188, 188, 196, 0.16)
  --colorPriorityLow: rgba(110, 186, 230, 1)
  --colorPriorityLowTranslucent: rgba(110, 186, 230, 0.16)
  --colorPriorityMedium: rgba(244, 142, 77, 1)
  --colorPriorityMediumTranslucent: rgba(244, 142, 77, 0.16)
  --colorPriorityHigh: rgba(240, 106, 93, 1)
  --colorPriorityHighTranslucent: rgba(240, 106, 93, 0.16)
  // Misc
  --colorTest: rgba(255, 201, 62, 1)
  --colorTestTranslucent: rgba(255, 201, 62, 0.16)

// Dark Theme

.prefersDarkTheme
  // General
  --colorClear: rgba(0, 0, 0, 0)
  // Title
  --colorTitle: rgba(234, 234, 234, 1)
  --colorTitleSelected: rgba(10, 10, 10, 1)
  --colorTitlePlaceholder: rgba(101, 101, 105, 1)
  --colorTitleDisabled: rgba(158, 158, 165, 1)
  --colorTitleDisabledSecondary: rgba(158, 158, 165, 0.4)
  --colorTitleSecondary: rgba(255, 255, 255, 1)
  // Subtitle
  --colorSubtitle: rgba(152, 152, 158, 1)
  --colorSubtitlePlaceholer: rgba(90, 90, 92, 1)
  --colorSubtitleDisabled: rgba(128, 128, 128, 1)
  --colorSubtitleTranslucent: rgba(255, 255, 255, 0.28)
  // Heading
  --colorHeading: rgba(142, 142, 146, 1)
  // Action
  --colorAction: rgba(180, 180, 192, 1)
  --colorActionUnSelected: rgba(230, 230, 230, 1)
  --colorActionHover: rgba(170, 170, 182, 1)
  --colorActionTranslucent: rgba(180, 180, 192, 0.14)
  --colorActionTranslucentHover: rgba(180, 180, 192, 0.24)
  --colorActionTranslucentCell: rgba(180, 180, 192, 0.06)
  --colorActionTranslucentCellHover: rgba(180, 180, 192, 0.1)
  --colorActionNavigation: rgba(55, 55, 69, 0.3)
  // Tint
  --colorTint: rgba(0, 80, 184, 1)
  --colorTintHover: rgba(62, 101, 206, 1)
  --colorTintTranslucent: rgba(74, 119, 243, 0.14)
  --colorTintTranslucentHover: rgba(74, 119, 243, 0.24)
  --colorTintSecondary: rgba(74, 119, 243, 1)
  // Destructive
  --colorDestructive: rgba(255, 67, 56, 1)
  --colorDestructiveTranslucent: rgba(255, 67, 56, 0.1)
  // Success
  --colorSuccess: rgba(25, 175, 0, 1)
  --colorSuccessHover: rgba(25, 175, 0, 1)
  --colorSuccessTranslucent: rgba(25, 175, 0, 0.14)
  --colorSuccessTranslucentHover: rgba(25, 175, 0, 0.24)
  // Separator
  --colorSeparator: rgba(255, 255, 255, 0.06)
  // Background
  --colorBackgroundPrimary: rgba(41, 42, 43, 1)
  --colorBackgroundPrimaryTranslucent: rgba(41, 42, 43, 0.24)
  --colorBackgroundPrimaryHover: rgba(56, 57, 59, 1)
  --colorBackgroundSecondary: rgba(28, 29, 30, 1)
  --colorBackgroundTertiary: rgba(19, 20, 21, 1)
  --colorBackgroundDisabled: rgba(143, 143, 143, 0.08)
  --colorBackgroundPlaceholder: rgba(19, 20, 21, 0.4)
  --colorBackgroundSelected: rgba(229, 225, 225,1)
  // Overlay
  --colorOverlay: rgba(0, 0, 0, 0.72)
  //Placeholder
  --colorPlaceholder: rgba(200, 200, 200, 1)

// SASS Variables
$colorClear: var(--colorClear)
// Title
$colorTitle: var(--colorTitle)
$colorTitleSelected : var(--colorTitleSelected)
$colorTitlePlaceholder: var(--colorTitlePlaceholder)
$colorTitleDisabled: var(--colorTitleDisabled)
$colorTitleDisabledSecondary: var(--colorTitleDisabledSecondary)
$colorTitleSecondary: var(--colorTitleSecondary)
$colorTitleSecondaryTranslucent: var(--colorTitleSecondaryTranslucent)
$colorTitleSecondaryTranslucentHover: var(--colorTitleSecondaryTranslucentHover)
// Subtitle
$colorSubtitle: var(--colorSubtitle)
$colorSubtitlePlaceholer: var(--colorSubtitlePlaceholer)
$colorSubtitleDisabled: var(--colorSubtitleDisabled)
$colorSubtitleTranslucent: var(--colorSubtitleTranslucent)
// Heading
$colorHeading: var(--colorHeading)
// Action
$colorAction: var(--colorAction)
$colorUnSelected: var(--colorActionUnSelected)
$colorActionHover: var(--colorActionHover)
$colorActionTranslucent: var(--colorActionTranslucent)
$colorActionTranslucentHover: var(--colorActionTranslucentHover)
$colorActionTranslucentCell: var(--colorActionTranslucentCell)
$colorActionTranslucentCellHover: var(--colorActionTranslucentCellHover)
$colorActionTranslucentCellButton: var(--colorActionTranslucentCellButton)
$colorActionTranslucentCellButtonHover: var(--colorActionTranslucentCellButtonHover)
$colorActionNavigation: var(--colorActionNavigation)
// Tint
$colorTint: var(--colorTint)
$colorTintHover: var(--colorTintHover)
$colorTintTranslucent: var(--colorTintTranslucent)
$colorTintTranslucentHover: var(--colorTintTranslucentHover)
$colorTintSecondary: var(--colorTintSecondary)
// Tint Purple
$colorTintPurple: var(--colorTintPurple)
$colorTintPurpleTranslucent: var(--colorTintPurpleTranslucent)
$colorTintPurpleTranslucentHover: var(--colorTintPurpleTranslucentHover)
// Tint Yellow
$colorTintYellow: var(--colorTintYellow)
$colorTintYellowTranslucent: var(--colorTintYellowTranslucent)
$colorTintYellowTranslucentHover: var(--colorTintYellowTranslucentHover)
// Destructive
$colorDestructive: var(--colorDestructive)
$colorDestructiveHover: var(--colorDestructiveHover)
$colorDestructiveTranslucent: var(--colorDestructiveTranslucent)
$colorDestructiveTranslucentHover: var(--colorDestructiveTranslucentHover)
// Success
$colorSuccess: var(--colorSuccess)
$colorSuccessHover: var(--colorSuccessHover)
$colorSuccessTranslucent: var(--colorSuccessTranslucent)
$colorSuccessTranslucentHover: var(--colorSuccessTranslucentHover)
// Separator
$colorSeparator: var(--colorSeparator)
$colorSeparatorOnDark: var(--colorSeparatorOnDark)
// Background
$colorBackgroundPrimary: var(--colorBackgroundPrimary)
$colorBackgroundPrimaryTranslucent: var(--colorBackgroundPrimaryTranslucent)
$colorBackgroundPrimaryHover: var(--colorBackgroundPrimaryHover)
$colorBackgroundSecondary: var(--colorBackgroundSecondary)
$colorBackgroundTertiary: var(--colorBackgroundTertiary)
$colorBackgroundDisabled: var(--colorBackgroundDisabled)
$colorBackgroundDark: var(--colorBackgroundDark)
$colorBackgroundPlaceholder: var(--colorBackgroundPlaceholder)
$colorBackgroundSelected : var(--colorBackgroundSelected)
// Video Call
$colorBackgroundVideoCall: var(--colorBackgroundVideoCall)
$colorCallEnd: var(--colorCallEnd)
// Overlay
$colorOverlay: var(--colorOverlay)
// Priorities
$colorPriorityNone: var(--colorPriorityNone)
$colorPriorityNoneTranslucent: var(--colorPriorityNoneTranslucent)
$colorPriorityLow: var(--colorPriorityLow)
$colorPriorityLowTranslucent: var(--colorPriorityLowTranslucent)
$colorPriorityMedium: var(--colorPriorityMedium)
$colorPriorityMediumTranslucent: var(--colorPriorityMediumTranslucent)
$colorPriorityHigh: var(--colorPriorityHigh)
$colorPriorityHighTranslucent: var(--colorPriorityHighTranslucent)
// Misc
$colorTest: var(--colorTest)
$colorTestTranslucent: var(--colorTestTranslucent)