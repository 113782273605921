.patientDetails
  min-height: 480px
  .secondaryNavBar
    +size(100%, auto)
    +contentEdgeInsets(12px, 12px, 12px, 0)

  .DraftEditor-root
    width: 100%
    height: 100%

  .medicalMultiSelect__control
    width: 100%

  .patientHeightInput
    .valueInputs
      +display-flex
      +align-items(center)
      +flex-direction(row)
    .inputCell
      max-width: 80px

  .sectionMatches
    .backButton
      +offset(0, 0, 0, 12px)
      +justify-content(center)
      max-width: 164px

  .buttonSaveAndVerify
    &:hover
      transform: scale(1)

.patientInteractionHistoryCell
  +size(100%, auto)
  +contentEdgeInsets(12px, 10px, 12px, 10px)
  +flex-direction(column)
  +align-items(flex-start)
  +interactionNone

  .interactionMetrics
    +size(100%, auto)
    +display-flex
    +flex-direction(row)

    .interactionAttribute
      +size(25%, auto)

  .interactionAttribute
    +size(100%, auto)
    +display-flex
    +flex-direction(column)

    .attributeTitle
      +fontFootnoteEmphasized
      color: $colorSubtitle

    .attributeValue
      +fontFootnote
      color: $colorTitle

    &.interactionNotes
      +offset(0, 8px, 0, 0)
      +flex-direction(column)
      +justify-content(flex-start)

      .attributeTitle
        +size(100%, auto)
      .attributeValue
        +size(100%, auto)

.possibleMatchCell
  +size(100%, auto)
  +contentEdgeInsets(12px, 12px, 12px, 12px)
  +flex-direction(column)
  +align-items(flex-start)
  +justify-content(flex-start)
  +interactionNone
  background-color: $colorBackgroundPrimary
  &:active
    background-color: $colorBackgroundPrimary

  .patientName
    +fontBodyEmphasized
    color: $colorTitle

  .patientAttributesList
    +size(100%, auto)
    +display-flex
    +flex-direction(column)
    +offset(0, 10px, 0, 10px)

  .patientAttribute
    +size(100%, auto)
    +display-flex
    +flex-direction(row)
    +justify-content(space-between)
    .attributeTitle
      +fontSubhead
      color: $colorSubtitle
    .attributeValue
      +fontSubhead
      color: $colorTitle

  .button
    width: 100%
    +justify-content(center)
    &:hover
      transform: scale(1)
