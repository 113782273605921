.sourcesView
  .sourceCell
    +contentEdgeInsets(0, 0, 0, 0)
    +flex-direction(column)
    background-color: $colorBackgroundSecondary
    .sourceBaseCell
      +size(100%, auto)
      +contentEdgeInsets(12px, 10px, 12px, 10px)
      +display-flex
      +flex-direction(column)
      +cornerRadius(6px)
      +shadowCell
      background-color: $colorBackgroundPrimary
      .cellBody
        +size(100%, auto)
        +display-flex
        +flex-direction(row)
        .cellActions
          +offset(0, 0, 0, 0)
      .sourceDetails
        +flex-direction(row)
        +display-flex
        +justify-content(space-between)
        .sourceAttribute
          .attributeTitle
            +fontFootnoteEmphasized
            color: $colorSubtitle
          .attributeValue
            +fontSubhead
            +userSelect
            color: $colorTitle
    .sourceActivityList
      +size(100%, auto)
      +display-flex
      +flex-direction(column)
      +contentEdgeInsets(12px, 10px, 12px, 10px)
      .sourceActivityCell
        +offset(0, 0, 0, 10px)
        +contentEdgeInsets(0, 0, 0, 10px)
        position: relative
        .title
          +userSelect
        .separator
          +positionAbsoluteWithOffset(0, 0, 0, 0)
          +shadowSeparator
        &:last-of-type
          +offset(0, 0, 0, 0)
          +contentEdgeInsets(0, 0, 0, 0)
          .separator
            display: none
