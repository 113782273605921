.surveyResultsView
  .detailView
    .navBar
      +justify-content(space-between)
    .scrollView
      height: calc(100% - 110px)
      .contentView
        +contentEdgeInsets(12px, 0, 12px, 56px)

  .attributeCell
    +contentEdgeInsets(0, 10px, 0, 12px)
    position: relative
    z-index: 1

    .attributeTitle
      +fontSubhead2Emphasized
      color: $colorHeading
    
    .attributeDetail
      +offset(0, 2px, 0, 0)
      +fontFootnote
      color: $colorTitle

    .separator
      +positionAbsoluteWithOffset(0, 0, 0, 0)
      +shadowSeparator
      z-index: -1

    &:first-of-type
      +contentEdgeInsets(0, 0, 0, 12px)

  .answerCell
    +contentEdgeInsets(10px, 10px, 10px, 10px)
    +offset(0, 0, 0, 12px)
    +cornerRadius(6px)
    +shadowCell
    background-color: $colorBackgroundPrimary


    .sentimentSelect
      +offset(0, 12px, 0, 0)
      max-width: 360px
      .button
        &.tint
          +offset(12px, 0, -8px, 0)

    .surveyQuestion
      +fontSubheadEmphasized
      color: $colorTitle

    .surveyAnswer
      +contentEdgeInsets(12px, 11px, 12px, 11px)
      +offset(0, 8px, 0, 0)
      +cornerRadius(6px)
      min-height: 40px
      background-color: $colorActionTranslucentCell
      
      .answerTitle
        +fontFootnoteEmphasized
        color: $colorTitleDisabled
      
      &:first-of-type
        +offset(0, 12px, 0, 0)

      &.selected
        background-color: $colorTint
        surface: #27272c
        focus-color: #222222
        .answerTitle
          color: $colorTitleSecondary

    &.answerChoice
    
    &.answerScale
      .surveyAnswers
        +offset(0, 12px, 0, 0)
        +display-flex
        +flex-direction(row)
        .surveyAnswer
          +flex-grow(1)
          +display-flex
          +justify-content(center)
          +offset(0, 0, 8px, 0)
          &:last-of-type
            +offset(0, 0, 0, 0)
      .answersLabels
        +offset(0, 8px, 0, 0)
        +display-flex
        +justify-content(space-between)
        .label
          +fontFootnoteEmphasized
          color: $colorSubtitle

    &.answerComment
      .surveyAnswer
        .answerTitle
          color: $colorTitle
      .button
        +offset(0, 12px, 0, 0)
        +justify-content(center)
        max-width: 180px

.alertModal

  .alertBody
    .alertMessage
      .tableCell
        height: 100%
        min-height: 40px
        .inputCell
          height: fit-content
          width: 100%
        
        &.textarea
          +display-flex
          align-items: start
          flex-direction: column
          padding-top: 10px
          padding-bottom: 10px
          textarea
            background-color: transparent
            border: 0
            color: white
            width: 100%
            height: 60px
            resize: none
            &:focus
              outline: none


.answerIgnore
  margin-top: 10px
  .MuiCheckbox-root
    padding-top: 0
    padding-bottom: 0
  .MuiFormControlLabel-label
    color: white
    font-size: 0.875rem
.answerIgnoreComment
  color: gray
  font-size: 0.8rem