.positivityView

  .scrollView
    +contentEdgeInsets(0, 0, 0, 24px)
    +size(100%, 100%)
    overflow-y: scroll

  .focusView
    +contentEdgeInsets(0, 0, 0, 24px)
    +size(100%, 100%)

  .carousel-root
    // height: 1000px

    .carousel-slider
      +positionAbsoluteWithOffset(auto, auto, auto, auto)
      // +transform(translate(0, -50%))
      height: calc(100% - 80px)
      // padding-top: 60px
      width: 97% !important

    .carousel
      .slide
        width: 100%
        background-color: transparent
        display: flex !important
        align-items: center

    .slider-wrapper
      height: 100%

      .slider
        height: 100%

        .slide
          flex-flow: inherit

    .carousel-slider
      .control-arrow
        color: $colorBackgroundPrimary
        opacity: 0.9
        &:hover
            border: 2px solid $colorBackgroundPrimary
            opacity: 1

  .feedbackList
    +contentEdgeInsets(24px, 24px, 24px, 0)
    // height: 999999px
    // +display-flex
    // +flex-flow(column wrap)
    // +align-content(space-between)
    // &::before, &::after
    //   content: ''
    //   flex-basis: 100%
    //   width: 0
    //   order: 2

  .feedback-masonry
    display: flex
  .feedback-masonry-column
    padding: 0px 5px 0px 5px

  .feedbackCell
    +size(100%, auto)
    +contentEdgeInsets(16px, 16px, 16px, 16px)
    +offset(0, 0, 0, 16px)
    +cornerRadius(12px)
    +shadowLarge
    background-color: $colorBackgroundPrimary

    // &.cellPrimary
    //   background-color: $colorBackgroundPrimary
    // &.cellSecondary
    //   background-color: $colorBackgroundTertiary
    &.clickable-feedback
      cursor: pointer

    // &:nth-child(2n+1)
    //   +offset(0, 0, 16px, 16px)
    //   order: 1
    // &:nth-child(2n+2)
    //   order: 2

    .feedbackText
      +fontTitle0Emphasized
      color: $colorTitleSecondary
      text-shadow: 0 0 3px rgba(0,0,0,0.10)
      white-space: normal
      overflow-wrap: break-word
      word-break: break-word
      @media only screen and (max-width: 550px)
        font-size: 25px
        letter-spacing: -1.5px
    
    .feedbackDate
      +offset(0, 8px, 0, 0)
      +fontBodyEmphasized
      color: $colorTitleSecondary
      opacity: 0.9
      text-align: left

    .feedbackAuthor
      +offset(0, 8px, 0, 0)
      +fontBodyEmphasized
      color: $colorTitleSecondary
      opacity: 0.9
      text-align: right


    &.focusedFeedback
      +size(100%, auto)
      // +contentEdgeInsets(24px, 24px, 24px, 24px)
      +contentEdgeInsets(4vh, 4vh, 4vh, 4vh)
      +offset(0, 0, 0, 0)
      +cornerRadius(3.5vh)
      // +cornerRadius(20px)
      .feedbackText
        color: $colorTitleSecondary
        // letter-spacing: -1.41px
        // line-height: 61.5px
        text-align: center
        &.small
          font-size: 4vh
          line-height: 5.1vh
          letter-spacing: -0.28vh
        &.medium
          font-size: 7vh
          line-height: 6.1vh
          letter-spacing: -0.30vh
        &.big
          font-size: 10vh
          line-height: 10.1vh
          letter-spacing: -0.32vh
.feedback-item-enter
  opacity: 0
.feedback-item-enter-active
  opacity: 1
  transition: opacity 1000ms ease-in
.feedback-item-exit
  opacity: 1
.feedback-item-exit-active
  opacity: 0
  transition: opacity 1000ms ease-in