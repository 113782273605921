.rulesView
  .versionEdit
    .editContent
      .tableSection
        &.filters
          .sectionRow
            +align-items(flex-start)
            .remove
              +offset(0, 4px, 0, 0)
            .fieldValueSelect
              +size(40%, 40px)
              +display-flex
              +flex-direction(row)
              position: relative
              &.expandableField
                +size(100%, auto)
                .selectCell
                  +size(100%, auto)
                  div
                    border-color: $colorClear
                    background-color: $colorBackgroundPrimary
                    min-width: 160px
                    div
                      +cornerRadius(6px)
                      min-width: auto
                      div
                        background-color: $colorActionTranslucent
                        color: $colorTitle
                        div
                          background-color: $colorClear
                        svg
                          +cursorAction
              .selectCell, .inputCell
                +size(calc(100% - 56px), 40px)
                +flex-shrink(1)
                +shadowCell
              .typeaheadSwitch
                +cornerRadius(6px)
                +offset(8px, 0, 0, 0)
                +size(40px, 40px)
                +flex-shrink(0)
                +display-flex
                +align-items(center)
                +justify-content(center)
                +shadowCell
                +cursorAction
                +noUserSelect
                +interactionTransition
                background-color: $colorBackgroundPrimary
                &:hover
                  // background-color: $colorActionTranslucentHover
                &:active
                  opacity: 0.4
                .icon
                  +size(24px, 24px)
                  &.suggested
                    +iconRulesTypeaheadSuggested
                  &.custom
                    +iconRulesTypeaheadCustom
       