.surveysView
  .surveysList
    +size(100%, auto)
    .tableSection
      +contentEdgeInsets(24px, 0, 24px, 24px)
      +flex-direction(column)
      +offset(0, 18px, 0, 0)
      .sectionTitle
        display: none
      .surveyCell
        +size(100%, 60px)
        +offset(0, 8px, 0, 0)
      .title.serviceName
        width: 361px
