.service
  +size(100%, 60px)

  .icon
    +display-flex
    +align-items(center)
    +justify-content(center)
    .glyph
      +iconServiceWellnessPlan

  .cellBody
    +size(calc(100% - 100px), auto)
    .description
      +clipToBounds
      white-space: nowrap
      text-overflow: ellipsis

  .price
    +size(auto, auto)
    +offset(8px, 0, 0, 0)
    +fontSubheadEmphasized
    color: $colorSubtitle
    max-width: 64px
