// Layout
.satisfactionView
  .navBar
    +size(100%, 64px)

    .navTitle
      +size(100%, auto)
      +flex-direction(column)
      +align-items(flex-start)

      .satisfactionPeriod
        +display-flex
        +flex-direction(row)
        +align-items(center)

        svg
          +offset(4px, 0, 0, 0)

  .tableView
    .tableSection
      position: relative
      +offset(0, 6px, 0, 0)
      .tableCell
        +size(100%, 60px)

  .satisfactionDaterangeInput
    +size(100%, auto)

// Styles
.satisfactionView
  .navBar
    .viewName
      +fontBodyEmphasized
      color: $colorTitle

    .dateValue
      +fontCaption1
      color: $colorSubtitle

// Interactions
.satisfactionView
  .navBar
    .viewSubtitle
      +cursorAction

  .tableView
    .tableSection
      .personnelCellClickable
        +cursorAction