.alertModal
  +size(320px, auto)
  +contentEdgeInsets(20px, 20px, 20px, 20px)
  +display-flex
  +cornerRadius(14px)
  +shadowLarge
  +flex-direction(column)
  +clipToBounds
  position: relative
  background-color: $colorBackgroundPrimary
  z-index: 100
  outline: none

  .alertBody
    .alertTitle
      +fontSubheadEmphasized
      color: $colorTitle
      text-align: center
    .alertMessage
      +offset(0, 8px, 0, 0)
      +fontFootnote
      color: $colorSubtitle
      text-align: center
  .alertActions
    +offset(0, 20px, 0, 0)
    &.dualActions
      +display-flex
      +flex-direction(row)
      +justify-content(space-between)
      .alertAction
        width: 48%
        +justify-content(center)
