.emailChipsContainer
    .error
        margin: 0
        font-size: 90%
        color: tomato

.emailChip
  background-color: #d4d5d6
  display: inline-block
  font-size: 14px
  border-radius: 30px
  height: 30px
  padding: 0 4px 0 1rem
  display: inline-flex
  align-items: center
  margin: 0 0.3rem 0.3rem 0

.emailChipRemoveButton
  background-color: white
  width: 22px
  height: 22px
  border-radius: 50%
  border: none
  cursor: pointer
  font: inherit
  margin-left: 10px
  font-weight: bold
  padding: 0
  line-height: 1
  display: flex
  align-items: center
  justify-content: center