.rulesView
  +size(100%, auto)
  .tableSection
    .sectionTitle
      +contentEdgeInsets(6px, 0, 6px, 0)

  .editContent
    .matchwindowRule
      .inputCell
        width: 100%
      .selectCell
        min-width: 100px
        width: auto  

.ruleCell
  +contentEdgeInsets(0, 0, 0, 0)
  +clipToBounds
  +cursorDefault
  position: relative
  &:active
    transform: scale(1)
    opacity: 1
  &:hover
    transform: scale(1)
    opacity: 1
    
    .ruleActions
      .hiddenActions
        +display-flex

  .cellBody
    +offset(12px, 0, 0, 0)
    +size(100%, auto)
    +cursorAction

  .ruleActions
    +offset(0, 0, 12px, 0)
    +size(auto, auto)
    +flex-shrink(0)
    +display-flex
    +flex-direction(row)

    .hiddenActions
      +flex-direction(row)
      display: none

    .ruleAction
      +contentEdgeInsets(12px, 0, 12px, 0)
      +offset(8px, 0, 0, 0)

.SingleDatePickerInput__disabled
  background: var(--colorBackgroundPrimary) !important
  border: none !important

.DateInput__disabled
  background: var(--colorBackgroundPrimary) !important
  color: var(--colorTitle)

.DateInput_input__disabled
  background: var(--colorBackgroundPrimary) !important
  font-style: normal

.Toastify__toast
  background-color: #292a2b

