.activityView
  +size(100%, auto)
  +display-flex
  +flex-direction(column)
  +flex-grow(0)
  position: relative
  background-color: $colorBackgroundSecondary

  .navBar
    +contentEdgeInsets(24px, 0, 24px, 0)
    background-color: $colorBackgroundSecondary
    .separator
      +positionAbsoluteWithOffset(24px, 0, 24px, 0)

  .secondaryNavBar
    .addTestEncounter
      +offset(0, 0, 8px, 0)

    .filterButton
      +offset(0, 0, 8px, 0)

  .scrollView
    +contentEdgeInsets(0, 0, 0, 24px)
    +size(100%, 100%)
    overflow-y: scroll

  .activitySection
    +size(100%, auto)
    +display-flex
    +flex-direction(column)
    +contentEdgeInsets(24px, 0, 24px, 0)
    position: relative

    &.sectionSurveys
      .tableCell
        +offset(0, 0, 0, 8px)

    &.sectionAllEncoutners
      +contentEdgeInsets(24px, 1px, 24px, 0)
      .encountersTitle
        display: none

    &.sectionInsights, &.sectionAllEncoutners
      width: calc(100vw - 200px)
      overflow: hidden
      .insightsSectionCells, .detailedEncountersTable
        overflow-x: scroll
        +hideScrollBars

    .encountersTitle
      +offset(0, 0, 0, 8px)
      +fontSubheadEmphasized
      color: $colorTitle
    .detailedEncountersList, .allEncountersList
      +size(100%, auto)
      +display-flex
      +flex-direction(column)

    .testIndicator
      +size(auto, 8px)
      +contentEdgeInsets(4px, 0, 4px, 0)
      +offset(0, 0, 8px, 0)
      +cornerRadius(4px)
      +display-flex
      +align-items(center)
      +justify-content(center)
      background-color: $colorTest

      .indicatorTitle
        +fontCaption2Emphasized
        color: $colorTest
        display: none

    .reviewIndicator
      +size(auto, 8px)
      +contentEdgeInsets(4px, 3px, 4px, 3px)
      +offset(0, 0, 8px, 0)
      +cornerRadius(4px)
      display: inline-block
      background-color: $colorTest

      .indicatorTitle
        +fontCaption2Emphasized
        color: $colorTest
        display: none

    &:hover
      .reviewIndicator
        +size(auto, 18px)
        .indicatorTitle
          color: $colorBackgroundPrimary
          display: block

    .consortiumIndicator
      +size(auto, 18px)
      +contentEdgeInsets(4px, 0, 4px, 0)
      +offset(0, 0, 8px, 0)
      +cornerRadius(9px)
      +display-flex
      +align-items(center)
      +justify-content(center)
      background-color: $colorTint

      .indicatorIcon
        +offset(0, -1px, 0, 0)
        path
          fill: $colorBackgroundPrimary

      .indicatorTitle
        +offset(4px, 0, 0, 0)
        +fontCaption1Emphasized
        color: $colorTint
        display: none

    .detailedEncounterCell, .encountersTableCell
      &:hover
        .testIndicator
          background-color: $colorTest
          +size(auto, 18px)

          .indicatorTitle
            color: $colorBackgroundPrimary
            display: block

        .consortiumIndicator
          background-color: $colorTint
          +cornerRadius(4px)
          +size(auto, 18px)

          .indicatorTitle
            color: $colorBackgroundPrimary
            display: block

    .encountersExpander
      +size(100%, 56px)
      +positionAbsoluteWithOffset(0, auto, 0, -12px)
      +contentEdgeInsets(24px, 0, 24px, 0)
      +display-flex
      +flex-direction(column)
      +align-items(center)
      +justify-content(flex-end)
      +cursorAction
      z-index: 1
      background-image: linear-gradient(180deg, $colorClear 0%, $colorBackgroundSecondary 64%, $colorBackgroundSecondary 100%)
      .expanderButton
        +size(auto, 20px)
        +display-flex
        +flex-direction(row)
        +align-items(center)
        +offset(0, 0, 0, 8px)
        +contentEdgeInsets(8px, 0, 8px, 0)
        +cornerRadius(4px)
        +noUserSelect
        background-color: $colorActionTranslucent
        .title
          +fontCaption1Emphasized
          color: $colorAction

.addTestEncounterView
  .tableView
    .tableSection
      &.sectionRecentEncounters
        .tableCell
          +size(100%, 56px)
          +contentEdgeInsets(8px, 0, 12px, 0)
          .profilePicture
            +size(40px, 40px)
            +cornerRadius(50%)
            +clipToBounds
            +flex-shrink(0)
          .cellBody
            +offset(8px, 0, 0, 0)
            .callerName
              +fontSubheadEmphasized
              color: $colorTitle
            .callerNumber
              +fontFootnote
              color: $colorTitle
          .cellDetail
            +size(100%, auto)
            .encounterName
              +fontFootnoteEmphasized
              color: $colorSubtitle
              text-align: right
          &.selected
            .cellBody
              .callerName
                color: $colorTitleSecondary
              .callerNumber
                color: $colorTitleSecondary
            .cellDetail
              .encounterName
                color: $colorTitleSecondary
                opacity: 0.80