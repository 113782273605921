.splitView
  +size(100%, 100%)
  +display-flex
  +flex-direction(row)
  +align-items(flex-start)
  +flex-grow(0)
  background-color: $colorBackgroundSecondary
  position: relative
  .mainView
    +size(272px, 100%)
    +display-flex
    +flex-direction(column)
    +flex-shrink(0)
    +flex-grow(0)
    position: relative
    background-color: $colorBackgroundSecondary
    .navBar
      +contentEdgeInsets(16px, 0, 16px, 0)
      background-color: $colorBackgroundSecondary
      .separator
        +positionAbsoluteWithOffset(16px, 0, 16px, 0)
    .scrollView
      +contentEdgeInsets(0, 16px, 0, 0)
      +size(100%, 100%)
      overflow-y: auto
    .contentView
      +display-flex
      +flex-direction(column)
      +contentEdgeInsets(16px, 0, 16px, 16px)
      background-color: $colorBackgroundSecondary

  .detailView
    +size(100%, 100%)
    +display-flex
    +flex-direction(column)
    +align-items(center)
    +flex-grow(0)
    position: relative
    background-color: $colorBackgroundTertiary
    .navBar
      +contentEdgeInsets(16px, 0, 16px, 0)
      background-color: $colorBackgroundTertiary
      .separator
        +positionAbsoluteWithOffset(16px, 0, 16px, 0)
    .actionBar
      background-color: $colorBackgroundTertiary
    .scrollView
      +contentEdgeInsets(0, 16px, 0, 0)
      +size(100%, 100%)
      overflow-y: auto
    .contentView
      min-height: 100%
      background-color: $colorBackgroundTertiary
      +display-flex
      +flex-direction(column)
      +contentEdgeInsets(16px, 0, 16px, 16px)

  &.modalDialog
    .mainView
      width: 232px
      .scrollView
        +contentEdgeInsets(0, 12px, 0, 0)
      .contentView
        +contentEdgeInsets(12px, 0, 12px, 12px)
      .navBar
        +contentEdgeInsets(12px, 0, 12px, 0)
        height: 48px
        .title
          +fontSubheadEmphasized
          color: $colorTitle
        .separator
          +positionAbsoluteWithOffset(0, 0, 0, 0)
    .detailView
      .scrollView
        +contentEdgeInsets(0, 12px, 0, 0)
      .contentView
        +contentEdgeInsets(12px, 0, 12px, 12px)
      .navBar
        +contentEdgeInsets(12px, 0, 12px, 0)
        height: 48px
        .title
          +fontSubheadEmphasized
          color: $colorTitle
        .separator
          +positionAbsoluteWithOffset(0, 0, 0, 0)

// .articlesView
//   +size(100%, auto)
//   +display-flex
//   +flex-direction(column)
//   +flex-grow(0)
//   position: relative
//   background-color: $colorBackgroundSecondary

//   .navBar
//     +contentEdgeInsets(16px, 0, 16px, 0)
//     background-color: $colorBackgroundSecondary
//     .separator
//       +positionAbsoluteWithOffset(16px, 0, 16px, 0)
