.conversationsView
  +display-flex
  +flex-direction(row)
  flex: 1

  .chatView
    .navBar
      width: 100%
      .navTitle
        width: 100%
        .title
          overflow: hidden
          max-width: 100%