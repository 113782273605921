// Layout
.activitySurveyCell
  +size(100%, auto)
  +contentEdgeInsets(12px, 9px, 12px, 9px)
  +justify-content(space-between)

  .cellActions
    +display-flex
    +flex-direction(row)

  .cellDetails
    +display-flex
    +flex-direction(row)

    .surveyCellDetail
      +offset(0, 0, 16px, 0)

      &.detailPrimary
        min-width: 200px

      &.detailSecondary
        min-width: 120px

      &.detailTertiary
        min-width: 160px

// Styles
.activitySurveyCell
  .detailTitle
    +fontSubhead2Emphasized
    color: $colorTitle

  .detailValue
    +fontFootnote
    color: $colorSubtitle
