$mobile-max-width: 1025px
$mobile-bottom-chrome: 83px

+keyframes(warn-blink)
  0%,49%
    background-color: $colorTintSecondary
  50%,100%
    background-color: $colorDestructive

.hidden
  display: none

.videoCallView
  +size(100vw, 100vh)
  +display-flex
  +align-items(center)
  +justify-content(center)
  background-color: $colorBackgroundVideoCall

.videoCallWindow
  +size(80vw, calc(80vw / 16 * 9))
  +cornerRadius(12px)
  +clipToBounds
  +noUserSelect
  +display-flex
  +flex-direction(column)
  +align-items(center)
  +justify-content(flex-end)
  background-color: $colorBackgroundPlaceholder
  box-shadow: 0 8px 20px 0 rgba(0,0,0,0.32)
  z-index: 1
  position: relative

  @media only screen and (max-height: 600px)
    +cornerRadius(0)
    +size(100vw, 100vh)
    box-shadow: none

  @media only screen and (max-width: $mobile-max-width)
    +cornerRadius(0)
    +size(100vw, 100vh)
    box-shadow: none

  .callDetails
    +size(200px, auto)
    +positionAbsoluteWithOffset(auto, auto, 16px, 200px)
    +display-flex
    +flex-direction(column)
    z-index: 3
    @media only screen and (min-width: $mobile-max-width)
      +positionAbsoluteWithOffset(auto, 16px, 16px, auto)

    &.desktop
      top: 2%
    .callStatus
      +size(100%, 100%)
      +align-items(center)
      +justify-content(center)
      +flex-direction(row)
      display: none
      background-color: $colorOverlay

      &.cameraOff, &.microphoneOff
        +display-flex

      &.cameraOff
        .camera
          .icon
            +iconVideoCallStatusCameraOff

      &.microphoneOff
        .camera
          .icon
            +iconVideoCallStatusMicrophoneOff

    .localParticipant
      +size(200px, 133px)
      +cornerRadius(12px)
      +clipToBounds
      background-color: $colorBackgroundPlaceholder
      box-shadow: 0 4px 10px 0 rgba(0,0,0,0.24)
      position: relative
      z-index: 3

      .timestamp
        +size(auto, 22px)
        +cornerRadius(6px)
        +positionAbsoluteWithOffset(auto, 8px, 8px, auto)
        +contentEdgeInsets(8px, 0, 8px, 0)
        +display-flex
        +align-items(center)
        background-color: rgba(255, 255, 255, 0.8)
        backdrop-filter: blur(12px)
        
        .timestampLabel
          +fontCaption1Emphasized
          color: $colorTitle

    .timer
      +size(100%, 48px)
      +offset(0, 12px, 0, 12px)
      +contentEdgeInsets(10px, 0, 10px, 0)
      +display-flex
      +flex-direction(column)
      +justify-content(center)
      +cornerRadius(8px)
      background-color: $colorTintSecondary
      backdrop-filter: blur(12px)
      box-sizing: border-box
      &.warn
        background-color: $colorDestructive
        animation: warn-blink 1s infinite

      .time
        +size(100%, auto)
        text-align: center
        font-size: 22px
        font-weight: bold
        color: $colorTitleSecondary

    .clientDetails
      +size(100%, 48px)
      +offset(0, 12px, 0, 0)
      +contentEdgeInsets(10px, 0, 10px, 0)
      +display-flex
      +flex-direction(column)
      +justify-content(center)
      +cornerRadius(8px)
      background-color: rgba(92, 92, 92, 1)
      backdrop-filter: blur(12px)
      box-sizing: border-box
      
      .client
        +size(100%, auto)
        +fontFootnoteEmphasized
        color: $colorTitleSecondary

      .patient
        +size(100%, auto)
        +display-flex
        +flex-direction(row)

        .patientName
          +fontFootnoteEmphasized
          color: $colorTitleSecondary
          opacity: 0.8

        .patientBreed
          +offset(6px, 0, 0, 0)
          +fontFootnote
          color: $colorTitleSecondary
          opacity: 0.6

  .callActions
    +size(auto, 64px)
    +display-flex
    +flex-direction(row)
    +offset(0, 0, 0, $mobile-bottom-chrome + 40px)
    @media screen and (min-width: $mobile-max-width)
      +offset(0, 0, 0, 40px)
    position: relative
    z-index: 4

    .callAction
      +size(64px, 64px)
      +cornerRadius(50%)
      +display-flex
      +align-items(center)
      +justify-content(center)
      +interactionTransition
      +cursorAction

      &:hover
        transform: scale(1.08)
      
      &:active
        opacity: 0.8
        transform: scale(0.98)

      &.flipCamera
        background-color: rgba(0, 0, 0, 0.4)
        backdrop-filter: blur(12px)

      &.privacyAction
        background-color: rgba(0, 0, 0, 0.4)
        backdrop-filter: blur(12px)

      &.off
        background-color: $colorBackgroundPrimary
        box-shadow: 0 4px 10px 0 rgba(0,0,0,0.24)
        backdrop-filter: none

      &.camera
        .icon
          +iconVideoCallCamera
        &.off
          .icon
            +iconVideoCallCameraOff

      &.microphone
        .icon
          +iconVideoCallMicrophone
        &.off
          .icon
            +iconVideoCallMicrophoneOff

      &.flipCamera
        .icon
          +iconVideoCallFlipCamera

      &.actionEnd
        +offset(16px, 0, 16px, 0)
        background-color: $colorCallEnd
        box-shadow: 0 4px 10px 0 rgba(0,0,0,0.24)
        .icon
          +iconVideoCallEnd

  .logo
    +iconVideoCallWatermark
    +positionAbsoluteWithOffset(auto, auto, 16px, 16px)
    z-index: 2

  .remoteParticipantStream
    +size(100%, 100%)
    +positionAbsoluteWithOffset(0, 0, 0, 0)
    background-color: $colorBackgroundPlaceholder
    z-index: 1

.videoJoinContainer
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap

.videoError
  color: $colorTitle

.videoJoin
  +size(auto, 60px)
  +contentEdgeInsets(4px, 0, 4px, 0)
  font-size: 32px
  font-weight: bold
  +cornerRadius(6px)
  +display-flex
  +align-items(center)
  +justify-content(center)
  box-sizing: border-box
  cursor: pointer
  min-width: 180px
  background-color: $colorTint
  .title
    color: $colorTitleSecondary
    background-color: $colorTint

.videoCallNotes
  height: calc((96vw / 16 * 9) * .6)
  width: 100%!important