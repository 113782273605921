// Layout
.satisfactionPersonnelFeedbackStatCell
  +size(100%, auto)
  +offset(6px, 0, 6px, 0)
  +contentEdgeInsets(12px, 10px, 12px, 10px)
  +cornerRadius(6px)
  +display-flex
  +flex-direction(column)

  .statValue
    +offset(0, 0, 0, 2px)


// Styles
.satisfactionPersonnelFeedbackStatCell
  background-color: $colorActionTranslucentCell
  
  .statValue
    +fontTitle1Emphasized
    color: $colorTitle

  .statTitle
    +fontSubheadEmphasized
    color: $colorSubtitle



// Interactions
.satisfactionPersonnelFeedbackStatCell

