.datePickerWrapper .react-datepicker-wrapper
  width: 100% !important
  margin-top: 10px

.datePickerWrapper .react-datepicker__input-container input
  width: 100% !important
  background-color: var(--colorTitleDisabled)
  color: var(--colorTitle)
  padding-left: 30px

.datePickerWrapper .react-datepicker__input-container input::placeholder
  color: var(--colorTitle)
  font-size: 15px
  font-family: var(--fontFamilyInter)

.satisfactionAlignMainView
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  padding: 0 40px 0 22px

.satisfactionComponentsAlignView
  display: flex
  align-items: center

.filterButtonView
  display: flex
  align-items: center
  marginTop: 20px
  justifyContent: space-between

.inputText
  color: var(--colorTitle)

.filterAlignView
  display: flex
  justify-content: space-between
  align-items: center

.lastDaysInput
  width: 50px
  background-color: var(--colorBackgroundPrimary)
  color: var(--colorTitle)




