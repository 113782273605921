/* Alert */

#masq-alert
  position: relative
  z-index: 100

  a
    color: blue
    cursor: pointer

  a:hover
    text-decoration: underline

#masq-alert:hover:after
  background: hsla(0,0%,0%,.8)
  border-radius: 3px
  color: #f6f6f6
  font: bold 12px/30px sans-serif
  height: 30px
  left: 50%
  margin-left: -60px
  position: absolute
  text-align: center
  top: 50px
  width: 120px

#masq-alert:hover:before
  border-bottom: 10px solid hsla(0,0%,0%,.8)
  border-left: 10px solid transparent
  border-right: 10px solid transparent
  content: ''
  height: 0
  left: 50%
  margin-left: -10px
  position: absolute
  top: 40px
  width: 0

#masq-alert:target
  display: none

.alert
  background-color: #c4453c
  background-image: -webkit-linear-gradient(135deg, transparent,transparent 25%, hsla(0,0%,0%,.05) 25%,hsla(0,0%,0%,.05) 50%, transparent 50%,transparent 75%, hsla(0,0%,0%,.05) 75%,hsla(0,0%,0%,.05))
  background-image: -moz-linear-gradient(135deg, transparent,transparent 25%, hsla(0,0%,0%,.1) 25%,hsla(0,0%,0%,.1) 50%, transparent 50%,transparent 75%, hsla(0,0%,0%,.1) 75%,hsla(0,0%,0%,.1))
  background-image: -ms-linear-gradient(135deg, transparent,transparent 25%, hsla(0,0%,0%,.1) 25%,hsla(0,0%,0%,.1) 50%, transparent 50%,transparent 75%, hsla(0,0%,0%,.1) 75%,hsla(0,0%,0%,.1))
  background-image: -o-linear-gradient(135deg, transparent,transparent 25%, hsla(0,0%,0%,.1) 25%,hsla(0,0%,0%,.1) 50%, transparent 50%,transparent 75%, hsla(0,0%,0%,.1) 75%,hsla(0,0%,0%,.1))
  background-image: linear-gradient(135deg, transparent,transparent 25%, hsla(0,0%,0%,.1) 25%,hsla(0,0%,0%,.1) 50%, transparent 50%,transparent 75%, hsla(0,0%,0%,.1) 75%,hsla(0,0%,0%,.1))
  background-size: 20px 20px
  box-shadow: 0 5px 0 hsla(0,0%,0%,.1)
  color: #f6f6f6
  display: block
  font: bold 14px sans-serif
  height: 50px
  position: absolute
  text-align: center
  text-decoration: none
  top: -45px
  width: 100%
  -webkit-animation: alert 1s ease forwards
    -moz-animation: alert 1s ease forwards
      -ms-animation: alert 1s ease forwards
        -o-animation: alert 1s ease forwards
          animation: alert 1s ease forwards