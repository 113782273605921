.chatView
  +size(100%, 100%)
  +display-flex
  +flex-direction(column)
  +noUserSelect
  +cursorDefault
  background-color: $colorBackgroundTertiary

  .navBar
    background-color: $colorBackgroundTertiary
    .navTitle
      +flex-direction(column)
      +align-items(flex-start)
      .title
        +fontBodyEmphasized
        color: $colorTitle
        max-width: 500px
        overflow: auto
        height: 20px
      .subtitle
        +fontCaption1
        color: $colorSubtitle

  .chatContent
    +size(100%, 100%)
    +display-flex
    +flex-direction(row)
    box-sizing: border-box

  .chatContext
    +size(auto, 100%)
    +contentEdgeInsets(0, 16px, 16px, 16px)
    +display-flex
    +flex-shrink(0)
    box-sizing: border-box
