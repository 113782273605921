.segmentedBar
  +size(auto, 28px)
  +display-flex
  +flex-direction(row)
  +align-items(center)
  +cornerRadius(8px)
  position: relative
  z-index: 2
  background-color: $colorActionTranslucent

  .separator
    +size(2px, 16px)
    +shadowNone
    position: static
    background-color: $colorBackgroundSelected

  .segment
    +size(50%, 100%)
    +cornerRadius(8px)
    +display-flex
    +align-items(center)
    +justify-content(center)
    +cursorAction
    +interactionTransition
    z-index: 4

    .title
      +cursorAction
      +fontFootnoteEmphasized
      color: $colorUnSelected
      &:hover
        color: $colorTitleSelected

    &:hover
      background-color: $colorBackgroundSelected

    &:active
      +interactionTapScale

    &.selected
      &:hover
        +interactionNone

      &:active
        +interactionTapScale

      .title
        color: $colorTitleSelected

  .selection
    +size(50%, 100%)
    +cornerRadius(8px)
    +cursorAction
    +interactionTransition
    +positionAbsoluteWithOffset(0, 0, auto, auto)
    +shadowSmall
    z-index: 3
    background-color: $colorBackgroundSelected

    &:active
      +interactionTapScale

  &.index0
    .selection
      left: 0
    .separator1
      opacity: 0
  &.index1
    .selection
      left: 80px
    .separator1, .separator2
      opacity: 0
  &.index2
    .selection
      left: 162px
    .separator2
      opacity: 0
  &.index3
    .selection
      left: 244px
    .separator2
      opacity: 0


