.chatMessages
  .messageCell
    .generic-attachment
      +size(100%, 50px)
      +cornerRadius(8px)
      background-color: $colorBackgroundPrimary
    .messageContainer
      .messageAttachments
        +size(100%, auto)
        max-width: 306px
        div
          +cornerRadius(12px)
          +clipToBounds

      .attachment
        +size(100%, auto)
        // +offset(0, 10px, 0, 0)
        +shadowSmall
        +cursorAction
        +interactionTransition
        
        &:hover
          +interactionHoverScale
        
        &:active
          +interactionTapScale

        &.media
          +cornerRadius(12px)
          background-color: $colorBackgroundPlaceholder

        &.compact
          +size(100%, 48px)
          +contentEdgeInsets(12px, 0, 10px, 0)
          +display-flex
          +flex-direction(row)
          +align-items(center)
          +cornerRadius(8px)
          background-color: $colorBackgroundPrimary

          .icon
            +flex-shrink(0)
            +size(24px, 24px)

          .attachmentDetails
            +size(100%, auto)
            +offset(12px, 0, 12px, 0)
            +display-flex
            +flex-direction(column)
            .attachmentTitle
              +maxSize(160px, 36px)
              +fontFootnoteEmphasized
              +clipToBounds
              text-overflow: ellipsis
              color: $colorTitle
            .attachmentSubtitle
              +fontCaption1
              color: $colorSubtitle

          .attachmentAction
            +size(auto, 28px)
            +contentEdgeInsets(4px, 0, 4px, 0)
            +cornerRadius(6px)
            +display-flex
            +align-items(center)
            +justify-content(center)
            +cursorAction
            box-sizing: border-box
            min-width: 56px
            background-color: $colorActionTranslucent
            .title
              +fontFootnoteEmphasized
              color: $colorAction
        
        &.file
          .icon
            +flex-shrink(0)
            +iconConversationAttachmentFile

        &.serviceOffering
          +contentEdgeInsets(8px, 0, 8px, 0)
          +interactionNone
          height: 48px
          .icon
            +size(32px, 32px)
            +flex-shrink(0)
            +display-flex
            +align-items(center)
            +justify-content(center)
            +cornerRadius(6px)
            background-color: $colorBackgroundSecondary

        &.referral
          +contentEdgeInsets(8px, 0, 8px, 0)
          +interactionNone
          height: 48px
          .icon
            +size(32px, 32px)
            +flex-shrink(0)
            +display-flex
            +align-items(center)
            +justify-content(center)
            +cornerRadius(6px)
            background-color: $colorBackgroundSecondary

            .glyph
              +iconConversationAttachmentReferral

        &.requestLocationUnfulfilled
          +contentEdgeInsets(8px, 0, 8px, 0)
          +interactionNone
          height: 48px
          .icon
            +size(32px, 32px)
            +flex-shrink(0)
            +display-flex
            +align-items(center)
            +justify-content(center)
            +cornerRadius(6px)
            background-color: $colorBackgroundSecondary

            .glyph
              +iconConversationAttachmentLocation

        &.requestLocationFilled
          +contentEdgeInsets(8px, 0, 8px, 0)
          +interactionNone
          height: 300px

        &.videoCall
          // background-color: $colorBackgroundDark
          background-color: $colorAction
          &.active
            background-color: $colorDestructive
          .icon
            +iconConversationAttachmentVideoCallOnDark
          .attachmentDetails
            .attachmentTitle
              color: $colorTitleSecondary
            .attachmentSubtitle
              color: $colorTitleSecondary
              opacity: 0.8
          .attachmentAction
            background-color: $colorTint
            .title
              color: $colorTitleSecondary

        &.location
          background-color: $colorAction
          .icon
            +iconConversationAttachmentLocationOnDark

        &.library
          background-color: $colorAction
          .icon
            +iconConversationAttachmentArticleOnDark
