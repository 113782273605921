.detailView
  +size(100%, 100%)
  +display-flex
  +flex-direction(column)
  +align-items(center)
  +flex-grow(0)
  position: relative
  background-color: $colorBackgroundSecondary

  .navBar
    +contentEdgeInsets(24px, 0, 24px, 0)
    background-color: $colorBackgroundSecondary
    .separator
      +positionAbsoluteWithOffset(24px, 0, 24px, 0)

  .scrollView
    +contentEdgeInsets(0, 0, 0, 24px)
    +size(100%, 100%)
    overflow-y: scroll

  .tableView
    +size(100%, auto)
    .tableSection
      +contentEdgeInsets(24px, 0, 24px, 24px)
      +flex-direction(row)
      +flex-wrap(wrap)
      +offset(0, 18px, 0, 0)
      .cell, .tableCell
        // +contentEdgeInsets(0, 0, 0, 0)
        +clipToBounds
        +cursorTextInput
        +shadowCell
        // +justify-content(space-between)
        &:active
          transform: scale(1)
          opacity: 1
        &:hover
          transform: scale(1)
          opacity: 1
          
        // +size(100%, 60px)
        +offset(0, 0, 0, 8px)
