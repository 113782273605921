.addEditSurvey
  .sectionSurveyQuestions
    z-index: 4
    position: relative

    .questionListItem
      +size(100%, auto)
      +display-flex
      +flex-direction(row)
      +offset(0, 0, 0, 12px)

      .removeQuestion
          +size(32px, 32px)
          +offset(0, 12px, 0, 0)
          +cornerRadius(6px)
          +display-flex
          +align-items(center)
          +justify-content(center)
          +flex-shrink(0)
          +cursorAction
          +interactionTransition
          background-color: $colorActionTranslucent

          &:hover
            background-color: $colorActionTranslucentHover
          &:active
            +interactionTapOpacity

          .icon
            +iconTableViewSectionRowAccessoryDelete

// Refactoring
.questionCell
  +size(100%, auto)
  +display-flex
  +flex-direction(row)
  +cornerRadius(10px)
  +contentEdgeInsets(8px, 8px, 8px, 8px)
  +offset(0, 0, 8px, 0)
  +shadowSmallInset
  background-color: $colorBackgroundTertiary

  .questionBody
    +size(80%, auto)
    +offset(0, 0, 8px, 0)
    +display-flex
    +flex-direction(column)

    .questionTitle
      .DraftEditor-root
        .public-DraftEditorPlaceholder-root
          +fontSubheadEmphasized
          color: $colorTitlePlaceholder

        .DraftEditor-editorContainer
          +fontSubheadEmphasized
          color: $colorTitle

    .questionItem
      +display-flex
      +flex-direction(row)
      +align-items(center)
      +offset(0, 0, 0, 8px)

      &.multipleChoiceItem
        +offset(0, 0, 0, 8px)
        .inputCell
          +offset(0, 0, 0, 0)
        .multiChoiceShortcut
          width: 50%

      .remove
        +offset(8px, 0, 0, 0)

      &.scaleRange
        .inputCell
          +offset(0, 0, 0, 0)
          width: 80%
        .scaleRangeTo
          +fontSubhead
          +noUserSelect
          +cursorDefault
          color: $colorSubtitle
          width: 60px
          text-align: center
        .numberInput
          .inputCell
            +offset(0, 0, 0, 0)
            width: 100%

    .multipleChoice
      .questionItem
        &.addOption
          .title
            +fontSubhead
            color: $colorAction
            text-align: left
        &:last-child
          +offset(0, 0, 0, 0)

  .questionType
    +size(20%, 40px)

  .questionLabel
    color: #D0D0D7
  .comparisonSource
    width: 100%

  .addCriteria
    +display-flex
    +justify-content(center)
    width: 100px

  .questionCriteria
    +display-flex
    +flex-direction(row)
    +offset(0, 0, 0, 8px)
    +contentEdgeInsets(8px, 8px, 8px, 8px)
    +cornerRadius(8px)
    background-color: $colorBackgroundPrimary
    &:first-of-type
      +offset(0, 8px, 0, 8px)

    .criteriaContainer
      +size(100%, auto)
      +offset(0, 0, 0, 0)
      +display-flex
      +flex-direction(column)

      .criteriaRow
        +size(100%, 40px)
        +offset(0, 0, 0, 8px)
        +display-flex
        +flex-direction(row)
        &:last-of-type
          +offset(0, 0, 0, 0)
        .tableCell
          +offset(0, 0, 8px, 0)
          +shadowNone
          background-color: $colorActionTranslucentCell
          &:focus-within
            +shadowInputFocused
        .conditionCell
          width: 35%
        .operatorCell
          width: 25%
          .selectCell__control
            background-color: $colorClear
        .valueCell
          width: 40%
          .input
            text-align: left
        .surveySelect__control
          width: 100%


    .removeCriteria
      .icon
        +size(20px, 20px)
        +cornerRadius(50%)
        +cursorAction
        +interactionTransition
        transform: rotate(45deg)
        position: relative
        background-color: $colorAction

        &:active
          opacity: 0.4

        .lineVertical, .lineHorizontal
          +cornerRadius(1px)
          background-color: $colorBackgroundPrimary
        .lineVertical
          +positionAbsoluteWithOffset(50%, 50%, auto, auto)
          transform: translate(-50%, -50%)
          +size(2px, 12px)
        .lineHorizontal
          +positionAbsoluteWithOffset(50%, 50%, auto, auto)
          transform: translate(-50%, -50%)
          +size(12px, 2px)