.clientsView
  +size(100%, 100%)
  +display-flex
  +flex-direction(column)

  .navBar
    +contentEdgeInsets(24px, 0, 24px, 0)
    .separator
      +positionAbsoluteWithOffset(24px, 0, 24px, 0)

  .clientList
    overflow-y: auto
    .clientListContents
      +flex-direction(column)
      +flex-wrap(nowrap)
      .clientCell
        @media only screen and (min-width: 1200px)
          +size(100%, 60px)
        @media only screen and (min-width: 1600px)
          +size(100%, 60px)
        @media only screen and (min-width: 1800px)
          +size(100%, 60px)
        @media only screen and (min-width: 2000px)
          +size(100%, 60px)
        div
          .title
            max-width: 250px
            overflow-x: hidden