.mapView
  +size(100%, 100%)
  +display-flex
  +flex-direction(column)
  +flex-grow(0)
  position: relative
  background-color: $colorBackgroundSecondary

  .navBar
    +contentEdgeInsets(24px, 0, 24px, 0)
    background-color: $colorBackgroundSecondary
    .separator
      +positionAbsoluteWithOffset(24px, 0, 24px, 0)

  .scrollView
    +display-flex
    +flex-direction(column)
    +contentEdgeInsets(24px, 24px, 24px, 24px)

    .conversationsMapStats
      +size(100%, auto)
      +display-flex
      +flex-direction(row)
      +justify-content(space-between)

      .statsCell
        +size(24%, 88px)
        +shadowMedium
        +cornerRadius(8px)
        +contentEdgeInsets(12px, 0, 12px, 0)
        +noUserSelect
        +cursorDefault
        background-color: $colorBackgroundPrimary

        .title, .value
          +size(100%, auto)

        .title
          +offset(0, 14px, 0, 0)
          +fontSubheadEmphasized
          color: $colorSubtitle

        .value
          +offset(0, 2px, 0, 0)
          +fontTitle0Emphasized
          color: $colorTitle

    .mapContainer
      +size(100%, 100%)
      +offset(0, 24px, 0, 0)
      +shadowMedium
      +cornerRadius(8px)
      +noUserSelect
      +cursorDefault
      +display-flex
      min-height: 520px
      background-color: $colorBackgroundPrimary

.conversation-count-enter
  opacity: 0
.conversation-count-enter-active
  opacity: 1
  transition: opacity 1000ms ease-in
  color: $colorTint