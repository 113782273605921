=spriteSettings
  background-image: url('/sprite.svg')
  display: inline-block

=iconTabBarLogoCompact
  +spriteSettings
  +size(32px, 32px)
  background-position: -280px -4px

=iconTabBarLogoCompactOnDark
  +spriteSettings
  +size(32px, 32px)
  background-position: -320px -4px

=iconTabBarLogo
  +spriteSettings
  +size(128px, 40px)
  +offset(-8px, 0, 0, 0)
  background-position: -8px 0

=iconTabBarLogoOnDark
  +spriteSettings
  +size(128px, 40px)
  +offset(-8px, 0, 0, 0)
  background-position: -144px 0

=iconTabBarOverview
  +spriteSettings
  +size(24px, 24px)
  background-position: -8px -48px

=iconTabBarOverviewSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -8px -80px

=iconTabBarConversations
  +spriteSettings
  +size(24px, 24px)
  background-position: -40px -48px

=iconTabBarConversationsSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -40px -80px

=iconTabBarWorkflow
  +spriteSettings
  +size(24px, 24px)
  background-position: -72px -48px

=iconTabBarWorkflowSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -72px -80px

=iconTabBarAppointments
  +spriteSettings
  +size(24px, 24px)
  background-position: -104px -48px

=iconTabBarAppointmentsSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -104px -80px

=iconTabBarClients
  +spriteSettings
  +size(24px, 24px)
  background-position: -136px -48px

=iconTabBarClientsSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -136px -80px

=iconTabBarServices
  +spriteSettings
  +size(24px, 24px)
  background-position: -168px -48px

=iconTabBarServicesSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -168px -80px

=iconTabBarSettings
  +spriteSettings
  +size(24px, 24px)
  background-position: -200px -48px

=iconTabBarSettingsSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -200px -80px

=iconTabBarOnboarding
  +spriteSettings
  +size(24px, 24px)
  background-position: -232px -48px

=iconTabBarOnboardingSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -232px -80px

=iconTabBarRules
  +spriteSettings
  +size(24px, 24px)
  background-position: -264px -48px

=iconTabBarRulesSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -264px -80px

=iconTabBarSurveys
  +spriteSettings
  +size(24px, 24px)
  background-position: -296px -48px

=iconTabBarSurveysSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -296px -80px

=iconTabBarMeasure
  +spriteSettings
  +size(24px, 24px)
  background-position: -328px -48px

=iconTabBarMeasureSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -328px -80px

=iconTabBarMap
  +spriteSettings
  +size(24px, 24px)
  background-position: -360px -48px

=iconTabBarMapSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -360px -80px

=iconTabBarGroups
  +spriteSettings
  +size(24px, 24px)
  background-position: -392px -48px

=iconTabBarGroupsSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -392px -80px

=iconTabBarArticles
  +spriteSettings
  +size(24px, 24px)
  background-position: -424px -48px

=iconTabBarArticlesSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -424px -80px

=iconTabBarProviders
  +spriteSettings
  +size(24px, 24px)
  background-position: -456px -48px

=iconTabBarProvidersSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -456px -80px

=iconTabBarSources
  +spriteSettings
  +size(24px, 24px)
  background-position: -488px -48px

=iconTabBarSourcesSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -488px -80px

=iconTabBarFeedback
  +spriteSettings
  +size(24px, 24px)
  background-position: -520px -48px

=iconTabBarFeedbackSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -520px -80px

=iconTabBarTasks
  +spriteSettings
  +size(24px, 24px)
  background-position: -552px -48px

=iconTabBarTasksSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -552px -80px

=iconConversationChatCellUnread
  +spriteSettings
  +size(10px, 10px)
  background-position: -8px -112px

=iconConversationChatCellUnreadSelected
  +spriteSettings
  +size(10px, 10px)
  background-position: -26px -112px

=iconConversationChatCellFollowUp
  +spriteSettings
  +size(10px, 10px)
  background-position: -44px -112px

=iconConversationChatCellFollowUpSelected
  +spriteSettings
  +size(10px, 10px)
  background-position: -62px -112px

=iconConversationChatCellOverdue
  +spriteSettings
  +size(10px, 10px)
  background-position: -80px -112px

=iconConversationContextDetailsPatientTypeDog
  +spriteSettings
  +size(28px, 28px)
  background-position: -8px -130px

=iconConversationContextDetailsPatientEmpty
  +spriteSettings
  +size(28px, 28px)
  background-position: -368px -130px

=iconConversationContextDetailsPatientSymptoms
  +spriteSettings
  +size(28px, 28px)
  background-position: -44px -130px

=iconConversationContextDetailsConsultationStatus
  +spriteSettings
  +size(28px, 28px)
  background-position: -80px -130px

=iconConversationContextDetailsConsultationSuspectedDiagnosis
  +spriteSettings
  +size(28px, 28px)
  background-position: -116px -130px

=iconConversationContextDetailsConsultationPriorityNone
  +spriteSettings
  +size(28px, 28px)
  background-position: -152px -130px

=iconConversationContextDetailsConsultationPriorityLow
  +spriteSettings
  +size(28px, 28px)
  background-position: -188px -130px

=iconConversationContextDetailsConsultationPriorityMedium
  +spriteSettings
  +size(28px, 28px)
  background-position: -224px -130px

=iconConversationContextDetailsConsultationPriorityHigh
  +spriteSettings
  +size(28px, 28px)
  background-position: -260px -130px

=iconConversationContextDetailsConsultationOpened
  +spriteSettings
  +size(28px, 28px)
  background-position: -296px -130px

=iconConversationContextDetailsConsultationQrCode
  +spriteSettings
  +size(28px, 28px)
  background-position: -136px -48px

=iconConversationContextDetailsConsultationReminderEmpty
  +spriteSettings
  +size(28px, 28px)
  background-position: -332px -130px

=iconConversationContextDetailsService
  +spriteSettings
  +size(28px, 28px)
  background-position: -404px -130px

=iconConversationContextDetailsVideoCall
  +spriteSettings
  +size(28px, 28px)
  background-position: -440px -130px

=iconConversationContextDetailsVideoCallEmphasized
  +spriteSettings
  +size(28px, 28px)
  background-position: -440px -130px

=iconConversationContextDetailsDisposition
  +spriteSettings
  +size(28px, 28px)
  background-position: -512px -130px

=iconConversationContextDetailsPatientDemographics
  +spriteSettings
  +size(28px, 28px)
  background-position: -548px -130px

=iconConversationContextDetailsPatientInteraction
  +spriteSettings
  +size(28px, 28px)
  background-position: -584px -130px

=iconConversationContextDetailsPatientInsurance
  +spriteSettings
  +size(28px, 28px)
  background-position: -620px -130px

=iconConversationChatInputActionSend
  +spriteSettings
  +size(24px, 24px)
  background-position: -138px -166px

=iconConversationChatInputActionSendDisabled
  +spriteSettings
  +size(24px, 24px)
  background-position: -354px -166px

=iconConversationChatInputActionVideo
  +spriteSettings
  +size(16px, 10px)
  background-position: -8px -166px

=iconConversationChatInputActionVideoSelected
  +spriteSettings
  +size(16px, 10px)
  background-position: -32px -166px

=iconConversationChatInputActionAttachment
  +spriteSettings
  +size(11px, 14px)
  background-position: -100px -166px

=iconConversationChatInputActionAttachmentSelected
  +spriteSettings
  +size(11px, 14px)
  background-position: -119px -166px

=iconConversationChatInputActionAppointment
  +spriteSettings
  +size(14px, 14px)
  background-position: -56px -166px

=iconConversationChatInputActionAppointmentSelected
  +spriteSettings
  +size(14px, 14px)
  background-position: -78px -166px

=iconConversationChatInputActionService
  +spriteSettings
  +size(17px, 18px)
  background-position: -386px -166px

=iconConversationChatInputActionServiceSelected
  +spriteSettings
  +size(17px, 18px)
  background-position: -411px -166px

=iconConversationChatInputActionLocation
  +spriteSettings
  +size(16px, 14px)
  background-position: -514px -166px

=iconConversationChatInputActionLocationSelected
  +spriteSettings
  +size(16px, 14px)
  background-position: -538px -166px

=iconConversationChatInputActionRefer
  +spriteSettings
  +size(16px, 14px)
  background-position: -562px -166px

=iconConversationChatInputActionReferSelected
  +spriteSettings
  +size(16px, 14px)
  background-position: -586px -166px

=iconConversationAttachmentService
  +spriteSettings
  +size(20px, 20px)
  background-position: -436px -166px

=iconConversationAttachmentServiceOnDark
  +spriteSettings
  +size(20px, 20px)
  background-position: -464px -166px

=iconConversationAttachmentReferral
  +spriteSettings
  +size(20px, 20px)
  background-position: -670px -166px

=iconConversationAttachmentReferralOnDark
  +spriteSettings
  +size(20px, 20px)
  background-position: -698px -166px

=iconConversationAttachmentLocation
  +spriteSettings
  +size(22px, 22px)
  background-position: -610px -166px

=iconConversationAttachmentLocationOnDark
  +spriteSettings
  +size(22px, 22px)
  background-position: -640px -166px

=iconConversationChatInputActionArticle
  +spriteSettings
  +size(16px, 16px)
  background-position: -726px -166px

=iconConversationChatInputActionArticleOnDark
  +spriteSettings
  +size(16px, 16px)
  background-position: -750px -166px

=iconConversationAttachmentArticle
  +spriteSettings
  +size(22px, 22px)
  background-position: -774px -166px

=iconConversationAttachmentArticleOnDark
  +spriteSettings
  +size(22px, 22px)
  background-position: -804px -166px

=iconConversationsResponseGroupPicker
  +spriteSettings
  +size(14px, 15px)
  background-position: -492px -166px

=iconServiceWellnessPlan
  +spriteSettings
  +size(24px, 24px)
  background-position: -8px -198px

=iconServicetemDefault
  +spriteSettings
  +size(24px, 24px)
  background-position: -40px -198px

=iconTableViewCellAccessoryDisclosureIndicator
  +spriteSettings
  +size(8px, 12px)
  background-position: -40px -230px

=iconTableViewCellAccessoryDisclosureIndicatorActive
  +spriteSettings
  +size(8px, 12px)
  background-position: -56px -230px

=iconTableViewCellAccessoryPopUp
  +spriteSettings
  +size(8px, 16px)
  background-position: -8px -230px

=iconTableViewCellAccessoryPopUpActive
  +spriteSettings
  +size(8px, 16px)
  background-position: -24px -230px

=iconTableViewCellAccessoryExpander
  +spriteSettings
  +size(6px, 10px)
  background-position: -176px -230px

=iconTableViewCellAccessoryFlagDestructive
  +spriteSettings
  +size(14px, 16px)
  background-position: -190px -230px

=iconTableViewCellAccessoryCheckmarkPositive
  +spriteSettings
  +size(20px, 20px)
  background-position: -212px -230px

=iconTableViewSectionRowAccessoryDelete
  +spriteSettings
  +size(16px, 16px)
  background-position: -72px -230px

=iconTableViewSectionRowAccessoryDeleteSelected
  +spriteSettings
  +size(16px, 16px)
  background-position: -96px -230px

=iconTableViewAccessoryCheckmark
  +spriteSettings
  +size(20px, 20px)
  background-position: -120px -230px

=iconTableViewAccessoryCheckmarkSelected
  +spriteSettings
  +size(20px, 20px)
  background-position: -148px -230px

=iconVideoCallStatusMicrophoneOff
  +spriteSettings
  +size(64px, 64px)
  background-position: 0 -254px

=iconVideoCallStatusCameraOff
  +spriteSettings
  +size(64px, 64px)
  background-position: -72px -254px

=iconVideoCallEnd
  +spriteSettings
  +size(32px, 32px)
  background-position: -304px -254px

=iconVideoCallCamera
  +spriteSettings
  +size(32px, 32px)
  background-position: -144px -254px

=iconVideoCallMicrophone
  +spriteSettings
  +size(32px, 32px)
  background-position: -224px -254px

=iconVideoCallCameraOff
  +spriteSettings
  +size(32px, 32px)
  background-position: -184px -254px

=iconVideoCallMicrophoneOff
  +spriteSettings
  +size(32px, 32px)
  background-position: -264px -254px

=iconVideoCallFlipCamera
  +spriteSettings
  +size(32px, 32px)
  background-position: -344px -254px

=iconVideoCallWatermark
  +spriteSettings
  +size(154px, 40px)
  background-position: -384px -254px

=iconConversationAttachmentMediaVideoPlay
  +spriteSettings
  +size(24px, 24px)
  background-position: -290px -166px

=iconConversationChatInputActionAttachmentClear
  +spriteSettings
  +size(24px, 24px)
  background-position: -322px -166px

=iconConversationAttachmentVideoCallOnDark
  +spriteSettings
  +size(26px, 16px)
  background-position: -204px -166px

=iconConversationAttachmentVideoCall
  +spriteSettings
  +size(26px, 16px)
  background-position: -170px -166px

=iconConversationAttachmentFile
  +spriteSettings
  +size(18px, 24px)
  background-position: -238px -166px

=iconConversationAttachmentFileOnDark
  +spriteSettings
  +size(18px, 24px)
  background-position: -264px -166px

=iconNavBarSearch
  +spriteSettings
  +size(16px, 16px)
  background-position: -8px -326px

=iconNavBarSearchClear
  +spriteSettings
  +size(18px, 18px)
  background-position: -32px -326px

=iconNavBarAdd
  +spriteSettings
  +size(12px, 12px)
  background-position: -58px -326px

=iconNavBarAddTint
  +spriteSettings
  +size(12px, 12px)
  background-position: -78px -326px

=iconEntityChatbot
  +spriteSettings
  +size(36px, 36px)
  background-position: -8px -422px

=iconConversationContextParticipantMuteOn
  +spriteSettings
  +size(24px, 24px)
  background-position: -56px -426px

=iconConversationContextParticipantMuteOff
  +spriteSettings
  +size(24px, 24px)
  background-position: -96px -426px

=iconGatekeeperLogo
  +spriteSettings
  +size(166px, 42px)
  background-position: -8px -352px

=iconGatekeeperLogoOnDark
  +spriteSettings
  +size(166px, 42px)
  background-position: -190px -352px

// Rules
=iconRulesTypeaheadSuggested
  +spriteSettings
  +size(24px, 24px)
  background-position: -8px -466px

=iconRulesTypeaheadCustom
  +spriteSettings
  +size(24px, 24px)
  background-position: -40px -466px

// Hacks
// =iconTabBarLogo
//   background-image: url("/GMRLogo.png")
//   background-repeat: no-repeat
//   background-size: 170px
//   +size(170px, 30px)

// =iconGatekeeperLogo
//   background-image: url("/GMRLogo.png")
//   background-repeat: no-repeat
//   +size(210px, 64px)

=iconTabBarFileImport
  +spriteSettings
  +size(24px, 24px)
  background-position: -650px -48px

=iconTabBarFileImportSelected
  +spriteSettings
  +size(24px, 24px)
  background-position: -650px -80px

=iconFileUpload
  +spriteSettings
  +size(36px, 36px)
  background-position: -750px -50px

=iconFileUploadOnDark
  +spriteSettings
  +size(36px, 36px)
  background-position: -750px -100px

=iconFile
  +spriteSettings
  +size(27px, 36px)
  background-position: -750px -150px

=iconFileOnDark
  +spriteSettings
  +size(27px, 36px)
  background-position: -750px -200px
